import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { ChatService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useChatUserList = (searchText, onSuccess, onError = onDefaultError) => {
  return useQuery(['user-chat-list', searchText], ChatService.userChatList, {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useGetMessageList = ([page_no, chat_room_id], onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['user-chat-message-list', page_no, chat_room_id],
    ChatService.userMessageChatList,
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useSendChatMessage = (onSuccess, onError = onDefaultError) => {
  return useMutation(ChatService.sendChatMessage, {
    onSuccess,
    onError,
  });
};

export { useChatUserList, useGetMessageList, useSendChatMessage };
