/* Blog Post */
import client from 'libs/HttpClient';

class BookAppointmentService {
  static scheduleAppointment(request) {
    return client.post('/admin/appointment/add', request);
  }
  static listBooking(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const fromDate = request.queryKey[4] || '';
    const toDate = request.queryKey[5] || '';
    return client.get(
      `/admin/appointment/list?search=${searchText}&page=${page_no}&status=${status}&from_date=${fromDate}&to_date=${toDate}`,
      request
    );
  }

  static getAppointmentAvailability(request) {
    return client.get(`/admin/appointment/get`, request);
  }
}
export { BookAppointmentService };
