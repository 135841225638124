import React, { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

import { useAddBookAppointment, useGetBookAppointment } from 'hooks';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router';
import { TNBreadCurm } from 'common/components';
import moment from 'moment';
import { date } from 'yup';

const BookAppointment = ({ t }) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [slot, setSlot] = useState();
  const [selectSlot, setSelectSlot] = useState();
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const handleChange = (e) => {
    e = new Date(e);
    const tempDate = `${e.getFullYear()}-${
      parseInt(e.getMonth(), 10) + 1 > 9
        ? parseInt(e.getMonth(), 10) + 1
        : `0${parseInt(e.getMonth(), 10) + 1}`
    }-${parseInt(e.getDate(), 10) < 9 ? `0${e.getDate()}` : e.getDate()}`;
    // setSelectedDate(e);
    // formik.values.date = tempDate;
    // setNewDate(tempDate);

    doSetBooking({ date: e, temp: tempDate });
  };

  const { mutate: doSetBooking } = useGetBookAppointment((res) => {
    setSlot(res.data);
    if (res.data.length > 0) {
      formik.setFieldValue('slot_id', res.data[0]?.id);
      setSelectSlot(res.data[0]?.id);
    }
  });
  // if (!apiCall) {
  //   doSetBooking({ date: selectedDate, is_current: true });
  //   setApiCall(true);
  // }

  const { mutate: doAddBooking } = useAddBookAppointment((res) => {
    toast.success(res.message);
    navigate(`/valuation/viewvaluationList/${id}`);
  });

  const formik = useFormik({
    initialValues: {
      valuation_id: id,
      date: new Date(),
      slot_id: 0,
      description: '',
    },
    onSubmit: (values) => {
      if (
        localStorage.adminBookingData !== undefined &&
        localStorage.adminBookingData !== undefined
      ) {
        values.booking_id = JSON.parse(localStorage.adminBookingData).booking_id;
        values.is_reschedule = JSON.parse(localStorage.adminBookingData).is_reschedule;
      }
      // values.date = newDate;
      doAddBooking(values);
    },
  });
  if (
    moment(new Date()).format('YYYY-MM-DD') === moment(formik.values.date).format('YYYY-MM-DD') &&
    !slot
  ) {
    doSetBooking(formik.values);
  }
  const breadcurmArray = [
    {
      label: t(`page.current_list_label`),
      link: `/valuation/current/list`,
      active: '',
    },
    {
      label: t(`page.valuation_details_label`),
      link: `/valuation/viewvaluationList/${id}`,
      active: '',
    },
    {
      label: t(`page.valuation_book_appointment_label`),
      link: `/valuation/book-appointment/${id}`,
      active: 'active',
    },
  ];

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <section
        id="terms-condition-descriptioncontact"
        className="terms-condition-description chat-message premilinary-offer-start">
        <div className="container">
          <div className="waste-main my-valuation">
            <div className="main-section">
              <div className="row"></div>
              <div className="waste-main mx-4 mt-4 p-5 pb-1">
                <div className="text-center">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <h2 className="text-start mb-4 bg-heading-add fs-3">
                        {t('page.book_appointment_title')}
                      </h2>
                      <div className="col-lg-6 p-0">
                        <div className="premilinary-offer-section mt-2 mb-4">
                          <div className="text-center">
                            <p className="form-label hours-available">
                              {t('page.book_appointment_select_date_for_available_slot')}
                            </p>
                          </div>

                          <div className="demo-app-calendar">
                            <Calendar
                              name="date"
                              tileDisabled={({ date }) => {
                                const dateSelected = new Date();
                                const previous = new Date(dateSelected.getTime());
                                previous.setDate(dateSelected.getDate() - 1);

                                // return previous;
                                return date < previous;
                              }}
                              onChange={(date) => {
                                handleChange(moment(date).format('YYYY-MM-DD'));
                                formik.setFieldValue('date', moment(date).format('YYYY-MM-DD'));
                                console.log(moment(date).format('YYYY-MM-DD'));
                              }}
                              value={new Date(formik.values.date)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div
                          className={`premilinary-offer-section mt-2 mb-4 ${
                            slot?.length ? '' : 'd-none'
                          }`}>
                          <div className="text-center">
                            <p className="form-label hours-available">
                              {t('page.book_appointment_hours_avaibility')}
                            </p>{' '}
                            {slot?.map((slot, i) => {
                              return (
                                <div key={i}>
                                  <div className="available-slot row mt-2">
                                    <div className="col-2 slot_radio_btn">
                                      <input
                                        type="radio"
                                        className="radiobtn form-check-input"
                                        name="slot_id"
                                        value={slot.id}
                                        checked={selectSlot === slot.id}
                                        onChange={() => {
                                          setSelectSlot(slot.id);
                                          formik.setFieldValue('slot_id', slot.id);
                                        }}
                                      />{' '}
                                      <span className="checkmark"></span>
                                    </div>{' '}
                                    <div className="col-10 time-slot p-2">
                                      <span className="">
                                        {slot.start_time + '-' + slot.end_time}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="row mt-2">
                              <div className="col-12 text-start add_not_section">
                                <h5>
                                  {/* {t("book_appointment_description")} */}
                                  {t('Add Note')}
                                </h5>
                                <textarea
                                  className="appointment-description"
                                  placeholder={t('page.book_appointment_description_placeholder')}
                                  onChange={formik.handleChange}
                                  name="description"
                                  value={formik.values.description}></textarea>

                                <div className="d-flex mt-3">
                                  <button className="rounded-pill btn-rounded me-0" type="submit">
                                    {t('page.book_appointment_accept_button')}
                                  </button>
                                  <button
                                    type="submit"
                                    className="rouded-cancel-bt rounded-pill btn-rounded ms-3"
                                    onClick={() => {
                                      navigate(`/valuation/viewvaluationList/${id}`);
                                    }}>
                                    {t('page.book_appointment_cancel_button')}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={`${!slot?.length ? '' : 'd-none'}`}>
                          <p className="form-label hours-available">
                            {t('page.book_appointment_hours_avaibility')}
                          </p>{' '}
                        </div>

                        <div
                          className={`no-slot-available justify-content-center ${
                            !slot?.length ? '' : 'd-none'
                          }`}>
                          <h5>{t('page.no_slot_is_available')}</h5>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookAppointment;
