import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { BlogCategoryService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useListBlogCategory = (
  [page_no, searchText, status],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['blog-category-list', page_no, searchText, status],
    BlogCategoryService.listBlogCategory,
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddBlogCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogCategoryService.addBlogCategory, {
    onSuccess,
    onError,
  });
};

const useViewBlogCategory = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('blog-category-view', () => BlogCategoryService.viewBlogCategory(id), {
    onSuccess,
    onError,
  });
};

const useUpdateBlogCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogCategoryService.updateBlogCategory, {
    onSuccess,
    onError,
  });
};

const useStatusChangeBlogCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogCategoryService.statusChangeBlogCategory, {
    onSuccess,
    onError,
  });
};

const useDeleteBlogCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogCategoryService.deleteBlogCategory, {
    onSuccess,
    onError,
  });
};

export {
  useListBlogCategory,
  useAddBlogCategory,
  useViewBlogCategory,
  useUpdateBlogCategory,
  useDeleteBlogCategory,
  useStatusChangeBlogCategory,
};
