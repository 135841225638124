import { React } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Card, Col, Row, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import validationSchema from './ContactUsSettingValidation';
import { useFormik } from 'formik';
import { TNButton } from 'common/components';
import 'assets/scss/page/_contactussetting.scss';
import { useStoreSettingData, useGetContactUsSettingData } from 'hooks';

const ContactUsSetting = ({ t }) => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      contact_us_header_text: '',
      contact_us_sub_header_text: '',
      contact_us_information_title_text: '',
      contact_us_sub_information_title_text: '',
      contact_us_email_information_title_text: '',
      contact_us_admin_email: '',
      contact_us_seo_title: '',
      contact_us_seo_description: '',
      contact_us_send_enquiry_heading_text: '',
    },
    validationSchema,
    onSubmit: (values) => {
      doStoreSettingData(values);
    },
  });

  const { refetch: doViewHomeGeneral } = useGetContactUsSettingData(
    [true],
    ({ data: response }) => {
      if (response) {
        formik.values.contact_us_header_text = response.contact_us_header_text || '';
        formik.values.contact_us_sub_header_text = response.contact_us_sub_header_text || '';
        formik.values.contact_us_information_title_text =
          response.contact_us_information_title_text || '';
        formik.values.contact_us_sub_information_title_text =
          response.contact_us_sub_information_title_text || '';
        formik.values.contact_us_email_information_title_text =
          response.contact_us_email_information_title_text || '';
        formik.values.contact_us_admin_email = response.contact_us_admin_email || '';
        formik.values.contact_us_seo_title = response.contact_us_seo_title || '';
        formik.values.contact_us_seo_description = response.contact_us_seo_description || '';
        formik.values.contact_us_send_enquiry_heading_text =
          response.contact_us_send_enquiry_heading_text || '';
      }
    }
  );

  /**
   * !This API will call when user click on Submit Button
   */
  const { mutate: doStoreSettingData, isLoading } = useStoreSettingData((response) => {
    toast.success(response.message);
    setTimeout(function () {
      navigate('/dashboard');
      doViewHomeGeneral();
    }, 1500);
  });
  /**
   * This function is use when click on cancel button
   */
  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/dashboard/`);
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate(`/dashboard/`);
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item to="/setting/contact-us" active>
          {t('page.contact_us_setting_tab_general_setting')}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card className="inner-box">
        <h1 className="page-heading-center">{t('page.contact_us_setting_header_text')}</h1>
        <div className="settings">
          <div className="contact-us-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row className="justify-content-center">
                <Col lg={8} md={8} sm={8}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_us_header_title_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.contact_us_header_text &&
                        formik.errors.contact_us_header_text
                          ? 'form-field-error'
                          : formik.touched.contact_us_header_text &&
                            !formik.errors.contact_us_header_text
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="contact_us_header_text"
                      placeholder={t('page.contact_us_header_title_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_us_header_text}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_us_header_text &&
                      formik.errors.contact_us_header_text ? (
                        <div>{t(formik.errors.contact_us_header_text)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_us_sub_header_title_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.contact_us_sub_header_text &&
                        formik.errors.contact_us_sub_header_text
                          ? 'form-field-error'
                          : formik.touched.contact_us_sub_header_text &&
                            !formik.errors.contact_us_sub_header_text
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="contact_us_sub_header_text"
                      placeholder={t('page.contact_us_sub_header_title_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_us_sub_header_text}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.general_facebook_link &&
                      formik.errors.general_facebook_link ? (
                        <div>{t(formik.errors.general_facebook_link)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_us_information_title_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.contact_us_information_title_text &&
                        formik.errors.contact_us_information_title_text
                          ? 'form-field-error'
                          : formik.touched.contact_us_information_title_text &&
                            !formik.errors.contact_us_information_title_text
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="contact_us_information_title_text"
                      placeholder={t('page.contact_us_information_title_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_us_information_title_text}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_us_information_title_text &&
                      formik.errors.contact_us_information_title_text ? (
                        <div>{t(formik.errors.contact_us_information_title_text)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_us_sub_information_title_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.contact_us_sub_information_title_text &&
                        formik.errors.contact_us_sub_information_title_text
                          ? 'form-field-error'
                          : formik.touched.contact_us_sub_information_title_text &&
                            !formik.errors.contact_us_sub_information_title_text
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="contact_us_sub_information_title_text"
                      placeholder={t('page.contact_us_sub_information_title_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_us_sub_information_title_text}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_us_sub_information_title_text &&
                      formik.errors.contact_us_sub_information_title_text ? (
                        <div>{t(formik.errors.contact_us_sub_information_title_text)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_us_send_enquiry_heading_text_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.contact_us_send_enquiry_heading_text &&
                        formik.errors.contact_us_send_enquiry_heading_text
                          ? 'form-field-error'
                          : formik.touched.contact_us_send_enquiry_heading_text &&
                            !formik.errors.contact_us_send_enquiry_heading_text
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="contact_us_send_enquiry_heading_text"
                      placeholder={t('page.contact_us_send_enquiry_heading_text_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_us_send_enquiry_heading_text}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_us_send_enquiry_heading_text &&
                      formik.errors.contact_us_send_enquiry_heading_text ? (
                        <div>{t(formik.errors.contact_us_send_enquiry_heading_text)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_us_email_information_title_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.contact_us_email_information_title_text &&
                        formik.errors.contact_us_email_information_title_text
                          ? 'form-field-error'
                          : formik.touched.contact_us_email_information_title_text &&
                            !formik.errors.contact_us_email_information_title_text
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="contact_us_email_information_title_text"
                      placeholder={t('page.contact_us_email_information_title_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_us_email_information_title_text}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_us_email_information_title_text &&
                      formik.errors.contact_us_email_information_title_text ? (
                        <div>{t(formik.errors.contact_us_email_information_title_text)}</div>
                      ) : null}
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_us_admin_email_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.contact_us_admin_email &&
                        formik.errors.contact_us_admin_email
                          ? 'form-field-error'
                          : formik.touched.contact_us_admin_email &&
                            !formik.errors.contact_us_admin_email
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="contact_us_admin_email"
                      placeholder={t('page.contact_us_admin_email_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_us_admin_email}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_us_admin_email &&
                      formik.errors.contact_us_admin_email ? (
                        <div>{t(formik.errors.contact_us_admin_email)}</div>
                      ) : null}
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_us_seo_title_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.contact_us_seo_title && formik.errors.contact_us_seo_title
                          ? 'form-field-error'
                          : formik.touched.contact_us_seo_title &&
                            !formik.errors.contact_us_seo_title
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="contact_us_seo_title"
                      placeholder={t('page.contact_us_seo_title_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_us_seo_title}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_us_seo_title && formik.errors.contact_us_seo_title ? (
                        <div>{t(formik.errors.contact_us_seo_title)}</div>
                      ) : null}
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_us_seo_description')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.contact_us_seo_description &&
                        formik.errors.contact_us_seo_description
                          ? 'form-field-error'
                          : formik.touched.contact_us_seo_description &&
                            !formik.errors.contact_us_seo_description
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="contact_us_seo_description"
                      placeholder={t('page.contact_us_seo_description_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_us_seo_description}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_us_seo_description &&
                      formik.errors.contact_us_seo_description ? (
                        <div>{t(formik.errors.contact_us_seo_description)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.cancel_button_text')}
                  </span>
                  <TNButton
                    type="submit"
                    loading={isLoading}
                    isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                    {t('page.save_button_text')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
  //#16818d
};
ContactUsSetting.propTypes = {
  t: PropTypes.func,
};
export default ContactUsSetting;
