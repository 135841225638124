/* Auth */
import client from 'libs/HttpClient';

class AdminAuthService {
  static adminLogin(request) {
    return client.post('/admin/auth/login', request);
  }
  static adminForgotPassword(request) {
    return client.post('/admin/auth/forgot-password', request);
  }

  static adminOtpVerify(request) {
    return client.post('/admin/auth/verify-forgot-otp', request);
  }
  static adminResetPassword(request) {
    return client.put('/admin/auth/reset-password', request);
  }
}

export { AdminAuthService };
