import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'phonecheck', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const phoneRegExp = /^\d{7,15}?$/;
    if (value && value.length > 0) {
      return phoneRegExp.test(value);
    }
    return true;
  });
});
let validationSchema = Yup.object({
  first_name: Yup.string('page.edit_firstname_validation_required')
    .matches(/^[A-Za-z ]*$/, ('page.edit_firstname_validation_match'))
    .required(('page.edit_firstname_validation_required')),

  last_name: Yup.string('page.edit_lastname_validation_required')
    .matches(/^[A-Za-z ]*$/, ('page.edit_lastname_validation_match'))
    .required(('page.edit_lastname_validation_required')),

  email: Yup.string('page.edit_email_validation_required')
    .email(('page.login_email_validation_email'))
    .required(('page.edit_email_validation_required')),

  contact_no: Yup.string()
    .required('page.profile_phone_required')
    .phonecheck('page.profile_mobile_number_match'),
  country_id: Yup.string(('page.profile_country_id_required')).required(
    ('page.profile_country_id_required')
  )
})

let emailValidationSchema = Yup.object({
  email: Yup.string('page.edit_email_validation_required')
    .email(('page.login_email_validation_email'))
    .required(('page.edit_email_validation_required'))
});
let validationSchemaVerfiy = Yup.object({
  otp: Yup.string()
    .required('page.profile_email_otp_required')
    .matches(/^[0-9\s]+$/, 'page.profile_email_otp_matches')
});
export { validationSchema, emailValidationSchema, validationSchemaVerfiy };
