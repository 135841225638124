/* Translations */
import client from 'libs/HttpClient';

class TranslationService {
  static listTranslation(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    return client.get(`/admin/translation/list?search=${searchText}&page=${page_no}`, request);
  }
  static addTranslation(request) {
    return client.post(`/admin/translation/add`, request);
  }
  static viewTranslation(params) {
    return client.get(`/admin/translation/edit/${params}`);
  }
  static updateTranslation(request) {
    return client.post(`/admin/translation/update`, request);
  }
  static syncTranslation(request) {
    return client.get(`/admin/translation/sync-text`, request);
  }
}
export { TranslationService };
