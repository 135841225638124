import client from 'libs/HttpClient';

class QuestionaryService {
  static questionaryList(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const answer_type = request.queryKey[4];
    return client.get(
      `/admin/questionary/list?search=${searchText}&page=${page_no}&status=${status}&answer_type=${answer_type}`,
      request
    );
  }
  static addQuestionary(request) {
    return client.post(`/admin/questionary/store`, request);
  }
  static viewQuestion(params) {
    return client.get(`/admin/questionary/get/${params}`);
  }
  static updateQuestion(request) {
    return client.post(`/admin/questionary/store`, request);
  }
  static deleteQuestion(request) {
    return client.delete(`/admin/questionary/delete/${request.id}`);
  }

  static statusChangeQuestion(request) {
    return client.post(`/admin/questionary/status-update`, request);
  }
}
export { QuestionaryService };
