import * as Yup from 'yup';

let validationSchema = Yup.object({
  contact_us_header_text: Yup.mixed(('page.contact_us_header_text_required')).required(
    ('page.contact_us_header_text_required')
  ),
  contact_us_sub_header_text: Yup.mixed(('page.contact_us_sub_header_text_required')).required(
    ('page.contact_us_sub_header_text_required')
  ),
  contact_us_information_title_text: Yup.mixed(('page.contact_us_information_title_text_required')).required(
    ('page.contact_us_information_title_text_required')
  ),
  contact_us_sub_information_title_text: Yup.mixed(('page.contact_us_sub_information_title_text_required')).required(
    ('page.contact_us_sub_information_title_text_required')
  ),
  contact_us_email_information_title_text: Yup.string(('page.contact_us_email_information_title_text_required')).email('page.contact_us_email_information_title_text_invalid_email').required(
    ('page.contact_us_email_information_title_text_required')
  ),
  contact_us_admin_email: Yup.string(('page.contact_us_admin_email_required')).email('page.contact_us_admin_email_invalid_email').required(
    ('page.contact_us_admin_email_required')
  )
});
export default validationSchema;
