import { DashboardService } from 'api';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useCounter = (onSuccess, onError = onDefaultError) => {
  return useQuery(['counter'], DashboardService.listCounter, {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useListCounter = ([userYearSelect], onSuccess, onError = onDefaultError) => {
  return useQuery(['user-counter', userYearSelect], DashboardService.listUserCounter, {
    onSuccess,
    onError,
    refetchOnWindowFocus: true,
  });
};

const useValuationCounter = ([valuationYearSelect], onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['valuation-counter', valuationYearSelect],
    DashboardService.listValuationCounter,
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
export { useCounter, useListCounter, useValuationCounter };
