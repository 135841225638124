import { React, useRef } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Card, Col, Row, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import validationSchema from './GeneralSettingValidation';
import { useStoreSettingData, useGetGeneralSettingData } from 'hooks';
import { addSetting } from 'store/features/settingSlice';
import { TNButton } from 'common/components';
import 'assets/scss/page/_generalsetting.scss';
import { imagePreviewFromik } from 'helpers';

const GeneralSetting = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let frontHomeLogoRef = useRef();
  let frontFaviconLogoRef = useRef();
  let adminHomeLogoRef = useRef();
  let adminFaviconLogoRef = useRef();
  let adminSidebarLogoRef = useRef();

  const { refetch: doViewHomeGeneral, isLoading: isLoadingData } = useGetGeneralSettingData(
    [true],
    ({ data: response }) => {
      if (response) {
        formik.values.general_admin_home_logo = response.general_admin_home_logo;
        formik.values.general_admin_favicon_logo = response.general_admin_favicon_logo;
        formik.values.general_front_home_logo = response.general_front_home_logo;
        formik.values.general_front_favicon_logo = response.general_front_favicon_logo;
        formik.values.general_facebook_link = response.general_facebook_link;
        formik.values.general_twitter_link = response.general_twitter_link;
        formik.values.general_linkedin_link = response.general_linkedin_link;
        formik.values.general_youtube_link = response.general_youtube_link;
        formik.values.general_instagram_link = response.general_instagram_link;
        formik.values.general_admin_sidebar_logo = response.general_admin_sidebar_logo;
        formik.values.general_service_charge = response.general_service_charge;
        formik.values.general_preliminary_offer = response.general_preliminary_offer;
        formik.values.general_contract_closing_days = response.general_contract_closing_days;
        const dataStore = {
          general_admin_home_logo: response.general_admin_home_logo,
          general_admin_favicon_logo: response.general_admin_favicon_logo,
          general_front_home_logo: response.general_front_home_logo,
          general_front_favicon_logo: response.general_front_favicon_logo,
          general_admin_sidebar_logo: response.general_admin_sidebar_logo,
          setting_get: true,
        };
        dispatch(addSetting(dataStore));
      }
    }
  );
  const { mutate: doStoreSettingData, isLoading } = useStoreSettingData((response) => {
    toast.success(response.message);
    setTimeout(function () {
      navigate('/dashboard');
      doViewHomeGeneral();
    }, 1500);
  });

  const formik = useFormik({
    initialValues: {
      general_admin_home_logo: '',
      general_admin_favicon_logo: '',
      general_front_home_logo: '',
      general_front_favicon_logo: '',
      general_facebook_link: '',
      general_twitter_link: '',
      general_linkedin_link: '',
      general_youtube_link: '',
      general_instagram_link: '',
      general_admin_sidebar_logo: '',
      general_service_charge: '',
      general_preliminary_offer: '',
      general_contract_closing_days: '',
    },
    validationSchema,
    onSubmit: (values) => {
      doStoreSettingData(values);
    },
  });
  /**
   * !This block will call on click cancel
   */
  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/dashboard/`, { replace: true });
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate(`/dashboard/`, { replace: true });
    }
  };
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item to="/setting/general" active>
          {t('page.general_setting_tab_general_setting')}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card className="inner-box">
        <h1 className="page-heading-center">{t('page.general_setting_header_text')}</h1>
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="change-align">
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_front_home_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={frontHomeLogoRef}
                      name="general_front_home_logo"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'general_front_home_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => frontHomeLogoRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image change-align">
                      {formik.values.general_front_home_logo && (
                        <div>
                          <img
                            src={imagePreviewFromik(formik.values.general_front_home_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('general_front_home_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.general_front_home_logo &&
                    formik.errors.general_front_home_logo ? (
                      <div>{t(formik.errors.general_front_home_logo)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_front_favicon_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={frontFaviconLogoRef}
                      name="general_front_favicon_logo"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'general_front_favicon_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => frontFaviconLogoRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image">
                      {formik.values.general_front_favicon_logo && (
                        <div>
                          <img
                            src={imagePreviewFromik(formik.values.general_front_favicon_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('general_front_favicon_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.general_front_favicon_logo &&
                    formik.errors.general_front_favicon_logo ? (
                      <div>{t(formik.errors.general_front_favicon_logo)}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="change-align">
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_admin_home_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={adminHomeLogoRef}
                      name="general_admin_home_logo"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'general_admin_home_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => adminHomeLogoRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image change-align">
                      {formik.values.general_admin_home_logo && (
                        <div>
                          <img
                            src={imagePreviewFromik(formik.values.general_admin_home_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('general_admin_home_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.general_admin_home_logo &&
                    formik.errors.general_admin_home_logo ? (
                      <div>{t(formik.errors.general_admin_home_logo)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_admin_favicon_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={adminFaviconLogoRef}
                      name="general_admin_favicon_logo"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'general_admin_favicon_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => adminFaviconLogoRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image">
                      {formik.values.general_admin_favicon_logo && (
                        <div>
                          <img
                            src={imagePreviewFromik(formik.values.general_admin_favicon_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('general_admin_favicon_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.general_admin_favicon_logo &&
                    formik.errors.general_admin_favicon_logo ? (
                      <div>{t(formik.errors.general_admin_favicon_logo)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_footer_facebook_link')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.general_facebook_link && formik.errors.general_facebook_link
                          ? 'form-field-error'
                          : formik.touched.general_facebook_link &&
                            !formik.errors.general_facebook_link
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="general_facebook_link"
                      placeholder={t('page.general_facebook_link_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.general_facebook_link}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.general_facebook_link &&
                      formik.errors.general_facebook_link ? (
                        <div>{t(formik.errors.general_facebook_link)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_footer_twitter_link')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.general_twitter_link && formik.errors.general_twitter_link
                          ? 'form-field-error'
                          : formik.touched.general_twitter_link &&
                            !formik.errors.general_twitter_link
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="general_twitter_link"
                      placeholder={t('page.general_twitter_link_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.general_twitter_link}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.general_twitter_link && formik.errors.general_twitter_link ? (
                        <div>{t(formik.errors.general_twitter_link)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_footer_linkedin_link')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.general_linkedin_link && formik.errors.general_linkedin_link
                          ? 'form-field-error'
                          : formik.touched.general_linkedin_link &&
                            !formik.errors.general_linkedin_link
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="general_linkedin_link"
                      placeholder={t('page.general_linkedin_link_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.general_linkedin_link}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.general_linkedin_link &&
                      formik.errors.general_linkedin_link ? (
                        <div>{t(formik.errors.general_linkedin_link)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_footer_youtube_link')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.general_youtube_link && formik.errors.general_youtube_link
                          ? 'form-field-error'
                          : formik.touched.general_youtube_link &&
                            !formik.errors.general_youtube_link
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="general_youtube_link"
                      placeholder={t('page.general_youtube_link_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.general_youtube_link}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.general_youtube_link && formik.errors.general_youtube_link ? (
                        <div>{t(formik.errors.general_youtube_link)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_footer_instagram_link')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.general_instagram_link &&
                        formik.errors.general_instagram_link
                          ? 'form-field-error'
                          : formik.touched.general_instagram_link &&
                            !formik.errors.general_instagram_link
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="general_instagram_link"
                      placeholder={t('page.general_instagram_link_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.general_instagram_link}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.general_instagram_link &&
                      formik.errors.general_instagram_link ? (
                        <div>{t(formik.errors.general_instagram_link)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="change-align">
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_admin_sidebar_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={adminSidebarLogoRef}
                      name="general_admin_sidebar_logo"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'general_admin_sidebar_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => adminSidebarLogoRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image change-align">
                      {formik.values.general_admin_sidebar_logo && (
                        <div>
                          <img
                            src={imagePreviewFromik(formik.values.general_admin_sidebar_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('general_admin_sidebar_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.general_admin_sidebar_logo &&
                    formik.errors.general_admin_sidebar_logo ? (
                      <div>{t(formik.errors.general_admin_sidebar_logo)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_service_charge')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.general_service_charge &&
                        formik.errors.general_service_charge
                          ? 'form-field-error'
                          : formik.touched.general_service_charge &&
                            !formik.errors.general_service_charge
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="general_service_charge"
                      placeholder={t('page.general_setting_service_charge_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.general_service_charge}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.general_service_charge &&
                      formik.errors.general_service_charge ? (
                        <div>{t(formik.errors.general_service_charge)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_preliminary_offer_charge')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.general_preliminary_offer &&
                        formik.errors.general_preliminary_offer
                          ? 'form-field-error'
                          : formik.touched.general_preliminary_offer &&
                            !formik.errors.general_preliminary_offer
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="general_preliminary_offer"
                      placeholder={t('page.general_general_preliminary_offer_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.general_preliminary_offer}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.general_preliminary_offer &&
                      formik.errors.general_preliminary_offer ? (
                        <div>{t(formik.errors.general_preliminary_offer)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_contract_closing_days')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.general_contract_closing_days &&
                        formik.errors.general_contract_closing_days
                          ? 'form-field-error'
                          : formik.touched.general_contract_closing_days &&
                            !formik.errors.general_contract_closing_days
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="general_contract_closing_days"
                      placeholder={t('page.general_general_contract_closing_days_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.general_contract_closing_days}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.general_contract_closing_days &&
                      formik.errors.general_contract_closing_days ? (
                        <div>{t(formik.errors.general_contract_closing_days)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton
                    type="submit"
                    disabled={isLoadingData}
                    loading={isLoading}
                    isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                    {t('page.general_setting_save_button')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};
GeneralSetting.propTypes = {
  t: PropTypes.func,
  isdirtyform: PropTypes.any,
};
export default GeneralSetting;
