import { createSlice } from '@reduxjs/toolkit';
import { setAuthToken } from 'libs/HttpClient';
const initialState = {
  user: '',
  isAdminLoggedIn: false,
  accessToken: '',
};
export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    addUser: (state, { payload }) => {
      state.user = payload.userData;
      state.isLoggedIn = true;
      state.accessToken = payload.accessToken;
      setAuthToken(payload.accessToken);
      return state;
    },
    clearUser: (state) => {
      state.user = '';
      state.isLoggedIn = false;
      state.accessToken = '';
      return state;
    },
    updateUserData: (state, { payload }) => {
      state.user = payload.userData;
      return state;
    },
    logoutSuccess: () => initialState,
  },
});
export const { addUser, clearUser, logoutSuccess, updateUserData } = userSlice.actions;
export const loggedUser = (state) => state.user;
export default userSlice.reducer;
