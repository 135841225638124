import { createSlice } from '@reduxjs/toolkit';
const intialState = {
  setting_get: true,
  general_admin_home_logo: '',
  general_admin_favicon_logo: '',
  general_front_home_logo: '',
  general_front_favicon_logo: '',
  general_admin_sidebar_logo: ''
};
export const settingSlice = createSlice({
  name: 'setting',
  initialState: intialState,
  reducers: {
    addSetting: (state, { payload }) => {
      state.general_admin_home_logo = payload.general_admin_home_logo;
      state.general_admin_favicon_logo = payload.general_admin_favicon_logo;
      state.general_front_home_logo = payload.general_front_home_logo;
      state.general_front_favicon_logo = payload.general_front_favicon_logo;
      state.general_admin_sidebar_logo = payload.general_admin_sidebar_logo;
      state.setting_get = payload.setting_get;
      return state;
    },
    clearSetting: () => intialState
  }
});
export const { addSetting, clearSetting } = settingSlice.actions;
export const settingData = (state) => state.setting;
export default settingSlice.reducer;
