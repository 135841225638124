export const API_URL = 'https://api.gable2closingtable.com:3001/api/v1/';
export const WEB_URL = 'https://127.0.0.1:3000';
export const CHAT_URL = 'https://gable2closingtable.com:3003';
export const CKEDITOR_IMAGE = 'https://api.gable2closingtable.com:3001/api/v1/admin/blog-post/ckuploads';
export const DATE_FORMAT = "MM/DD/YYYY";
export const  CHAT_DATE_FORMAT = 'MMM DD, YYYY hh:mm a';
export const USER_STAGE = {
  AUTH: 'AUTH',
  DASHBOARD: 'DASHBOARD',
};
