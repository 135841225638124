import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { BlogPostService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useListBlogPost = (
  [page_no, searchText, status, blog_category_id],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['blog-post-list', page_no, searchText, status, blog_category_id],
    BlogPostService.listBlogPost,
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddBlogPost = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogPostService.addBlogPost, {
    onSuccess,
    onError,
  });
};

const useViewBlogPost = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('blog-post-view', () => BlogPostService.viewBlogPost(id), {
    onSuccess,
    onError,
  });
};

const useUpdateBlogPost = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogPostService.updateBlogPost, {
    onSuccess,
    onError,
  });
};

const useGetBlogCategoryList = (onSuccess, onError = onDefaultError) => {
  return useQuery('blog-post-get-category', BlogPostService.getBlogCategory, {
    onSuccess,
    onError,
  });
};

const useGetBlogCategoryListFilter = (onSuccess, onError = onDefaultError) => {
  return useQuery('blog-post-get-category-list', BlogPostService.getBlogCategoryList, {
    onSuccess,
    onError,
  });
};

const useStatusChangeBlogPost = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogPostService.statusChangeBlogPost, {
    onSuccess,
    onError,
  });
};

const useDeleteBlogPost = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogPostService.deleteBlogPost, {
    onSuccess,
    onError,
  });
};

export {
  useListBlogPost,
  useAddBlogPost,
  useViewBlogPost,
  useUpdateBlogPost,
  useGetBlogCategoryListFilter,
  useDeleteBlogPost,
  useGetBlogCategoryList,
  useStatusChangeBlogPost,
};
