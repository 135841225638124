import { useDispatch } from 'react-redux';
import { React, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Select from 'react-select';
import { Bar } from 'react-chartjs-2';
// import { loggedUser } from 'store/features/userSlice';
import { updateFcmToken } from 'store/features/fcmSlice';
import { useCounter, useListCounter, useTokenSave, useValuationCounter } from 'hooks';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const Dashboard = ({ t }) => {
  const dispatch = useDispatch();
  // const data = useSelector(loggedUser);
  const [counterData, setCounterData] = useState();
  const [userCounterData, setUserCounterData] = useState();
  const [userValuationData, setValuationCounterData] = useState([
    {
      preliminary_price: 0,
      service_charge: 0,
      repair_charge: 0,
      estimated_cost: 0,
    },
    {
      preliminary_price: 0,
      service_charge: 0,
      repair_charge: 0,
      estimated_cost: 0,
    },
    {
      preliminary_price: 0,
      service_charge: 0,
      repair_charge: 0,
      estimated_cost: 0,
    },
    {
      preliminary_price: 0,
      service_charge: 0,
      repair_charge: 0,
      estimated_cost: 0,
    },
    {
      preliminary_price: 0,
      service_charge: 0,
      repair_charge: 0,
      estimated_cost: 0,
    },
    {
      preliminary_price: 0,
      service_charge: 0,
      repair_charge: 0,
      estimated_cost: 0,
    },
    {
      preliminary_price: 0,
      service_charge: 0,
      repair_charge: 0,
      estimated_cost: 0,
    },
    {
      preliminary_price: 0,
      service_charge: 0,
      repair_charge: 0,
      estimated_cost: 0,
    },
    {
      preliminary_price: 0,
      service_charge: 0,
      repair_charge: 0,
      estimated_cost: 0,
    },
    {
      preliminary_price: 0,
      service_charge: 0,
      repair_charge: 0,
      estimated_cost: 0,
    },
    {
      preliminary_price: 0,
      service_charge: 0,
      repair_charge: 0,
      estimated_cost: 0,
    },
    {
      preliminary_price: 0,
      service_charge: 0,
      repair_charge: 0,
      estimated_cost: 0,
    },
  ]);
  const [yearData, setYearData] = useState();
  const [userYearSelect, setUserYearSelect] = useState(
    localStorage.dashboardYearList !== undefined && localStorage.dashboardYearList !== ''
      ? JSON.parse(localStorage.dashboardYearList).userYearSelect
      : ''
  );
  const [valuationYearSelect, setValuationYearSelect] = useState(
    localStorage.dashboardYearList !== undefined && localStorage.dashboardYearList !== ''
      ? JSON.parse(localStorage.dashboardYearList).valuationYearSelect
      : ''
  );
  const navigate = useNavigate();
  const { mutate: doUpdateToken } = useTokenSave(
    () => {},
    () => {}
  );
  const { isLoading: counterLoading } = useCounter((res) => {
    setCounterData(res.data);
    setYearData(res.data.yearData);
  });

  const options1 = [];
  if (yearData !== undefined) {
    yearData.map((val) => {
      if (val.year !== '') {
        options1.push({
          value: val.year,
          label: val.year,
        });
      }
      return true;
    });
  }

  useEffect(() => {
    let dashboardYearList = {
      userYearSelect: userYearSelect,
      valuationYearSelect: valuationYearSelect,
    };
    localStorage.dashboardYearList = JSON.stringify(dashboardYearList);
  }, [userYearSelect, valuationYearSelect]);

  const { refetch, isLoading: userDataCount } = useListCounter([userYearSelect], (res) => {
    setUserCounterData(res.data);
  });

  useEffect(() => {
    refetch();
  }, [refetch, userYearSelect]);

  useEffect(() => {
    valuationFetch();
  }, [valuationYearSelect]);

  const { refetch: valuationFetch, isLoading: userValuationCount } = useValuationCounter(
    [valuationYearSelect],
    (res) => {
      setValuationCounterData(res.data.resData);
    }
  );

  const fcmToken = localStorage.fcmToken;
  useEffect(() => {
    if (fcmToken !== undefined && fcmToken !== '') {
      doUpdateToken({ token: fcmToken });
      dispatch(updateFcmToken({ token: fcmToken }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doUpdateToken, fcmToken]);

  if (userValuationCount || userDataCount) {
    return null;
  }

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Bar Chart',
      },
    },
  };

  const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const chartData = {
    labels,
    datasets: [
      {
        label: 'User',
        data: userDataCount
          ? labels.map(() => 0)
          : labels.map((ele, index) => userCounterData?.user_data[index]),
        backgroundColor: 'rgba(255, 99, 132, 1)',
      },
    ],
  };
  const OfferData = {
    labels,
    datasets: [
      {
        label: t('page.dashboard_chart_preliminary_price_lable'),
        data: userValuationCount
          ? labels.map(() => 0)
          : labels.map((ele, index) => userValuationData[index]?.preliminary_price),
        backgroundColor: 'rgba(2, 206, 230, 1)',
      },
      {
        label: t('page.dashboard_chart_service_charge_lable'),
        data: userValuationCount
          ? labels.map(() => 0)
          : labels.map((ele, index) => userValuationData[index]?.service_charge),
        backgroundColor: 'rgba(172, 214, 73, 1)',
      },
      {
        label: t('page.dashboard_chart_repair_charge_lable'),
        data: userValuationCount
          ? labels.map(() => 0)
          : labels.map((ele, index) => userValuationData[index]?.repair_charge),
        backgroundColor: 'rgba(24,24,24, 1)',
      },
      {
        label: t('page.dashboard_chart_estimated_cost_lable'),
        data: userValuationCount
          ? labels.map(() => 0)
          : labels.map((ele, index) => userValuationData[index]?.estimated_cost),
        backgroundColor: 'rgba(141, 140, 140, 1)',
      },
    ],
  };
  userValuationData.map((ele, index) => {
    return userValuationData[index]?.estimated_cost;
  });
  return (
    <>
      <h1>{t('page.dashboard')}</h1>
      <Row>
        <Col lg={3}>
          <div className="main-card-dashboard mb-3">
            <div className="text-center">
              <h5 className="card-title mb-3">{t('page.total_user')}</h5>
              <p className="card-text">{counterData?.totalUsers}</p>
              <button
                className="page-link"
                onClick={() => {
                  navigate('/users/list');
                }}>
                {t('page.dashboard_user_list')}
              </button>
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="main-card-dashboard">
            <div className="text-center">
              <h5 className="card-title mb-3">{t('page.pending_value')}</h5>
              <p className="card-text">{counterData?.pendingValuation}</p>
              <button className="page-link" onClick={() => navigate('/valuation/pending/list')}>
                {t('page.dashboard_pending_list')}
              </button>
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="main-card-dashboard">
            <div className="text-center">
              <h5 className="card-title mb-3">{t('page.completed_valuation_dashboard')}</h5>
              <p className="card-text">{counterData?.completeValuation?.count}</p>
              <button className="page-link" onClick={() => navigate('/valuation/completed/list')}>
                {t('page.dashboard_completed_list')}
              </button>
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="main-card-dashboard">
            <div className="text-center">
              <h5 className="card-title mb-3">{t('page.completed_value')}</h5>
              <p className="card-text">
                ${' '}
                {counterData?.completeValuation?.estimated_cost.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </p>
              <button className="page-link" onClick={() => navigate('/valuation/completed/list')}>
                {t('page.dashboard_completed_list')}
              </button>
            </div>
          </div>
        </Col>
      </Row>

      <div className="user-data-chart mt-5">
        <div className="row">
          <h5 className="text-start col-lg-8">{t('page.user_data_created')}</h5>
          <div className="text-end col-lg-4">
            <Form.Group>
              <Select
                className="filter-column"
                name="yeardata"
                options={counterLoading ? options1 : options1}
                value={options1.filter((option) => option.value === userYearSelect)}
                onChange={(selectedOption) => {
                  setUserYearSelect(selectedOption.value);
                }}
              />
            </Form.Group>
          </div>

          <div className={`${userDataCount ? 'd-none' : ''}`}>
            <Bar options={options} data={chartData} />
          </div>
        </div>
      </div>
      <div className="offer-data-chart mt-5">
        <div className="row">
          <h5 className="text-start col-lg-8">{t('page.offer_completed_price')}</h5>
          <div className="text-end col-lg-4">
            <Form.Group>
              <Select
                className="filter-column"
                name="yeardata"
                options={counterLoading ? options1 : options1}
                value={options1.filter((option) => option.value === valuationYearSelect)}
                onChange={(selectedOption) => {
                  setValuationYearSelect(selectedOption.value);
                }}
              />
            </Form.Group>
          </div>
          {userValuationCount ? null : (
            <div>
              <Bar options={options} data={OfferData} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Dashboard;
