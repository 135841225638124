import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { settingData } from "../store/features/settingSlice";
import { SettingService } from "../api";

const onDefaultError = (error) => {
  toast.error((error.message));
};

const useGetGeneralSettingData = (get_status, onSuccess, onError) => {
  const sent = get_status ? true : false;
  const getSettingData = useSelector(settingData);
  return useQuery(
    'setting-data-general',
    () => {
      return SettingService.getGeneralSettingData();
    },
    {
      onSuccess,
      onError,
      enabled: sent ? true : getSettingData.setting_get
    }
  );
};

const useGetAboutSettingData = (get_status, onSuccess, onError) => {
  const sent = get_status ? true : false;
  const getSettingData = useSelector(settingData);
  return useQuery(
    'setting-data-bout',
    () => {
      return SettingService.getAboutSettingData();
    },
    {
      onSuccess,
      onError,
      enabled: sent ? true : getSettingData.setting_get
    }
  );
};

const useGetHomeSettingData = (get_status, onSuccess, onError) => {
  const sent = get_status ? true : false;
  const getSettingData = useSelector(settingData);
  return useQuery(
    'setting-data-home',
    () => {
      return SettingService.getHomeSettingData();
    },
    {
      onSuccess,
      onError,
      enabled: sent ? true : getSettingData.setting_get
    }
  );
};

const useGetContactUsSettingData = (get_status, onSuccess, onError) => {
  const sent = get_status ? true : false;
  const getSettingData = useSelector(settingData);
  return useQuery(
    'setting-data-contact',
    () => {
      return SettingService.getContactUsSettingData();
    },
    {
      onSuccess,
      onError,
      enabled: sent ? true : getSettingData.setting_get
    }
  );
};

const useGetFaqSettingData = (get_status, onSuccess, onError) => {
  const sent = get_status ? true : false;
  const getSettingData = useSelector(settingData);
  return useQuery(
    'setting-data-faq',
    () => {
      return SettingService.getFaqSettingData();
    },
    {
      onSuccess,
      onError,
      enabled: sent ? true : getSettingData.setting_get
    }
  );
};

const useStoreSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeSettingData, {
    onSuccess,
    onError,
  });
};

export { useGetGeneralSettingData, useStoreSettingData,useGetFaqSettingData,useGetHomeSettingData,useGetAboutSettingData, useGetContactUsSettingData };
