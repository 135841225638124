import * as Yup from 'yup';

let validationSchema = Yup.object({
  new_password: Yup.string()
    .required('page.new_password_required')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*)[A-Za-z\d@$!%*#?&]{6,}$/, 'page.strong_password_validation_text'),
  confirm_password: Yup.string()
    .required('page.confirm_password_required')
    .oneOf([Yup.ref('new_password'), null], "page.new_password_and_confirm_password_not_match"),
});

export default validationSchema;
