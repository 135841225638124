import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Container, Form, InputGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { PublicLayout, LogoUrlAdmin } from 'common/layouts';
import validationSchema from './LoginValidation';
import { useAdminLogin } from 'hooks';
import { addUser } from 'store/features/userSlice';
import { TNButton } from 'common/components';

const LoginPage = ({ t }) => {
  const { mutate: doAdminLogin, isLoading } = useAdminLogin((res) => {
    toast.success(res.message);
    const dataStore = {
      userData: res.data.user_data,
      isLoggedIn: true,
      accessToken: res.data.accessToken,
    };
    dispatch(addUser(dataStore));
  });

  // Add class in body
  useEffect(() => {
    document.body.classList.add('bg-box');
  }, []);

  // Show Hide Password
  const [isRevealPwd, setIsRevealPwd] = useState();
  const dispatch = useDispatch();

  // Formik
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      doAdminLogin(values);
    },
  });
  return (
    <PublicLayout>
      <div className="auth-pages">
        <Container>
          <div className="background-box">
            <div>
              <div className="brand-logo">
                <LogoUrlAdmin />
              </div>
              <div className="login-heading">
                <h1 className="page-heading-center">{t('page.login_heading_label_text')}</h1>
              </div>
              <div>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="field-label">{t('page.email_label_text')}</Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.email && formik.errors.email
                          ? 'form-field-error'
                          : formik.touched.email && !formik.errors.email
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="email"
                      placeholder={t('page.email_placeholder_text')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.email && formik.errors.email ? (
                        <div>{t(formik.errors.email)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <div>
                    <Form.Label className="field-label field-label-top">
                      {t('page.password_label_text')}
                    </Form.Label>
                    <InputGroup className="form-group-field">
                      <FormControl
                        className={
                          '' +
                          (formik.touched.password && formik.errors.password
                            ? 'form-field-error'
                            : formik.touched.password && !formik.errors.password
                            ? 'form-field-success'
                            : '')
                        }
                        name="password"
                        placeholder={t('page.password_placeholder_text')}
                        autoComplete="off"
                        type={isRevealPwd ? 'text' : 'password'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <InputGroup.Text
                        className={
                          '' +
                          (formik.touched.password && formik.errors.password
                            ? 'form-field-error'
                            : formik.touched.password && !formik.errors.password
                            ? 'form-field-success'
                            : '')
                        }>
                        <FontAwesomeIcon
                          onClick={() => setIsRevealPwd((prevState) => !prevState)}
                          icon={isRevealPwd ? faEye : faEyeSlash}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    <div className="form-field-error-text">
                      {formik.touched.password && formik.errors.password ? (
                        <div>{t(formik.errors.password)}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="link-right">
                    <Link to="/forget-password">{t('page.forgot_password_link_text')}</Link>
                  </div>
                  <div className="primary-button">
                    <TNButton
                      type="submit"
                      loading={isLoading}
                      isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                      {t('page.login_button_text')}
                    </TNButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </PublicLayout>
  );
};

export { LoginPage };
