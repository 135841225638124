import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes, Navigate } from 'react-router-dom';

const Esignature = React.lazy(() => import('./EsignatureList'));
const AddEsignature = React.lazy(() => import('./AddEsignature'));
const EditEsignature = React.lazy(() => import('./EditEsignature'));

const RejectionRouting = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<Esignature t={t} />} />
      <Route index exact path="/add" element={<AddEsignature t={t} />} />
      <Route index exact path="/edit/:id" element={<EditEsignature t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
RejectionRouting.propTypes = {
  t: PropTypes.func,
};

export default RejectionRouting;
