/* User */
import client from 'libs/HttpClient';

class UserService {
  static getProfile(request) {
    return client.get('/admin/get-profile', request);
  }
  static updatePassword(request) {
    return client.post('/admin/change-password', request);
  }
  static updateProfile(request) {
    return client.post('/admin/edit-profile', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static getCountryCodeList(request) {
    return client.get('/admin/country-code-list', request);
  }
  static updateEmailVerify(request) {
    return client.post('/admin/verify-new-email', request);
  }
  static updateEmail(request) {
    return client.post('/admin/edit-email', request);
  }
  static resendOtp(request) {
    return client.post('/admin/resend-otp', request);
  }
}

export { UserService };
