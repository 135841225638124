import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { settingData } from 'store/features/settingSlice';

import { Sidebar } from 'common/layouts/Sidebar/Sidebar';
import { Header } from 'common/layouts/Header/Header';
import { useNavigate } from 'react-router';

const LogoUrlAdmin = () => {
  const navigate = useNavigate();
  const getSettingData = useSelector(settingData);
  return (
    <img
      src={getSettingData.general_admin_sidebar_logo}
      alt="g2ct_admin_logo"
      width={'100%'}
      className="nav-brand"
      onClick={() => {
        navigate('/dashboard');
      }}
    />
  );
};
const SideBarUrlAdmin = () => {
  const getSettingData = useSelector(settingData);
  return (
    <img
      src={getSettingData.general_admin_sidebar_logo}
      alt="g2ct_sidebar_logo"
      width={'100%'}
      className="nav-brand"
    />
  );
};
const PrivateLayout = (props) => {
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  let bodyElement = document.getElementsByTagName('body')[0];
  bodyElement.className = isActive ? 'overly bg-white' : 'bg-white';

  return (
    <>
      <div {...props}>
        <Header toggleClass={toggleClass} t={t} />
        <div id="wrapper" className={isActive ? 'toggled' : ''}>
          <Sidebar toggleClass={toggleClass} active={props.active} t={t} />
          <section id="content-wrapper" onClick={isActive ? toggleClass : null}>
            <Row>
              <Col lg={12} className="main-section">
                {props.children}
              </Col>
            </Row>
          </section>
        </div>
      </div>
    </>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.any.isRequired,
  active: PropTypes.string,
};

export { PrivateLayout, LogoUrlAdmin, SideBarUrlAdmin };
