import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { AdminAuthService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useAdminLogin = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminAuthService.adminLogin, {
    onSuccess,
    onError,
  });
};

const useAdminForgotPassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminAuthService.adminForgotPassword, {
    onSuccess,
    onError,
  });
};

const useAdminOTPVerify = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminAuthService.adminOtpVerify, {
    onSuccess,
    onError,
  });
};

const useAdminResetPassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminAuthService.adminResetPassword, {
    onSuccess,
    onError,
  });
};

export { useAdminLogin, useAdminForgotPassword, useAdminOTPVerify, useAdminResetPassword };
