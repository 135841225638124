import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { BookAppointmentService } from 'api/BookAppointmentService';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useBookAppointment = (onSuccess, onError = onDefaultError) => {
  return useMutation(BookAppointmentService.scheduleAppointment, {
    onSuccess,
    onError,
  });
};

const useListBooking = (
  [page_no, searchText, status, fromDate, toDate],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['blog-category-list', page_no, searchText, status, fromDate, toDate],
    BookAppointmentService.listBooking,
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useGetAvailibilitySlot = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-availability-slot-list'],
    BookAppointmentService.getAppointmentAvailability,
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

export { useBookAppointment, useListBooking, useGetAvailibilitySlot };
