import * as Yup from 'yup';
import moment from 'moment';

export const validationSchema = Yup.object({
  days_slot_1: Yup.array().of(
    Yup.object().shape({
      dayscheckbox: Yup.boolean(),
      // .required('page.book_appointment_day_1_checkbox_required')
      // .oneOf([true], 'page.book_appointment_day_1_checkbox_required'),
      days_start: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_1_start_time_required')
          .test('not empty', 'page.book_appointment_day_1_start_time_required', function (value) {
            return !!value;
          })
          .test(
            'start_time_test',
            'page.book_appointment_day_1_start_time_before',
            function (value) {
              const { days_end } = this.parent;
              if (days_end === undefined || days_end === '') {
                return true;
              }
              return isSameOrBefore(value, days_end);
            }
          ),
      }),
      days_end: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_1_end_time_required')
          .test('not empty', 'page.book_appointment_day_1_end_time_required', function (value) {
            return !!value;
          })
          .test('start_time_test', 'page.abook_appointment_day_1_end_time_after', function (value) {
            const { days_start } = this.parent;
            if (days_start === undefined || days_start === '') {
              return true;
            }
            return isSameOrAfter(value, days_start);
          }),
      }),
    })
  ),
  days_slot_2: Yup.array().of(
    Yup.object().shape({
      dayscheckbox: Yup.boolean(),
      // .required('page.book_appointment_day_2_checkbox_required')
      // .oneOf([true], 'page.book_appointment_day_2_checkbox_required'),
      days_start: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_2_start_time_required')
          .test('not empty', 'page.book_appointment_day_2_start_time_required', function (value) {
            return !!value;
          })
          .test(
            'start_time_test',
            'page.book_appointment_day_2_start_time_before',
            function (value) {
              const { days_end } = this.parent;
              if (days_end === undefined || days_end === '') {
                return true;
              }
              return isSameOrBefore(value, days_end);
            }
          ),
      }),
      days_end: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_2_end_time_required')
          .test('not empty', 'page.book_appointment_day_2_end_time_required', function (value) {
            return !!value;
          })
          .test('start_time_test', 'page.abook_appointment_day_2_end_time_after', function (value) {
            const { days_start } = this.parent;
            if (days_start === undefined || days_start === '') {
              return true;
            }
            return isSameOrAfter(value, days_start);
          }),
      }),
    })
  ),
  days_slot_3: Yup.array().of(
    Yup.object().shape({
      dayscheckbox: Yup.boolean(),
      // .required('page.book_appointment_day_3_checkbox_required')
      // .oneOf([true], 'page.book_appointment_day_3_checkbox_required'),
      days_start: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_3_start_time_required')
          .test('not empty', 'page.book_appointment_day_3_start_time_required', function (value) {
            return !!value;
          })
          .test(
            'start_time_test',
            'page.book_appointment_day_3_start_time_before',
            function (value) {
              const { days_end } = this.parent;
              if (days_end === undefined || days_end === '') {
                return true;
              }
              return isSameOrBefore(value, days_end);
            }
          ),
      }),
      days_end: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_3_end_time_required')
          .test('not empty', 'page.book_appointment_day_3_end_time_required', function (value) {
            return !!value;
          })
          .test('start_time_test', 'page.abook_appointment_day_3_end_time_after', function (value) {
            const { days_start } = this.parent;
            if (days_start === undefined || days_start === '') {
              return true;
            }
            return isSameOrAfter(value, days_start);
          }),
      }),
    })
  ),
  days_slot_4: Yup.array().of(
    Yup.object().shape({
      dayscheckbox: Yup.boolean(),
      // .required('page.book_appointment_day_4_checkbox_required')
      // .oneOf([true], 'page.book_appointment_day_4_checkbox_required'),
      days_start: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_4_start_time_required')
          .test('not empty', 'page.book_appointment_day_4_start_time_required', function (value) {
            return !!value;
          })
          .test(
            'start_time_test',
            'page.book_appointment_day_4_start_time_before',
            function (value) {
              const { days_end } = this.parent;
              if (days_end === undefined || days_end === '') {
                return true;
              }
              return isSameOrBefore(value, days_end);
            }
          ),
      }),
      days_end: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_4_end_time_required')
          .test('not empty', 'page.book_appointment_day_4_end_time_required', function (value) {
            return !!value;
          })
          .test('start_time_test', 'page.book_appointment_day_4_end_time_after', function (value) {
            const { days_start } = this.parent;
            if (days_start === undefined || days_start === '') {
              return true;
            }
            return isSameOrAfter(value, days_start);
          }),
      }),
    })
  ),
  days_slot_5: Yup.array().of(
    Yup.object().shape({
      dayscheckbox: Yup.boolean(),
      // .required('page.book_appointment_day_5_checkbox_required')
      // .oneOf([true], 'page.book_appointment_day_5_checkbox_required'),
      days_start: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_5_start_time_required')
          .test('not empty', 'page.book_appointment_day_5_start_time_required', function (value) {
            return !!value;
          })
          .test(
            'start_time_test',
            'page.book_appointment_day_5_start_time_before',
            function (value) {
              const { days_end } = this.parent;
              if (days_end === undefined || days_end === '') {
                return true;
              }
              return isSameOrBefore(value, days_end);
            }
          ),
      }),
      days_end: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_5_end_time_required')
          .test('not empty', 'page.book_appointment_day_5_end_time_required', function (value) {
            return !!value;
          })
          .test('start_time_test', 'page.book_appointment_day_5_end_time_after', function (value) {
            const { days_start } = this.parent;
            if (days_start === undefined || days_start === '') {
              return true;
            }
            return isSameOrAfter(value, days_start);
          }),
      }),
    })
  ),
  days_slot_6: Yup.array().of(
    Yup.object().shape({
      dayscheckbox: Yup.boolean(),
      // .required('page.book_appointment_day_6_checkbox_required')
      // .oneOf([true], 'page.book_appointment_day_6_checkbox_required'),
      days_start: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_6_start_time_required')
          .test('not empty', 'page.book_appointment_day_6_start_time_required', function (value) {
            return !!value;
          })
          .test(
            'start_time_test',
            'page.book_appointment_day_6_start_time_before',
            function (value) {
              const { days_end } = this.parent;
              if (days_end === undefined || days_end === '') {
                return true;
              }
              return isSameOrBefore(value, days_end);
            }
          ),
      }),
      days_end: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_6_end_time_required')
          .test('not empty', 'page.book_appointment_day_6_end_time_required', function (value) {
            return !!value;
          })
          .test('start_time_test', 'page.book_appointment_day_6_end_time_after', function (value) {
            const { days_start } = this.parent;
            if (days_start === undefined || days_start === '') {
              return true;
            }
            return isSameOrAfter(value, days_start);
          }),
      }),
    })
  ),
  days_slot_7: Yup.array().of(
    Yup.object().shape({
      dayscheckbox: Yup.boolean(),
      // .required('page.book_appointment_day_7_checkbox_required')
      // .oneOf([true], 'page.book_appointment_day_7_checkbox_required'),
      days_start: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_7_start_time_required')
          .test('not empty', 'page.book_appointment_day_7_start_time_required', function (value) {
            return !!value;
          })
          .test(
            'start_time_test',
            'page.book_appointment_day_7_start_time_before',
            function (value) {
              const { days_end } = this.parent;
              if (days_end === undefined || days_end === '') {
                return true;
              }
              return isSameOrBefore(value, days_end);
            }
          ),
      }),
      days_end: Yup.mixed().when(['dayscheckbox'], {
        is: (dayscheckbox) => dayscheckbox === true,
        then: Yup.string()
          .required('page.book_appointment_day_7_end_time_required')
          .test('not empty', 'page.book_appointment_day_7_end_time_required', function (value) {
            return !!value;
          })
          .test('start_time_test', 'page.book_appointment_day_7_end_time_after', function (value) {
            const { days_start } = this.parent;
            if (days_start === undefined || days_start === '') {
              return true;
            }
            return isSameOrAfter(value, days_start);
          }),
      }),
    })
  ),
});
const isSameOrBefore = (startTime, endTime) => {
  return moment(startTime, 'HH:mm').isBefore(moment(endTime, 'HH:mm'));
};
const isSameOrAfter = (startTime, endTime) => {
  return moment(startTime, 'HH:mm').isAfter(moment(endTime, 'HH:mm'));
};
// const isSameOrBeforeDate = (startTime, endTime) => {
//   return moment(startTime, 'YYYY-MM-DD').isSameOrBefore(moment(endTime, 'YYYY-MM-DD'));
// };
// const isSameOrAfterDate = (startTime, endTime) => {
//   return moment(startTime, 'YYYY-MM-DD').isSameOrAfter(moment(endTime, 'YYYY-MM-DD'));
// };
