/* Blog Post */
import client from 'libs/HttpClient';

class BlogPostService {
  static listBlogPost(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const blog_category_id = request.queryKey[4] || '';
    return client.get(
      `/admin/blog-post/list?search=${searchText}&page=${page_no}&status=${status}&blog_category_id=${blog_category_id}`,
      request
    );
  }
  static addBlogPost(request) {
    return client.post(`/admin/blog-post/add`, request, {
      headers: {
          "Content-type": "multipart/form-data",
      },
    });
  }
  static viewBlogPost(params) {
    return client.get(`/admin/blog-post/edit/${params}`);
  }
  static updateBlogPost(request) {
    return client.post(`/admin/blog-post/update`, request, {
      headers: {
          "Content-type": "multipart/form-data",
      },
    });
  }
  static deleteBlogPost(request) {
    return client.get(`/admin/blog-post/delete/${request.id}`, request);
  }
  static getBlogCategory(request) {
    return client.get(`/admin/blog-post/blog-category-list`, request);
  }
  static getBlogCategoryList(request) {
    return client.get(`/admin/blog-post/blog-category-list-filter`, request);
  }
  static statusChangeBlogPost(request) {
    return client.get(`/admin/blog-post/update-status/${request.id}`, request);
  }
}
export { BlogPostService };
