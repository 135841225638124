/* Blog Post */
import client from 'libs/HttpClient';

class ChatService {
  static userChatList(request) {
    const search = request.queryKey[1];
    return client.get(`user/chat/user-list?search_key=${search}`, request);
  }

  static userMessageChatList(request) {
    const page_no = request.queryKey[1];
    const chat_room_id = request.queryKey[2];
    return client.get(
      `user/chat/message-list?page=${page_no}&chat_room_id=${chat_room_id}`,
      request
    );
  }

  static sendChatMessage(request) {
    return client.post(`user/chat/send-message`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
}
export { ChatService };
