import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { FaqCategoryService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useListFaqCategory = ([page_no, searchText, status], onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['faq-category-list', page_no, searchText, status],
    FaqCategoryService.listFaqCategory,
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddFaqCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqCategoryService.addFaqCategory, {
    onSuccess,
    onError,
  });
};

const useViewFaqCategory = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('faq-category-view', () => FaqCategoryService.viewFaqCategory(id), {
    onSuccess,
    onError,
  });
};

const useUpdateFaqCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqCategoryService.updateFaqCategory, {
    onSuccess,
    onError,
  });
};

const useStatusChangeFaqCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqCategoryService.statusChangeFaqCategory, {
    onSuccess,
    onError,
  });
};

const useDeleteFaqCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqCategoryService.deleteFaqCategory, {
    onSuccess,
    onError,
  });
};

export {
  useListFaqCategory,
  useAddFaqCategory,
  useViewFaqCategory,
  useUpdateFaqCategory,
  useDeleteFaqCategory,
  useStatusChangeFaqCategory,
};
