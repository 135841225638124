import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Container, Form, InputGroup, FormControl, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { faClose } from '@fortawesome/free-solid-svg-icons';

import { PublicLayout, LogoUrlAdmin } from 'common/layouts';
import validationSchema from './ResetPasswordValidation';
import { useAdminResetPassword } from 'hooks';
import { TNButton } from 'common/components';

const ResetPasswordPage = ({ t }) => {
  const navigate = useNavigate();

  const { mutate: doAdminResetPassword, isLoading } = useAdminResetPassword((res) => {
    toast.success(res.message);
    localStorage.reset_password = true;
    setTimeout(function () {
      localStorage.removeItem('verify_otp_token');
      localStorage.removeItem('reset_password');
      navigate('/login');
    }, 1500);
  });
  // Show Hide Password
  const [isRevealPwd, setIsRevealPwd] = useState();
  const [isCnfRevealPwd, setCnfIsRevealPwd] = useState();
  /**
   * This function is use for when click on cancel button
   */

  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate('/login');
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    document.body.classList.add('bg-box');
    if (localStorage.verify_otp_token === undefined || localStorage.verify_otp_token === '') {
      navigate('/login');
    }
  });
  // Formik
  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      values.reset_token = localStorage.verify_otp_token;
      doAdminResetPassword(values);
    },
  });
  return (
    <PublicLayout>
      <div className="auth-pages">
        <Container>
          <div className="background-box">
            <div>
              <div className="brand-logo">
                <LogoUrlAdmin />
              </div>

              <h1 className="page-heading-center">{t('page.reset_password_heading_text')}</h1>

              <div>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="field-label">
                      {t('page.reset_new_password_label_text')}
                    </Form.Label>
                    <InputGroup className="form-group-field">
                      <FormControl
                        className={
                          formik.touched.new_password && formik.errors.new_password
                            ? 'form-field-error'
                            : formik.touched.new_password && !formik.errors.new_password
                            ? 'form-field-success'
                            : ''
                        }
                        name="new_password"
                        placeholder={t('page.reset_new_password_placeholder_text')}
                        autoComplete="off"
                        type={isRevealPwd ? 'text' : 'password'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.new_password}
                      />
                      <InputGroup.Text
                        className={
                          formik.touched.new_password && formik.errors.new_password
                            ? 'form-field-error'
                            : formik.touched.new_password && !formik.errors.new_password
                            ? 'form-field-success'
                            : ''
                        }
                        id="basic-addon1">
                        <FontAwesomeIcon
                          onClick={() => setIsRevealPwd((prevState) => !prevState)}
                          icon={isRevealPwd ? faEye : faEyeSlash}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    <div className="form-field-error-text">
                      {formik.touched.new_password && formik.errors.new_password ? (
                        <div>{t(formik.errors.new_password)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <div>
                    <Form.Label className="field-label field-label-top">
                      {t('page.reset_confirm_password_label_text')}
                    </Form.Label>
                    <InputGroup className="form-group-field">
                      <FormControl
                        className={
                          formik.touched.confirm_password && formik.errors.confirm_password
                            ? 'form-field-error'
                            : formik.touched.confirm_password && !formik.errors.confirm_password
                            ? 'form-field-success'
                            : ''
                        }
                        name="confirm_password"
                        placeholder={t('page.reset_confirm_password_placeholder_text')}
                        autoComplete="off"
                        type={isCnfRevealPwd ? 'text' : 'password'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirm_password}
                      />
                      <InputGroup.Text
                        className={
                          formik.touched.confirm_password && formik.errors.confirm_password
                            ? 'form-field-error'
                            : formik.touched.confirm_password && !formik.errors.confirm_password
                            ? 'form-field-success'
                            : ''
                        }
                        id="basic-addon1">
                        <FontAwesomeIcon
                          onClick={() => setCnfIsRevealPwd((prevState) => !prevState)}
                          icon={isCnfRevealPwd ? faEye : faEyeSlash}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    <div className="form-field-error-text">
                      {formik.touched.confirm_password && formik.errors.confirm_password ? (
                        <div>{t(formik.errors.confirm_password)}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="primary-button">
                    <span className="link-center" onClick={handleCancel}>
                      {t('page.cancel_label_link_text')}
                    </span>
                    <TNButton
                      type="submit"
                      loading={isLoading}
                      isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                      {t('page.save_button_text')}
                    </TNButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </PublicLayout>
  );
};

export { ResetPasswordPage };
