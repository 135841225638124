import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { UserMasterService } from 'api';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAddUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.addUser, {
    onSuccess,
    onError,
  });
};

const useUpdateUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.updateUser, {
    onSuccess,
    onError,
  });
};

const useViewUser = (user_id, onSuccess, onError = onDefaultError) => {
  return useQuery('user-view', () => UserMasterService.viewUser(user_id), {
    onSuccess,
    onError,
  });
};

const useListUsers = (
  [page_no, searchText, status, adminStatus, userType, allianceType, fromDate, toDate, signUpType],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'user-list',
      page_no,
      searchText,
      status,
      adminStatus,
      userType,
      allianceType,
      fromDate,
      toDate,
      signUpType,
    ],
    UserMasterService.listUser,
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useStatusChangeUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.statusChangeUser, {
    onSuccess,
    onError,
  });
};
const useStatusChangeAdmin = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.statusChangeAdmin, {
    onSuccess,
    onError,
  });
};
const useUserDelete = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.userDelete, {
    onSuccess,
    onError,
  });
};
export {
  useAddUser,
  useUpdateUser,
  useViewUser,
  useListUsers,
  useUserDelete,
  useStatusChangeUser,
  useStatusChangeAdmin,
};
