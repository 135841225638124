import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListBlogPostPage = React.lazy(() => import('./ListBlogPostPage'));
const AddBlogPostPage = React.lazy(() => import('./AddBlogPostPage'));
const EditBlogPostPage = React.lazy(() => import('./EditBlogPostPage'));

const BlogPostRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListBlogPostPage t={t} />} />
      <Route exact path="/add" element={<AddBlogPostPage t={t} />} />
      <Route exact path="/edit/:id" element={<EditBlogPostPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
BlogPostRoute.propTypes = {
  t: PropTypes.func
};
export default BlogPostRoute;
