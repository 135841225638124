import { React } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Card, Col, Row, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import 'react-confirm-alert/src/react-confirm-alert.css';
import validationSchema from './FaqSettingValidation';
import { useStoreSettingData, useGetFaqSettingData } from 'hooks';
import { TNButton } from 'common/components';
import 'assets/scss/page/_generalsetting.scss';

const FaqSettingPage = ({ t }) => {
  const navigate = useNavigate();

  const { refetch: doViewHomeFaq, isLoading: isLoadingData } = useGetFaqSettingData(
    [true],
    ({ data: response }) => {
      if (response) {
        formik.values.faq_page_heading = response.faq_page_heading;
        formik.values.faq_page_sub_heading = response.faq_page_sub_heading;
        formik.values.faq_page_seo_meta_title = response.faq_page_seo_meta_title;
        formik.values.faq_page_seo_meta_desc = response.faq_page_seo_meta_desc;
      }
    }
  );
  const { mutate: doStoreSettingData, isLoading } = useStoreSettingData((response) => {
    toast.success(response.message);
    setTimeout(function () {
      doViewHomeFaq();
      navigate('/dashboard');
    }, 1500);
  });
  const formik = useFormik({
    initialValues: {
      faq_page_heading: '',
      faq_page_sub_heading: '',
    },
    validationSchema,
    onSubmit: (values) => {
      doStoreSettingData(values);
    },
  });
  /**
   * !This block will call on click cancel
   */
  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/dashboard/`, { replace: true });
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate(`/dashboard/`, { replace: true });
    }
  };
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item to="/setting/faq" active>
          {t('page.general_setting_tab_faq_setting')}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card className="inner-box">
        <h1 className="page-heading-center">{t('page.faq_setting_header_text')}</h1>
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.faq_setting_heading')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.faq_page_heading && formik.errors.faq_page_heading
                          ? 'form-field-error'
                          : formik.touched.faq_page_heading && !formik.errors.faq_page_heading
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="faq_page_heading"
                      placeholder={t('page.faq_setting_heading_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.faq_page_heading}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.faq_page_heading && formik.errors.faq_page_heading ? (
                        <div>{t(formik.errors.faq_page_heading)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.faq_setting_sub_heading')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.faq_page_sub_heading && formik.errors.faq_page_sub_heading
                          ? 'form-field-error'
                          : formik.touched.faq_page_sub_heading &&
                            !formik.errors.faq_page_sub_heading
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="faq_page_sub_heading"
                      placeholder={t('page.faq_setting_sub_heading_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.faq_page_sub_heading}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.faq_page_sub_heading && formik.errors.faq_page_sub_heading ? (
                        <div>{t(formik.errors.faq_page_sub_heading)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.faq_seo_meta_title_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.faq_page_seo_meta_title &&
                        formik.errors.faq_page_seo_meta_title
                          ? 'form-field-error'
                          : formik.touched.faq_page_seo_meta_title &&
                            !formik.errors.faq_page_seo_meta_title
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="faq_page_seo_meta_title"
                      placeholder={t('page.faq_seo_meta_title_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.faq_page_seo_meta_title}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.faq_page_seo_meta_title &&
                      formik.errors.faq_page_seo_meta_title ? (
                        <div>{t(formik.errors.faq_page_seo_meta_title)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.faq_seo_meta_desc_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.faq_page_seo_meta_desc &&
                        formik.errors.faq_page_seo_meta_desc
                          ? 'form-field-error'
                          : formik.touched.faq_page_seo_meta_desc &&
                            !formik.errors.faq_page_seo_meta_desc
                          ? 'form-field-success'
                          : '')
                      }
                      as="textarea"
                      rows={3}
                      name="faq_page_seo_meta_desc"
                      placeholder={t('page.faq_seo_meta_desc_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.faq_page_seo_meta_desc}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.faq_page_seo_meta_desc &&
                      formik.errors.faq_page_seo_meta_desc ? (
                        <div>{t(formik.errors.faq_page_seo_meta_desc)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton
                    type="submit"
                    disabled={isLoadingData}
                    loading={isLoading}
                    isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                    {t('page.general_setting_save_button')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};
FaqSettingPage.propTypes = {
  t: PropTypes.func,
};
export default FaqSettingPage;
