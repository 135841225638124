import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ValuationService } from 'api/ValuationService';

const onDefaultError = (error) => {
  toast.error(error.message);
};
const useCurrentList = (
  [page_no, searchText, fromDate, toDate],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['current-list', page_no, searchText, fromDate, toDate],
    ValuationService.currentList,
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useRejectionList = (
  [page_no, searchText, fromDate, toDate],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['rejection-list', page_no, searchText, fromDate, toDate],
    ValuationService.RejectionList,
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const usePendingList = (
  [page_no, searchText, fromDate, toDate],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['pending-list', page_no, searchText, fromDate, toDate],
    ValuationService.PendingList,
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useCompletedList = (
  [page_no, searchText, fromDate, toDate],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['completed-list', page_no, searchText, fromDate, toDate],
    ValuationService.CompletedList,
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useDeleteValuation = (onSuccess, onError = onDefaultError) => {
  return useMutation(ValuationService.deleteValuation, {
    onSuccess,
    onError,
  });
};
const useGetOfferDetails = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['valuation_id', params], ValuationService.OfferDetails, {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useGetUserQuestionary = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['user-question-view-data', params], ValuationService.viewUserQuestionary, {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useReviseOffer = (onSuccess, onError = onDefaultError) => {
  return useMutation(ValuationService.revisedOffer, {
    onSuccess,
    onError,
  });
};
const useDeleteReviseOffer = (onSuccess, onError = onDefaultError) => {
  return useMutation(ValuationService.deleteReviseOffer, {
    onSuccess,
    onError,
  });
};

const useGetBookAppointment = (onSuccess, onError = onDefaultError) => {
  return useMutation(ValuationService.getAvaibilityofSlot, {
    onSuccess,
    onError,
  });
};

const useAddBookAppointment = (onSuccess, onError = onDefaultError) => {
  return useMutation(ValuationService.addBookingSlot, {
    onSuccess,
    onError,
  });
};

const useCancelReviseOffer = (onSuccess, onError = onDefaultError) => {
  return useMutation(ValuationService.cancelReviseOffer, {
    onSuccess,
    onError,
  });
};

const useGetValuationActity = (onSuccess, onError = onDefaultError) => {
  return useMutation(ValuationService.getValuationActivity, {
    onSuccess,
    onError,
  });
};

const useAssignQuestionary = (onSuccess, onError = onDefaultError) => {
  return useMutation(ValuationService.getAssignQuestionary, {
    onSuccess,
    onError,
  });
};

const useUploadDocument = (onSuccess, onError = onDefaultError) => {
  return useMutation(ValuationService.uploadDocument, { onSuccess, onError });
};

const useAttorneyDate = (onSuccess, onError = onDefaultError) => {
  return useMutation(ValuationService.addAttorneyDate, {
    onSuccess,
    onError,
  });
};
const useCancelConfirmValuation = (onSuccess, onError = onDefaultError) => {
  return useMutation(ValuationService.cancleConfirmValuation, {
    onSuccess,
    onError,
  });
};
const useCancelAttorneyDate = (onSuccess, onError = onDefaultError) => {
  return useMutation(ValuationService.cancleAttorneyDate, {
    onSuccess,
    onError,
  });
};

export {
  onDefaultError,
  useCurrentList,
  useAttorneyDate,
  useReviseOffer,
  useGetOfferDetails,
  useRejectionList,
  useCancelReviseOffer,
  usePendingList,
  useCompletedList,
  useGetBookAppointment,
  useAddBookAppointment,
  useGetValuationActity,
  useDeleteReviseOffer,
  useAssignQuestionary,
  useDeleteValuation,
  useGetUserQuestionary,
  useUploadDocument,
  useCancelConfirmValuation,
  useCancelAttorneyDate,
};
