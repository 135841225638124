import client from 'libs/HttpClient';

class EsignatureListService {
  static EsignatureList(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    return client.get(
      `/admin/signature/list?search=${searchText}&page=${page_no}&status=${status}`,
      request
    );
  }

  static deleteEsignatureList(request) {
    return client.delete(`/admin/signature/delete/${request.id}`, request);
  }
  static addEsignatureList(request) {
    return client.post(`/admin/signature/save`, request);
  }
  static viewEsignatureList(params) {
    return client.get(`/admin/signature/edit/${params}`);
  }
  static updateEsignatureList(request) {
    return client.post(`/admin/signature/save`, request);
  }
  static statusChangeEsignature(request) {
    return client.post(`/admin/signature/update-status`, request);
  }
  static getTempEsignature(request) {
    return client.get(`/admin/signature/template-list`);
  }
  static sendEsignDocument(request) {
    return client.post(`/admin/signature/send-contract`, request);
  }
}
export { EsignatureListService };
