/* Users */
import client from 'libs/HttpClient';

class UserMasterService {
  static listUser(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const userStatus = request.queryKey[3];
    const adminStatus = request.queryKey[4];
    const fromDate = request.queryKey[5] || '';
    const toDate = request.queryKey[6] || '';
    const signUpType = request.queryKey[7] || '';
    return client.get(
      `/admin/user-master/list?page=${page_no}&search=${searchText}&user_status=${userStatus}&admin_status=${adminStatus}&from_date=${fromDate}&to_date=${toDate}&signup_type=${signUpType}`,
      request
    );
  }
  static viewUser(params) {
    return client.get(`/admin/user-master/edit/${params}`);
  }
  static updateUser(request) {
    return client.post(`/admin/user-master/update`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static userDelete(request) {
    return client.get(`/admin/user-master/delete/${request.id}`, request);
  }
  static statusChangeUser(request) {
    return client.get(`/admin/user-master/update-user-status/${request.id}`, request);
  }
  static statusChangeAdmin(request) {
    return client.get(`/admin/user-master/update-admin-status/${request.id}`, request);
  }
}
export { UserMasterService };
