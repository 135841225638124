import * as Yup from 'yup';

let validationSchema = Yup.object({
  faq_page_heading: Yup.string('page.faq_setting_heading_required')
    .trim()
    .required('page.faq_setting_heading_required'),
  faq_page_sub_heading: Yup.string('page.faq_setting_sub_heading_required')
    .trim()
    .required('page.faq_setting_sub_heading_required'),
  faq_page_seo_meta_title: Yup.string('page.faq_setting_seo_title_required')
    .trim()
    .required('page.faq_setting_seo_title_required'),
  faq_page_seo_meta_desc: Yup.string('page.faq_setting_seo_description_required')
    .trim()
    .required('page.faq_setting_seo_description_required'),
});
export default validationSchema;
