import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import BookAppointment from './BookAppointment';

const PendingListPage = React.lazy(() => import('./Pending/PendingListPage'));
const CurrentList = React.lazy(() => import('./Current/CurrentList'));
const RejectionListPage = React.lazy(() => import('./Rejection/RejectionListPage'));
const ValuationActivityPage = React.lazy(() => import('./ValuationActivity'));
const ViewQuestionaryPage = React.lazy(() => import('./ViewQuestionary'));
const CompleteValuation = React.lazy(() => import('./Completed/CompleteValuation'));

const ValuationRoute = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/pending/list" element={<PendingListPage t={t} />} />
      <Route index exact path="/current/list" element={<CurrentList t={t} />} />
      <Route exact path="/completed/list" element={<CompleteValuation t={t} />} />
      <Route exact path="/viewvaluationList/:id" element={<ValuationActivityPage t={t} />} />
      <Route exact path="/rejection/list" element={<RejectionListPage t={t} />} />
      <Route exact path="/book-appointment/:id" element={<BookAppointment t={t} />} />
      <Route exact path="/questionary/:id" element={<ViewQuestionaryPage t={t} />} />

      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ValuationRoute.propTypes = {
  t: PropTypes.func,
};
export default ValuationRoute;
