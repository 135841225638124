/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';
import { React, useEffect, useRef, useState } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImage,
  faPaperPlane,
  faSearch,
  faBars,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { io } from 'socket.io-client';
import { useNavigate, useParams } from 'react-router';
import { saveAs } from 'file-saver';

import { loggedUser } from 'store/features/userSlice';
import { useChatUserList, useGetMessageList, useSendChatMessage } from 'hooks';
import { LogoUrlAdmin } from 'common/layouts';
import { CHAT_URL } from 'config';

const socket = io(CHAT_URL, { transports: ['websocket'] });

const ChatNew = ({ t }) => {
  const inputFile = useRef(null);
  const divRef = useRef(null);
  const { chat_room_id } = useParams();

  const userChatListIcon = useRef([]);

  const navigate = useNavigate();

  const [userListData, setUserListData] = useState([]);
  const loggedUserData = useSelector(loggedUser);
  const [isActive, setActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [chatUserImage, setChatUserImage] = useState('../images/user.png');
  const [chatUserName, setChatUserName] = useState('');
  const [chatUserId, setChatUserId] = useState('');
  const [chatUserChange, setChatUserChange] = useState(true);
  const chatUserRoomId = parseInt(chat_room_id, 10);
  const [newMessage, setNewMessage] = useState('');
  const [searchText, setSearchText] = useState('');
  const [connectChat, setConnectChat] = useState(false);
  const [usersData, setUsersData] = useState(
    localStorage.usersData !== undefined && localStorage.usersData !== ''
      ? JSON.parse(localStorage.usersData)
      : []
  );

  const { refetch } = useChatUserList([searchText], (res) => {
    setUserListData(res.data);
    handleUsersStatus();
  });

  const {
    refetch: chatMessageRefetch,
    isLoading: chatMessagesLoad,
    data: chatMessages,
  } = useGetMessageList([currentPage, chatUserRoomId], (res) => {
    setTotalPage(res.data.total_pages);
    scrollToBottom();
  });

  const handleUsersStatus = () => {
    if (usersData.length > 0) {
      usersData.map((value, index) => {
        if (value || index > 0) {
          //console.log(userChatListIcon.current[index]);
          const span = userChatListIcon.current[value.user_id];
          if (span) {
            if (value) {
              // console.log('online', value);
              span.className = 'online-dot';
            } else {
              // console.log('offline', value);
              span.className = 'offline-dot';
            }
          }
        }
        return true;
      });
    }
  };

  if (usersData.length > 0) {
    handleUsersStatus();
  }

  const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };

  const handleSelectUser = (chatRoom, user) => {
    setChatUserChange(true);
    navigate(`/chat/${chatRoom}`);
    setNewMessage(Math.floor(Math.random() * (1000 - 1 + 1) + 1));
  };

  const formik = useFormik({
    initialValues: {
      chat_room_id: chatUserRoomId,
      message: '',
      message_type: 'TEXT',
    },
    onSubmit(values) {
      // console.log(values);
    },
  });

  const scrollToBottom = () => {
    if (!chatMessagesLoad) {
      divRef.current?.scrollIntoView({ behavior: 'smooth' });
      const element = document.getElementById('sidebar-scroll');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        element.scrollTop = element.scrollHeight;
      }
    }
  };

  useEffect(() => {
    refetch();
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessage]);

  const handleMediaUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      var parts = e.target.value.split('.');
      const ext = parts[parts.length - 1];
      const imageExt = ['jpg', 'gif', 'bmp', 'png'];
      const videoExt = ['m4v', 'avi', 'mpg', 'mp4'];
      const pdfDocExt = [
        'pdf',
        'doc',
        'docx',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];
      let message_type = 'INVALID';
      if (imageExt.includes(ext)) {
        message_type = 'IMAGE';
      }
      if (videoExt.includes(ext)) {
        message_type = 'VIDEO';
      }
      if (pdfDocExt.includes(ext)) {
        message_type = 'DOCUMENT';
      }
      if (message_type !== 'INVALID') {
        formik.setFieldValue('message_type', message_type);
        const data = {
          room_id: chatUserRoomId,
          to_user_id: chatUserId,
          message_type,
          chat_media: e.target.files[0],
        };
        doSendMessage(data);
      } else {
        toast.error('Please select image, video or document file only');
      }
    }
  };
  const handleSendTextMessage = () => {
    if (formik.values.message !== '' && chatUserRoomId && chatUserId !== '') {
      const data = {
        room_id: chatUserRoomId,
        to_user_id: chatUserId,
        message_type: formik.values.message_type,
        body: formik.values.message,
      };
      doSendMessage(data);
    }
  };

  // This API will call when user clicks on Update Button
  const { mutate: doSendMessage } = useSendChatMessage((response) => {
    formik.setFieldValue('message', '');
    socket.emit('new_message', {
      chat_room_id: chatUserRoomId,
      from_user_id: loggedUserData.user.id,
      to_user_id: chatUserId,
      message_type: formik.values.message_type,
    });
    formik.setFieldValue('message_type', 'TEXT');
    chatMessageRefetch();
    setNewMessage(Math.floor(Math.random() * (1000 - 1 + 1) + 1));
  });

  if (!connectChat) {
    socket.emit('admin_connected', {
      user_id: loggedUserData.user.id,
    });
    setConnectChat(true);
  }

  socket.on('check_message_admin', (data) => {
    refetch();
    handleUsersStatus();
    chatMessageRefetch();
    setNewMessage(Math.floor(Math.random() * (1000 - 1 + 1) + 1));
  });

  socket.on('user_list_admin', (data) => {
    // console.log('update list');
    refetch();
    handleUsersStatus();
    chatMessageRefetch();
    setNewMessage(Math.floor(Math.random() * (1000 - 1 + 1) + 1));
  });

  // online offline icon
  socket.on('updateUserStatus', (data) => {
    refetch();
    if (data.length > 0) {
      const result = [];
      data.forEach((car, index) => result.push(index));
      // console.log(result);
      // console.log(JSON.parse(localStorage.usersData));
      localStorage.usersData = JSON.stringify(data);
      // localStorage.usersData = JSON.parse(localStorage.usersData)
      // setUsersData(data);
      setUsersData(JSON.parse(localStorage.usersData));
      handleUsersStatus();
    }
  });

  useEffect(() => {
    // if (usersData > 0) {
    handleUsersStatus();
    // }
  }, [usersData]);

  if (userListData.length && chatUserRoomId !== chat_room_id && chatUserChange) {
    const rmId = parseInt(chatUserRoomId, 10);
    for (var i = 0; i < userListData.length; i++) {
      if (userListData[i].chat_room_id === rmId) {
        setChatUserName(userListData[i].users.full_name);
        setChatUserId(userListData[i].users.id);
        if (userListData[i].users.user_image !== '') {
          setChatUserImage(userListData[i].users.user_image_path);
        }
      }
    }
    setChatUserChange(false);
  }

  // User list search by name
  const handleSearch = (search_text) => {
    setSearchText(search_text.target.value);
  };

  // Check scroll to Top
  const handleScrollEvent = (e) => {
    if (e.target.scrollTop === 0) {
      if (currentPage < totalPage) {
        setCurrentPage(currentPage + 1);
        // chatMessageRefetch();
        setNewMessage(Math.floor(Math.random() * (1000 * 1 + 1) + 1));
      }
    } else {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight + 1;
      if (bottom) {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          setNewMessage(Math.floor(Math.random() * (1000 - 1 + 1) + 1));
        }
      }
    }
  };

  // Download Chat Media
  const downloadMedia = (url) => {
    const splitUrl = url.split('/');
    saveAs(url, splitUrl[splitUrl.length - 1]);
  };

  return (
    <>
      <Navbar className="sticky-top navbar-section">
        <Container fluid className="container-section">
          <Navbar.Brand className="dashboard-logo">
            <LogoUrlAdmin />
          </Navbar.Brand>
          <span className="back_to_dashboard p-2">
            <button
              className="table-primary-button p-2"
              type="button"
              onClick={() => {
                navigate('/dashboard');
              }}>
              {t('page.go_to_dashboard')}
            </button>
          </span>
          <span
            className="bars_icons p-3"
            onClick={() => {
              setActive(!isActive);
            }}>
            <FontAwesomeIcon icon={faBars} size="2x" />
          </span>
        </Container>
      </Navbar>
      <section id="" className="container-fluid chat-wrapeer">
        <div className="waste-main">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-section">
                <div className="body-section">
                  <div className="row">
                    <div
                      className={`col-lg-4 col-md-5 ${
                        isActive ? 'sidebar-collaped' : 'sidebar-none'
                      }`}>
                      <div className={`left-section desktop-section`}>
                        <div className="headLeft-sub">
                          <h4 className="text-start">Messages</h4>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text search_btn">
                                <FontAwesomeIcon icon={faSearch} />
                              </span>
                            </div>
                            <input
                              type="text"
                              placeholder="Search people or message"
                              name="search_user"
                              className="form-control search search_user"
                              id="search_user"
                              onKeyUp={handleSearch}
                              onChange={handleSearch}
                              value={searchText}
                            />
                          </div>
                        </div>
                        <div className="user_list" id="user_list">
                          <ul>
                            {userListData.length
                              ? userListData.map((userValue, index) => {
                                  return (
                                    <li key={index}>
                                      <div
                                        className="chatList active chat_user"
                                        onClick={() => {
                                          setActive(!isActive);
                                          handleSelectUser(userValue.chat_room_id, userValue.users);
                                        }}>
                                        <div className="img">
                                          <img
                                            alt=""
                                            src={
                                              userValue.users.user_image
                                                ? userValue.users.user_image_path
                                                : '../images/user.png'
                                            }
                                            className="rounded-circle"
                                          />
                                          <span
                                            className=""
                                            ref={(el) =>
                                              (userChatListIcon.current[userValue.users.id] = el)
                                            }></span>
                                        </div>
                                        <div className="desc">
                                          <span
                                            class={`chat_unread_count ${
                                              userValue.unread_count > 0 &&
                                              chat_room_id !== userValue.chat_room_id
                                                ? ''
                                                : 'd-none'
                                            }`}>
                                            {userValue.unread_count > 10
                                              ? '9+'
                                              : userValue.unread_count}
                                          </span>
                                          <small className="time">
                                            {userValue.latest_message_date
                                              ? formatAMPM(new Date(userValue.latest_message_date))
                                              : formatAMPM(new Date(userValue.created_at))}
                                          </small>
                                          <h5>{userValue.users.full_name}</h5>
                                          <small>
                                            {userValue.latest_message_type === 'TEXT'
                                              ? userValue.latest_message
                                                ? userValue.latest_message.length > 20
                                                  ? `${userValue.latest_message.substring(
                                                      0,
                                                      20
                                                    )}....`
                                                  : userValue.latest_message
                                                : ''
                                              : userValue.latest_message_type.toLowerCase() || ''}
                                          </small>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })
                              : null}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12">
                      <div className="right-section" id="chat_body">
                        <div className="headRight-sub">
                          <div className="d-flex bd-highlight">
                            <div className="img_cont">
                              <img alt="" src={chatUserImage} className="rounded-circle user_img" />
                              <span
                                className={
                                  chatUserRoomId
                                    ? usersData[chatUserRoomId]
                                      ? 'online-dot'
                                      : 'offline-dot'
                                    : 'offline-dot'
                                }
                                data-chat_id={chatUserRoomId}></span>
                            </div>
                            <div className="user_info">
                              <span>{chatUserName}</span>
                            </div>
                          </div>
                        </div>
                        <div className="message">
                          <div
                            className="sidebar-scroll"
                            id="sidebar-scroll"
                            onScroll={(e) => {
                              handleScrollEvent(e);
                            }}>
                            <ul className="msg_card_body">
                              {chatMessages
                                ? [...chatMessages.data.chatMessageList]
                                    .reverse()
                                    .map((value, index) => {
                                      return loggedUserData.user.id === value.from_id ? (
                                        <li className="msg-right" key={index}>
                                          <img
                                            alt=""
                                            src={loggedUserData.user.user_image_path}
                                            className="rounded-circle user_img"
                                          />
                                          <div className="msg-left-sub">
                                            <div className="msg-desc main_message">
                                              {value.message_type === 'TEXT' ? (
                                                value.body
                                              ) : (
                                                <>
                                                  <a
                                                    className="chat-link-href"
                                                    href={value.body}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    passHref={true}
                                                    style={{
                                                      color: '#ffffff !important',
                                                    }}>
                                                    {value.message_type === 'DOCUMENT'
                                                      ? 'Document Shared'
                                                      : null}
                                                    {value.message_type === 'IMAGE' ? (
                                                      <img
                                                        src={value.body}
                                                        alt=""
                                                        className="chat_image img-thumbnail"
                                                      />
                                                    ) : null}
                                                    {value.message_type === 'VIDEO' ? (
                                                      <video width="320" height="240" controls>
                                                        <source src={value.body} type="video/mp4" />
                                                        <source src={value.body} type="video/ogg" />
                                                        Your browser does not support the video tag.
                                                      </video>
                                                    ) : null}
                                                  </a>
                                                  <div className="download-icon">
                                                    <span
                                                      onClick={() => {
                                                        downloadMedia(value.body);
                                                      }}>
                                                      <FontAwesomeIcon icon={faDownload} />
                                                    </span>
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                            <div className="small">
                                              <small>
                                                {formatAMPM(new Date(value.created_at))}
                                              </small>
                                            </div>
                                          </div>
                                        </li>
                                      ) : (
                                        <li className="msg-left" key={index}>
                                          <div className="msg-left-sub">
                                            <img
                                              alt=""
                                              src={chatUserImage}
                                              className="rounded-circle user_img"
                                            />
                                            <div className="msg-desc">
                                              {value.message_type === 'TEXT' ? (
                                                value.body
                                              ) : (
                                                <>
                                                  <a
                                                    className="chat-link-href"
                                                    href={value.body}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    passHref={true}
                                                    style={{
                                                      color: '#ffffff !important',
                                                    }}>
                                                    {value.message_type === 'DOCUMENT'
                                                      ? 'Document Shared'
                                                      : null}
                                                    {value.message_type === 'IMAGE' ? (
                                                      <img
                                                        src={value.body}
                                                        alt=""
                                                        className="chat_image img-thumbnail"
                                                      />
                                                    ) : null}
                                                    {value.message_type === 'VIDEO' ? (
                                                      <video width="320" height="240" controls>
                                                        <source src={value.body} type="video/mp4" />
                                                        <source src={value.body} type="video/ogg" />
                                                        Your browser does not support the video tag.
                                                      </video>
                                                    ) : null}
                                                  </a>
                                                  <div className="download-icon">
                                                    <span
                                                      onClick={() => {
                                                        downloadMedia(value.body);
                                                      }}>
                                                      <FontAwesomeIcon icon={faDownload} />
                                                    </span>
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                            <div className="small">
                                              <small>
                                                {formatAMPM(new Date(value.created_at))}
                                              </small>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })
                                : ''}
                            </ul>
                            <div ref={divRef} />
                          </div>
                        </div>
                        <div className="maintext-area">
                          <textarea
                            name="message"
                            id="message"
                            className="form-control type_msg emojiable-question"
                            placeholder="Type a message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}></textarea>
                          <div
                            className="upload-img"
                            onClick={() => {
                              inputFile.current.click();
                            }}>
                            <FontAwesomeIcon icon={faImage} />
                          </div>

                          <div className="send-button">
                            <button
                              type="button"
                              className="rounded-pill btn-rounded"
                              onClick={() => {
                                handleSendTextMessage();
                              }}>
                              Send{' '}
                              <span>
                                <FontAwesomeIcon icon={faPaperPlane} />
                              </span>{' '}
                            </button>
                          </div>
                          <input
                            type="file"
                            className="d-none"
                            name="media"
                            ref={inputFile}
                            accept="image/*, video/*"
                            onChange={(e) => {
                              handleMediaUpload(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ChatNew;
