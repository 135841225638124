import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { settingData } from 'store/features/settingSlice';

const LogoUrlFront = () => {
  const getSettingData = useSelector(settingData);
  return (
    <img
      src={getSettingData.general_front_home_logo}
      alt="g2ct_front_logo"
      width={'100%'}
      className="nav-brand"
    />
  );
};
const PublicLayout = (props) => {
  return <div {...props}>{props.children}</div>;
};

PublicLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export { PublicLayout, LogoUrlFront };
