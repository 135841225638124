import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetGeneralSettingData } from 'hooks';
import { addSetting, settingData } from 'store/features/settingSlice';

import { ForgetPasswordPage, LoginPage, OtpVerificationPage, ResetPasswordPage } from 'pages/Auth';
import Dashboard from 'pages/Dashboard/Dashboard';
import Chat from 'pages/Chat/ChatPage';
import ChatNew from 'pages/Chat/ChatNewPage';
import Users from 'pages/Master/Users/UserRoutes';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import EditProfile from 'pages/EditProfilePage/EditProfilePage';
import ChangePassword from 'pages/ChangePasswordPage/ChangePasswordPage';

import GeneralSetting from 'pages/Setting/GeneralSetting/GeneralSettingPage';
import FaqSetting from 'pages/Setting/FaqSetting/FaqSettingPage';
import AboutSetting from 'pages/Setting/AboutSetting/AboutPageRoute';
import HomeSetting from 'pages/Setting/HomeSetting/HomePageRoute';
import ContactUsSetting from 'pages/Setting/ContactUsSetting/ContactUsSettingPage';

import BlogCategoryRoute from 'pages/Master/BlogCategory/BlogCategoryRoute';
import BlogPostRoute from 'pages/Master/BlogPost/BlogPostRoute';
import CmsRoute from 'pages/Master/Cms/CmsRoute';
import EmailTemplateRoute from 'pages/Master/EmailTemplate/EmailTemplateRoute';
import FaqRoute from 'pages/Master/Faq/FaQRoute';
import FaqCategoryRoute from 'pages/Master/FaqCategory/FaqCategoryRoute';
import TranslationRoute from 'pages/Master/Translation/TranslationRoute';
import QuestionaryRoute from 'pages/Master/Questionary/QuestionaryRoute';

import { PageNotFound } from 'common/components';
import ScheduleAppointments from 'pages/Master/BookAppointments/ScheduleAppointments';
import ValuationRoute from 'pages/Valuation/ValuationRoute';
import EsignatureRoute from 'pages/Master/Esignature/EsignatureRoute';
import AppointmentRoute from 'pages/Master/Appointment/AppointmentRoute';
import Notification from 'pages/Dashboard/Notification';
import ChatRoutes from 'pages/Chat/ChatRoutes';

const PagesRoutes = ({ t }) => {
  const dispatch = useDispatch();
  useGetGeneralSettingData([true], ({ data: general_data }) => {
    if (general_data) {
      const dataStore = {
        general_admin_home_logo: general_data.general_admin_home_logo,
        general_admin_favicon_logo: general_data.general_admin_favicon_logo,
        general_front_home_logo: general_data.general_front_home_logo,
        general_front_favicon_logo: general_data.general_front_favicon_logo,
        general_admin_sidebar_logo: general_data.general_admin_sidebar_logo,
        setting_get: true,
      };
      dispatch(addSetting(dataStore));
    }
  });
  const getSettingData = useSelector(settingData);
  return (
    <Router basename={'/'}>
      <Helmet>
        <title>Gable 2 Closing Table</title>
        <meta name="title" content="Gable 2 Closing Table" />
        <meta name="description" content="Gable 2 Closing Table" />
        <meta name="og:title" content="Gable 2 Closing Table" />
        <meta name="og:description" content="Gable 2 Closing Table" />
        <link
          rel="icon"
          type="image/x-icon"
          href={`${getSettingData.general_admin_favicon_logo}`}
        />
      </Helmet>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route exact path="/" element={<LoginPage t={t} />} />
          <Route exact path="/login" element={<LoginPage t={t} />} />
          <Route exact path="/forget-password" element={<ForgetPasswordPage t={t} />} />
          <Route exact path="/otp-verification" element={<OtpVerificationPage t={t} />} />
          <Route exact path="/reset-password" element={<ResetPasswordPage t={t} />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route exact path="/dashboard" element={<Dashboard t={t} />} />
          <Route exact path="/notification" element={<Notification t={t} />} />
          <Route exact path="/users/*" element={<Users t={t} />} />
          <Route exact path="/edit-profile" element={<EditProfile t={t} />} />
          <Route exact path="/change-password" element={<ChangePassword t={t} />} />

          {/* Blog Category Routes */}
          <Route exact path="/blog/blog-category/*" element={<BlogCategoryRoute t={t} />} />

          {/* Blog Post Routes */}
          <Route exact path="/blog/blog-post/*" element={<BlogPostRoute t={t} />} />

          {/* Blog Category Routes */}
          <Route exact path="/master/cms/*" element={<CmsRoute t={t} />} />

          {/* Blog Post Routes */}
          <Route exact path="/master/email-template/*" element={<EmailTemplateRoute t={t} />} />

          {/* Blog Category Routes */}
          <Route exact path="/master/faq/*" element={<FaqRoute t={t} />} />

          {/* Blog Post Routes */}
          <Route exact path="/master/questionary/*" element={<QuestionaryRoute t={t} />} />
          <Route
            exact
            path="/appointment/book-appointments"
            element={<ScheduleAppointments t={t} />}
          />
          <Route exact path="/master/faq-category/*" element={<FaqCategoryRoute t={t} />} />

          {/* Translation Routes */}
          <Route exact path="/master/translation/*" element={<TranslationRoute t={t} />} />
          <Route exact path="/master/esignature/*" element={<EsignatureRoute t={t} />} />
          <Route exact path="/appointment/*" element={<AppointmentRoute t={t} />} />

          <Route basename={'/setting/'}>
            <Route exact path="/setting/general" element={<GeneralSetting t={t} />} />
            <Route exact path="/setting/faq" element={<FaqSetting t={t} />} />
            <Route exact path="/setting/about/*" element={<AboutSetting t={t} />} />
            <Route exact path="/setting/home/*" element={<HomeSetting t={t} />} />
            <Route exact path="/setting/contact-us" element={<ContactUsSetting t={t} />} />
          </Route>
          <Route exact path="/valuation/*" element={<ValuationRoute t={t} />} />
        </Route>
        {/* <Route exact path="/chat" element={<Chat t={t} />} />
        <Route exact path="/chat/:chat_room_id" element={<ChatNew t={t} />} /> */}
        <Route exact path="/chat/*" element={<ChatRoutes t={t} />} />
        <Route>
          <Route path="*" element={<PageNotFound t={t} from="admin" />} />
        </Route>
      </Routes>
    </Router>
  );
};
PagesRoutes.propTypes = {
  t: PropTypes.any,
};
export default PagesRoutes;
