import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { NotificationService } from 'api/NotificationService';

const onDefaultError = (error) => {
  toast.error(error.message);
};
const useNotificationList = (onSuccess, onError = onDefaultError) => {
  return useQuery(['notification-service'], NotificationService.notificationList, {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useDeleteNotificationList = (onSuccess, onError = onDefaultError) => {
  return useMutation(NotificationService.deleteNotificationList, {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

export { useNotificationList, useDeleteNotificationList };
