import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PublicLayout } from 'common/layouts';
import { loader } from 'store/features/loaderSlice';
import { loggedUser } from 'store/features/userSlice';
import { Loader } from 'common/components';

const PublicRoute = () => {
  const checkLoading = useSelector(loader);
  const checkLoggenInUser = useSelector(loggedUser);

  const isLoggedIn = checkLoggenInUser.isLoggedIn;

  return !isLoggedIn ? (
    <PublicLayout>
      {checkLoading ? <Loader /> : ''}
      <Outlet />
    </PublicLayout>
  ) : (
    <Navigate
      to={{
        pathname: '/dashboard',
      }}
      replace
    />
  );
};

export default PublicRoute;
