/* Faq */
import client from 'libs/HttpClient';

class FaqService {
  static listFaq(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const category_id = request.queryKey[4] || '';
    return client.get(
      `/admin/faq/list?search=${searchText}&page=${page_no}&status=${status}&category_id=${category_id}`,
      request
    );
  }
  static addFaq(request) {
    return client.post(`/admin/faq/add`, request);
  }
  static viewFaq(params) {
    return client.get(`/admin/faq/edit/${params}`);
  }
  static updateFaq(request) {
    return client.post(`/admin/faq/update`, request);
  }
  static deleteFaq(request) {
    return client.get(`/admin/faq/delete/${request.id}`, request);
  }
  static getFaqCategory(request) {
    return client.get(`/admin/faq/faq-category-list`, request);
  }
  static getFaqCategoryList(request) {
    return client.get(`/admin/faq/faq-category-list-filter`, request);
  }
  static statusChangeFaq(request) {
    return client.get(`/admin/faq/update-status/${request.id}`, request);
  }
}
export { FaqService };
