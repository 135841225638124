import client from 'libs/HttpClient';

class ValuationService {
  static currentList(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const fromDate = request.queryKey[3] || '';
    const toDate = request.queryKey[4] || '';
    return client.get(
      `/admin/valuation/list?page=${page_no}&listType=${2}&search=${searchText}&from_date=${fromDate}&to_date=${toDate}`,
      request
    );
  }

  static PendingList(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const fromDate = request.queryKey[3] || '';
    const toDate = request.queryKey[4] || '';

    return client.get(
      `/admin/valuation/list?page=${page_no}&listType=${1}&search=${searchText}&from_date=${fromDate}&to_date=${toDate}`,
      request
    );
  }

  static RejectionList(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const fromDate = request.queryKey[3] || '';
    const toDate = request.queryKey[4] || '';

    return client.get(
      `/admin/valuation/list?page=${page_no}&listType=${3}&search=${searchText}&from_date=${fromDate}&to_date=${toDate}`,
      request
    );
  }

  static CompletedList(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const fromDate = request.queryKey[3] || '';
    const toDate = request.queryKey[4] || '';

    return client.get(
      `/admin/valuation/list?page=${page_no}&listType=${4}&search=${searchText}&from_date=${fromDate}&to_date=${toDate}`,
      request
    );
  }

  static OfferDetails(request) {
    const valuation_id = request.queryKey[1];
    return client.get(`/valuation/get-valuation-details/${valuation_id}?is_admin=true`);
  }

  static deleteReviseOffer(request) {
    return client.post(`/admin/valuation/accept-reject-offer`, request);
  }
  static viewUserQuestionary(request) {
    const valuation_id = request.queryKey[1];
    return client.get(`/admin/questionary/view-questionary/${valuation_id}?is_admin=true`);
  }

  static getAvaibilityofSlot(request) {
    return client.post(`/admin/valuation/get-booking-slots`, request);
  }
  static revisedOffer(request) {
    return client.post(`/admin/valuation/revise-offer`, request);
  }
  static cancelReviseOffer(request) {
    return client.post(`/admin/valuation/get-booking-slots`, request);
  }
  static deleteValuation(request) {
    return client.post(`/admin/valuation/delete-valuation`, request);
  }
  static addBookingSlot(request) {
    return client.post(`/admin/valuation/add-appointment-booking`, request);
  }
  static getValuationActivity(request) {
    return client.post('/valuation/get-valuation-activity', request);
  }
  static getAssignQuestionary(request) {
    return client.post('/admin/questionary/assign-questionary', request);
  }
  static uploadDocument(request) {
    return client.post('/admin/valuation/upload-doc', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static addAttorneyDate(request) {
    return client.post('/admin/valuation/attorney-date-book', request);
  }
  static cancleConfirmValuation(request) {
    return client.post('/admin/valuation/deal-cancel-complete', request);
  }
  static cancleAttorneyDate(request) {
    return client.post('/admin/valuation/attorney-date-book', request);
  }
}
export { ValuationService };
