import React, { useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import { PublicLayout, LogoUrlAdmin } from 'common/layouts';
import validationSchema from './FogetPasswordValidation';
import { useAdminForgotPassword } from 'hooks';
import { TNButton } from 'common/components';

const ForgetPasswordPage = ({ t }) => {
  const navigate = useNavigate();
  const { mutate: doAdminForgotPassword, isLoading } = useAdminForgotPassword((res) => {
    toast.success(res.message);
    localStorage.forgot_token = res.data.reset_token;
    setTimeout(function () {
      navigate('/otp-verification');
    }, 1500);
  });
  // Formik
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      doAdminForgotPassword(values);
    },
  });

  /**
   * This function is use for when click on cancel button
   */

  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate('/login');
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate('/login');
    }
  };

  // Add class in body
  useEffect(() => {
    document.body.classList.add('bg-box');
  }, []);
  return (
    <PublicLayout>
      <div className="auth-pages">
        <Container>
          <div className="background-box">
            <div>
              <div className="brand-logo">
                <LogoUrlAdmin />
              </div>
              <h1 className="page-heading-center">
                {t('page.forgot_password_heading_label_text')}
              </h1>
              <div className="page-sub-heading">
                <span>{t('page.forgot_password_sub_heading_label_text')}</span>
              </div>
              <div>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
                    <Form.Label className="field-label">{t('page.email_label_text')}</Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.email && formik.errors.email
                          ? 'form-field-error'
                          : formik.touched.email && !formik.errors.email
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="email"
                      placeholder={t('page.email_placeholder_text')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.email && formik.errors.email ? (
                        <div>{t(formik.errors.email)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <div className="primary-button">
                    <span className="link-center" onClick={handleCancel}>
                      {t('page.login_label_link_text')}
                    </span>
                    <TNButton
                      type="submit"
                      loading={isLoading}
                      isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                      {t('page.forgot_password_send_button_text')}
                    </TNButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </PublicLayout>
  );
};

export { ForgetPasswordPage };
