import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListAppointmentPage = React.lazy(() => import('./ListAppointmentPage'));
const AvailabilityPage = React.lazy(() => import('../BookAppointments/ScheduleAppointments'));

const AppointmentRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListAppointmentPage t={t} />} />
      <Route index exact path="/availability" element={<AvailabilityPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AppointmentRoute.propTypes = {
  t: PropTypes.func,
};
export default AppointmentRoute;
