import { React, useState } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { Nav, Row, Card, Col, InputGroup, Form, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { faClose } from '@fortawesome/free-solid-svg-icons';

import { TNButton } from 'common/components';
import validationSchema from './ChangePasswordValidation';
import { useChangePassword } from 'hooks';
const ChangePassword = ({ t }) => {
  const navigate = useNavigate();
  // Hide Show Password
  const [isRevealPwd, setIsRevealPwd] = useState();
  const [isNewRevealPwd, setNewIsRevealPwd] = useState();
  const [isCnfRevealPwd, setCnfIsRevealPwd] = useState();

  // This API will call when user clicks on Update Button
  const { mutate: doUserChangePassword, isLoading } = useChangePassword((response) => {
    toast.success(response.message);
    formik.resetForm();
  });

  // Formik
  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      doUserChangePassword(values);
    },
  });
  /**
   * This function is use for when click on cancel button
   */

  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate('/dashboard');
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate('/dashboard');
    }
  };
  return (
    <>
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to="/edit-profile">{t('page.edit_profile')}</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/change-password">{t('page.edit_change_password')}</NavLink>
        </Nav.Item>
      </Nav>
      <Card className="inner-box">
        <h1 className="page-heading-center">{t('page.change_password_header_text')}</h1>
        <Row>
          <Col lg={12}>
            <div className="normal-box">
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.change_password_old_password_label')}
                  </Form.Label>
                  <InputGroup className="form-group-field">
                    <FormControl
                      className={
                        '' +
                        (formik.touched.old_password && formik.errors.old_password
                          ? 'form-field-error'
                          : formik.touched.old_password && !formik.errors.old_password
                          ? 'form-field-success'
                          : '')
                      }
                      name="old_password"
                      placeholder={t('page.change_password_old_password_placeholder')}
                      autoComplete="off"
                      type={isRevealPwd ? 'text' : 'password'}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.old_password}
                    />
                    <InputGroup.Text
                      className={
                        formik.touched.old_password && formik.errors.old_password
                          ? 'form-field-error'
                          : formik.touched.old_password && !formik.errors.old_password
                          ? 'form-field-success'
                          : ''
                      }>
                      <FontAwesomeIcon
                        onClick={() => setIsRevealPwd((prevState) => !prevState)}
                        icon={isRevealPwd ? faEye : faEyeSlash}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="form-field-error-text">
                    {formik.touched.old_password && formik.errors.old_password ? (
                      <div>{t(formik.errors.old_password)}</div>
                    ) : null}
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.change_password_new_password_label')}
                  </Form.Label>
                  <InputGroup className="form-group-field">
                    <FormControl
                      className={
                        '' +
                        (formik.touched.new_password && formik.errors.new_password
                          ? 'form-field-error'
                          : formik.touched.new_password && !formik.errors.new_password
                          ? 'form-field-success'
                          : '')
                      }
                      name="new_password"
                      placeholder={t('page.reset_new_password_placeholder')}
                      autoComplete="off"
                      type={isNewRevealPwd ? 'text' : 'password'}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.new_password}
                    />
                    <InputGroup.Text
                      className={
                        '' +
                        (formik.touched.new_password && formik.errors.new_password
                          ? 'form-field-error'
                          : formik.touched.new_password && !formik.errors.new_password
                          ? 'form-field-success'
                          : '')
                      }>
                      <FontAwesomeIcon
                        onClick={() => setNewIsRevealPwd((prevState) => !prevState)}
                        icon={isNewRevealPwd ? faEye : faEyeSlash}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="form-field-error-text">
                    {formik.touched.new_password && formik.errors.new_password ? (
                      <div>{t(formik.errors.new_password)}</div>
                    ) : null}
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.change_password_cnf_password_label')}
                  </Form.Label>
                  <InputGroup className="form-group-field">
                    <FormControl
                      className={
                        '' +
                        (formik.touched.confirm_password && formik.errors.confirm_password
                          ? 'form-field-error'
                          : formik.touched.confirm_password && !formik.errors.confirm_password
                          ? 'form-field-success'
                          : '')
                      }
                      name="confirm_password"
                      placeholder={t('page.reset_cnfpassword_placeholder')}
                      autoComplete="off"
                      type={isCnfRevealPwd ? 'text' : 'password'}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirm_password}
                    />
                    <InputGroup.Text
                      className={
                        '' +
                        (formik.touched.confirm_password && formik.errors.confirm_password
                          ? 'form-field-error'
                          : formik.touched.confirm_password && !formik.errors.confirm_password
                          ? 'form-field-success'
                          : '')
                      }>
                      <FontAwesomeIcon
                        onClick={() => setCnfIsRevealPwd((prevState) => !prevState)}
                        icon={isCnfRevealPwd ? faEye : faEyeSlash}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="form-field-error-text">
                    {formik.touched.confirm_password && formik.errors.confirm_password ? (
                      <div>{t(formik.errors.confirm_password)}</div>
                    ) : null}
                  </div>
                </Form.Group>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.cancel_button_text')}
                  </span>
                  <TNButton
                    type="submit"
                    loading={isLoading}
                    isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                    {' '}
                    {t('page.reset_otp_verify_button_text')}
                  </TNButton>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
ChangePassword.propTypes = {
  t: PropTypes.func,
};
export default ChangePassword;
