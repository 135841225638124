import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TNBreadCurm } from 'common/components';
import { useDeleteNotificationList, useNotificationList } from 'hooks';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';

const Notification = ({ t }) => {
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState([]);
  const { mutate: dodeleteclearnotification } = useDeleteNotificationList((res) => {
    refetch();
  });

  const handleDeleteNotification = (notification_id) => {
    const data = {
      notification_id,
      is_clear: false,
    };
    if (!notification_id) {
      data.is_clear = true;
    }

    dodeleteclearnotification(data);
  };
  const { refetch, isLoading } = useNotificationList((res) => {
    setNotificationData(res.data.notificationData);
  });
  const breadcurmArray = [
    {
      label: t(`page.notification_list_label`),
      link: `/notification/list`,
      active: 'active',
    },
  ];
  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Button
              type="button"
              className="secondary-remove-button"
              onClick={() => handleDeleteNotification(0)}>
              {t('page.clear_notification_button')}
            </Button>
          </div>
        </Col>
      </Row>
      <h1>{t('page.notification_list_label')}</h1>
      <Row>
        <Col lg={12}>
          {' '}
          <div>
            {!isLoading && notificationData.length
              ? notificationData.map((list, index) => {
                  return (
                    <div key={index}>
                      <div className="add-proper-cls d-flex align-items-center mb-3 input-add-location notification-list p-2 border border-2 ps-3">
                        <p className="border border-0 bg-transparent valuation_list_class_input">
                          <h6 className="d-block" style={{ fontSize: '0.8rem' }}>
                            {list?.from_users?.full_name}({list?.from_users?.email})
                          </h6>
                          {list.title}
                        </p>
                        <div className="p-1 text-center">
                          <Button
                            type="button"
                            onClick={() => {
                              navigate(`/valuation/viewvaluationList/${list.redirection_id}`);
                            }}>
                            View
                          </Button>
                          <Button
                            className="btn btn-dark text-white fw-bold"
                            onClick={() => handleDeleteNotification(list.id)}>
                            <FontAwesomeIcon icon={faTrashCan} />
                          </Button>
                          <div className="p-2 sub-title-text">{list.created_at}</div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : 'No Notification Found'}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Notification;
