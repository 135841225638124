import React, { useEffect, useRef, useState } from 'react';
import { Nav, Form, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import { TNButton } from 'common/components';
import 'assets/scss/page/_editprofile.scss';
import {
  validationSchema,
  emailValidationSchema,
  validationSchemaVerfiy,
} from './EditProfileValidation';
import {
  useGetProfile,
  useUpdateProfile,
  useGetCountryCodeList,
  useUpdateEmailVerify,
  useUpdateEmail,
  useResendOtp,
} from 'hooks';
import { loggedUser, updateUserData } from 'store/features/userSlice';
import { showLoader, hideLoader } from 'store/features/loaderSlice';

const EditProfilePage = ({ t }) => {
  const profilePic = useRef();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState('');
  const checkLoggenInUser = useSelector(loggedUser);
  const dispatch = useDispatch();

  const [selectedCountry, setSelectedCountry] = useState('');
  const [show, setShow] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [verifyToken, setVerifyToken] = useState('');
  const handleClose = () => {
    setShow(false);
    formikEmail.resetForm();
  };
  // From this api we are getting user profile and setting as default from  here.
  const { refetch } = useGetProfile(({ data: profile }) => {
    if (profile) {
      formik.values.first_name = profile.first_name;
      formik.values.last_name = profile.last_name;
      formik.values.email = profile.email;
      formik.values.contact_no = profile.contact_no;
      formik.values.country_id = profile.country_id;
      setSelectedCountry(profile.country_id);
    }
  });

  // From this api we are getting country code list.
  const { isLoading: countryIsLoad, data: countryCodeList } = useGetCountryCodeList();

  const options = [];
  if (countryCodeList !== undefined) {
    countryCodeList.data.map((val) => {
      return options.push({
        value: val.id,
        label: val.phone_code + ' (' + val.name + ')',
      });
    });
  }

  // This API will call when user clicks on Update Button
  const { mutate: doUserUpdateProfile, isLoading: updateDataLoading } = useUpdateProfile(
    (response) => {
      toast.success(response.message);
      const dataStore = {
        userData: response.data,
      };
      dispatch(updateUserData(dataStore));
    }
  );

  /**
   * This function is use when click on cancel button
   */

  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/dashboard/`);
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate(`/dashboard/`);
    }
  };

  /**
   * This function is use when click on cancel button
   */

  const handleCancelEmailModal = () => {
    if (formikEmail.dirty && formikEmail.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    setShow(false);
                    formikEmail.resetForm();
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      formikEmail.resetForm();
      setShow(false);
    }
  };

  /**
   * This function is use when click on OTP cancel button
   */

  const handleCancelOTPModal = () => {
    if (formikEmailVerify.dirty && formikEmailVerify.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    setShowVerify(false);
                    setVerifyToken(null);
                    formikEmailVerify.resetForm();
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      setShowVerify(false);
      setVerifyToken(null);
      formikEmailVerify.resetForm();
    }
  };

  // This API will call when user clicks on Submit Button
  const { mutate: doUserUpdateEmail, isLoading: isLoadingEmail } = useUpdateEmail((response) => {
    toast.success(response.message);
    setShow(false);
    setShowVerify(true);
    setVerifyToken(response.data.reset_token);
  });

  // This API will call when user clicks on Submit Button
  const { mutate: doUserUpdateEmailVerify, isLoading: isLoadingEmailVerify } = useUpdateEmailVerify(
    (response) => {
      toast.success(response.message);
      setShow(false);
      setShowVerify(false);
      setVerifyToken(null);
      refetch();
    }
  );

  // This Block is execute on Form Submit for validation
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      contact_no: '',
      profile_image: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      values.profile_image = profilePic.current.files[0];
      values.country_id = selectedCountry;
      doUserUpdateProfile(values);
    },
  });

  // This Block is execute on when user clicks on Submit Button of edit email modal
  const formikEmail = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emailValidationSchema,
    onSubmit: async (values) => {
      doUserUpdateEmail(values);
      formikEmail.resetForm();
    },
  });

  /**
   * !This block will call when user click on Resend Otp
   */
  const handleResendOtp = () => {
    const values = {};
    values.encoded_token = verifyToken;
    dispatch(showLoader());
    doResendOtp(values);
  };
  //This API will call when user clicks on Resend Otp link of verify email modal
  const { mutate: doResendOtp } = useResendOtp(
    (res) => {
      dispatch(hideLoader());
      toast.success(res.message);
    },
    (err) => {
      dispatch(hideLoader());
    }
  );

  // This API will call when user clicks on submit button of verify otp modal
  const formikEmailVerify = useFormik({
    initialValues: {
      otp: '',
      encoded_token: verifyToken,
    },
    validationSchema: validationSchemaVerfiy,
    onSubmit: async (values) => {
      values.encoded_token = verifyToken;
      doUserUpdateEmailVerify(values);
      formikEmailVerify.resetForm();
    },
  });

  /**
   * !This block is used for Image privew
   */
  function imagePreview(e) {
    if (e.target.files && e.target.files.length > 0) {
      formik.setFieldValue('profile_image', e.target.files[0]);
      setUserProfile(URL.createObjectURL(e.target.files[0]));
    }
  }

  useEffect(() => {
    setUserProfile(checkLoggenInUser.user.user_image_path);
  }, [checkLoggenInUser.user.user_image_path]);

  return (
    <>
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to="/edit-profile">{t('page.edit_profile')}</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/change-password">{t('page.edit_change_password')}</NavLink>
        </Nav.Item>
      </Nav>
      <Card className="inner-box">
        <h1 className="page-heading-center"> {t('page.edit_header_text')}</h1>
        <div className="edit-profile-form">
          <Form onSubmit={formik.handleSubmit}>
            <div className="edit-profile-img ">
              <img src={userProfile} alt="profile_img" height="100%" />
            </div>
            <div className="upload-profile">
              <label htmlFor="profile_image">{t('page.edit_profile_upload_photo')}</label>
              <input
                name="profile_image"
                id="profile_image"
                onChange={imagePreview}
                ref={profilePic}
                accept=".png, .jpg, .jpeg"
                style={{ visibility: 'hidden', display: 'none' }}
                type={'file'}></input>
            </div>
            <Row>
              <Col lg={6} md={6} xs={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.edit_firstname_label')}
                  </Form.Label>
                  <Form.Control
                    className={
                      'form-field ' +
                      (formik.touched.first_name && formik.errors.first_name
                        ? 'form-field-error'
                        : formik.touched.first_name && !formik.errors.first_name
                        ? 'form-field-success'
                        : '')
                    }
                    type="text"
                    name="first_name"
                    placeholder={t('page.edit_profile_first_name_placeholder')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div>{t(formik.errors.first_name)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.edit_lastname_label')}
                  </Form.Label>
                  <Form.Control
                    className={
                      'form-field ' +
                      (formik.touched.last_name && formik.errors.last_name
                        ? 'form-field-error'
                        : formik.touched.last_name && !formik.errors.last_name
                        ? 'form-field-success'
                        : '')
                    }
                    type="text"
                    name="last_name"
                    placeholder={t('page.edit_profile_last_name_placeholder')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div>{t(formik.errors.last_name)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.edit_email_label')}
                  </Form.Label>
                  <Form.Label className="field-label field-label-top float-end me-4">
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => {
                        setShow(true);
                      }}
                    />
                  </Form.Label>
                  <Form.Control
                    className={
                      'form-field ' +
                      (formik.touched.email && formik.errors.email
                        ? 'form-field-error'
                        : formik.touched.email && !formik.errors.email
                        ? 'form-field-success'
                        : '')
                    }
                    type="text"
                    name="email"
                    readOnly={true}
                    placeholder={t('page.profile_email_placeholder')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.email && formik.errors.email ? (
                      <div>{t(formik.errors.email)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.edit_profile_phone')}
                  </Form.Label>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Select
                        name="country_id"
                        value={options.filter((option) => option.value === selectedCountry)}
                        onChange={(selectedOption) => {
                          setSelectedCountry(selectedOption.value);
                          formik.setFieldValue('country_id', selectedOption.value);
                        }}
                        className={
                          'contry-code ' +
                          (formik.touched.country_id && formik.errors.country_id
                            ? 'form-select-error'
                            : formik.touched.country_id && !formik.errors.country_id
                            ? 'form-select-success'
                            : '')
                        }
                        options={countryIsLoad ? options : options}
                      />
                      <div className="form-field-error-text">
                        {formik.touched.country_id && formik.errors.country_id ? (
                          <div>{t(formik.errors.country_id)}</div>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6} xs={6}>
                      <Form.Control
                        className={
                          'contant-number ' +
                          (formik.touched.contact_no && formik.errors.contact_no
                            ? 'form-field-error'
                            : formik.touched.contact_no && !formik.errors.contact_no
                            ? 'form-field-success'
                            : '')
                        }
                        type="text"
                        name="contact_no"
                        placeholder={t('page.profile_phone_placeholder')}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        minLength="10"
                        maxLength="13"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={formik.values.contact_no}
                      />
                      <div className="form-field-error-text">
                        {formik.touched.contact_no && formik.errors.contact_no ? (
                          <div>{t(formik.errors.contact_no)}</div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <div className="primary-button">
              <span className="link-center" onClick={handleCancel}>
                {t('page.cancel_button_text')}
              </span>
              <TNButton
                loading={updateDataLoading}
                type="submit"
                isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                {t('page.save_button_text')}
              </TNButton>
            </div>
          </Form>
        </div>
      </Card>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('page.profile_email_edit_label')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto">
          <Form onSubmit={formikEmail.handleSubmit}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.profile_email')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formikEmail.touched.email && formikEmail.errors.email
                    ? 'form-field-error'
                    : formikEmail.touched.email && !formikEmail.errors.email
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="email"
                placeholder={t('page.profile_email_placeholder')}
                onChange={formikEmail.handleChange}
                onBlur={formikEmail.handleBlur}
              />
              <div className="form-field-error-text">
                {formikEmail.touched.email && formikEmail.errors.email ? (
                  <div>{t(formikEmail.errors.email)}</div>
                ) : null}
              </div>
            </Form.Group>
            <div className="primary-button">
              <span className="link-center" onClick={handleCancelEmailModal}>
                {t('page.cancel_button_text')}
              </span>
              <TNButton
                type="submit"
                loading={isLoadingEmail}
                isdirtyform={formikEmail.dirty && formikEmail.dirty !== undefined ? 1 : 0}>
                {t('page.save_button_text')}
              </TNButton>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={showVerify}
        onHide={() => {
          setShowVerify(false);
          setVerifyToken(null);
          formikEmailVerify.resetForm();
        }}>
        <Modal.Header closeButton>
          <Modal.Title>{t('page.profile_edit_email_verify_label')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto">
          <Form onSubmit={formikEmailVerify.handleSubmit}>
            <Form.Group>
              <Form.Label className="field-label">{t('page.profile_email_otp_label')}</Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formikEmailVerify.touched.otp && formikEmailVerify.errors.otp
                    ? 'form-field-error'
                    : formikEmailVerify.touched.otp && !formikEmailVerify.errors.otp
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="otp"
                maxLength="6"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder={t('page.profile_email_otp_placeholder')}
                onChange={formikEmailVerify.handleChange}
                onBlur={formikEmailVerify.handleBlur}
                value={formikEmailVerify.values.otp}
              />
              {formikEmailVerify.touched.otp && formikEmailVerify.errors.otp ? (
                <div className="form-field-error-text">{t(formikEmailVerify.errors.otp)}</div>
              ) : null}
            </Form.Group>
            <div className="link-right">
              <span className="resend-otp" onClick={handleResendOtp}>
                {t('page.resend_otp_button_button_text')}
              </span>
            </div>
            <div className="primary-button">
              <span className="link-center" onClick={handleCancelOTPModal}>
                {t('page.cancel_button_text')}
              </span>
              <TNButton
                type="submit"
                loading={isLoadingEmailVerify}
                isdirtyform={
                  formikEmailVerify.dirty && formikEmailVerify.dirty !== undefined ? 1 : 0
                }>
                {t('page.save_button_text')}
              </TNButton>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditProfilePage;
