import * as Yup from 'yup';

let validationSchema = Yup.object({
  old_password: Yup.string(('page.reset_old_password_validation_required')).required(
    ('page.reset_old_password_validation_required')
  ),
  new_password: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*)[A-Za-z\d@$!%*#?&]{6,}$/,
      'page.reset_strong_password_validation_required'
    ).required('page.reset_new_password_validation_required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'page.reset_match_password_validation_required'
    ).required(('page.reset_confirm_password_validation_required'))
})

export default validationSchema;
