import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { FaqService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useListFaq = (
  [page_no, searchText, status, category_id],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(['faq-list', page_no, searchText, status, category_id], FaqService.listFaq, {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useAddFaq = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqService.addFaq, {
    onSuccess,
    onError,
  });
};

const useViewFaq = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('faq-view', () => FaqService.viewFaq(id), {
    onSuccess,
    onError,
  });
};
const useUpdateFaq = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqService.updateFaq, {
    onSuccess,
    onError,
  });
};

const useGetCategoryList = (onSuccess, onError = onDefaultError) => {
  return useQuery('faq-get-category', FaqService.getFaqCategory, {
    onSuccess,
    onError,
  });
};

const useGetCategoryListFilter = (onSuccess, onError = onDefaultError) => {
  return useQuery('faq-get-category-list', FaqService.getFaqCategoryList, {
    onSuccess,
    onError,
  });
};

const useStatusChangeFaq = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqService.statusChangeFaq, {
    onSuccess,
    onError,
  });
};

const useDeleteFaq = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqService.deleteFaq, {
    onSuccess,
    onError,
  });
};

export {
  useListFaq,
  useAddFaq,
  useViewFaq,
  useUpdateFaq,
  useDeleteFaq,
  useGetCategoryListFilter,
  useGetCategoryList,
  useStatusChangeFaq,
};
