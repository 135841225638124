import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';

import PagesRoutes from 'routes';
import { Loader, NoInternet } from 'common/components';
import { persistor, store } from 'store';
import '../assets/scss/style.scss';
import { setAuthToken } from 'libs/HttpClient';
import Notification from 'firebaseNotifications/Notification';

const handleOnBeforeLift = () => {
  if (
    store.getState().user.accessToken !== undefined &&
    store.getState().user.accessToken !== null
  ) {
    setAuthToken(store.getState().user.accessToken);
  }
};
//Configure Query Client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //retryOnMount:
      refetchOnWindowFocus: false,
    },
  },
});
function App() {
  const { t } = useTranslation();

  if (!navigator.onLine) {
    return <NoInternet />;
  }

  window.onoffline = (event) => {
    return <NoInternet />;
  };

  window.ononline = (event) => {
    window.location.reload(true);
  };
  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} onBeforeLift={handleOnBeforeLift}>
          <QueryClientProvider client={queryClient}>
            <PagesRoutes t={t} />
            <ToastContainer />
            <Notification />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}
export default App;
