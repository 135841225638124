/* Blog Category */
import client from 'libs/HttpClient';

class BlogCategoryService {
  static listBlogCategory(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    return client.get(
      `/admin/blog-category/list?search=${searchText}&page=${page_no}&status=${status}`,
      request
    );
  }
  static addBlogCategory(request) {
    return client.post(`/admin/blog-category/add`, request);
  }
  static viewBlogCategory(params) {
    return client.get(`/admin/blog-category/edit/${params}`);
  }
  static updateBlogCategory(request) {
    return client.post(`/admin/blog-category/update`, request);
  }
  static deleteBlogCategory(request) {
    return client.get(`/admin/blog-category/delete/${request.id}`, request);
  }
  static statusChangeBlogCategory(request) {
    return client.get(`/admin/blog-category/update-status/${request.id}`, request);
  }
}
export { BlogCategoryService };
