import { TokenServices } from 'api/TokenService';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

/**
 * Hook for user Tokem
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useTokenSave = (onSuccess, onError = onDefaultError) => {
  return useMutation(TokenServices.storeToken, {
    onSuccess,
    onError,
  });
};

export { useTokenSave };
