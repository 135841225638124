import { React, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Container, NavDropdown, Navbar, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { io } from 'socket.io-client';

import { SideBarUrlAdmin } from 'common/layouts';
import { loggedUser, logoutSuccess } from 'store/features/userSlice';
import { CHAT_URL } from 'config';
import { useDeleteNotificationList, useNotificationList } from 'hooks/useRQNotificationService';

function useOutsideAlerter(ref, setShow) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setShow]);
}

const Header = (props) => {
  const socket = io(CHAT_URL, {
    transports: ['websocket'],
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [isLoggedIn, setIsLoggedIn] = useState(false);
  let [isOffline, setIsOffline] = useState(false);
  let [notificationCount, setNotificationCount] = useState(0);
  const [notificationBlock, setNotificationBlock] = useState(false);

  const [notificationData, setNotificationData] = useState([]);

  const handleEditProfile = () => {
    navigate('/edit-profile');
  };
  const handleLogout = () => {
    dispatch(logoutSuccess());
    setTimeout(function () {
      navigate('/login');
    }, 1500);
  };
  const checkLoggenInUser = useSelector(loggedUser);
  if (checkLoggenInUser.isLoggedIn && !isLoggedIn) {
    setIsLoggedIn(true);
    if (!isOffline) {
      socket.emit('admin_disconnected', {});
      setIsOffline(true);
    }
  }
  const { mutate: dodeleteclearnotification } = useDeleteNotificationList((res) => {
    refetch();
  });

  const [show, setShow] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setShow);

  const handleDeleteNotification = (notification_id) => {
    const data = {
      notification_id,
      is_clear: false,
    };
    if (!notification_id) {
      data.is_clear = true;
    }

    dodeleteclearnotification(data);
  };
  const { refetch, isLoading } = useNotificationList((res) => {
    setNotificationData(res.data.notificationData);
    setNotificationCount(res.data.notificationCount);
  });

  const { t, toggleClass } = props;

  const UserMenu = (
    <svg width="9" height="21" viewBox="0 0 9 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="4.44691" cy="4.49619" rx="1.44691" ry="1.49619" fill="#9592A6" />
      <ellipse cx="4.44691" cy="10.4886" rx="1.44691" ry="1.49619" fill="#9592A6" />
      <ellipse cx="4.44691" cy="16.4809" rx="1.44691" ry="1.49619" fill="#9592A6" />
    </svg>
  );

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setNotificationBlock(false);
    }
  };

  return (
    <>
      <Navbar className="sticky-top navbar-section">
        <Container fluid className="container-section">
          <Navbar.Brand className="dashboard-logo">
            <SideBarUrlAdmin />
          </Navbar.Brand>
          <div className="header-setting-menu">
            <div className="dashboard-toggle-btn">
              <FontAwesomeIcon icon={faBars} onClick={toggleClass} />
            </div>
            <div ref={wrapperRef}>
              <div className="mt-1 me-4 ">
                <span className={`notification-counter ${!notificationCount ? 'd-none' : ''}`}>
                  {notificationCount ? (notificationCount > 10 ? '+9' : notificationCount) : null}
                </span>
                <svg
                  onClick={() => {
                    setNotificationBlock(!notificationBlock);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  className="bi bi-bell"
                  viewBox="0 0 16 16">
                  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                </svg>
              </div>

              <div className={`notification data ${notificationBlock ? '' : 'd-none'} `}>
                <h4 className="description-heading paragraph-heading fw-bold m-3 fs-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-bell me-3"
                    viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                  </svg>
                  {t('page.notification_page_label')}
                </h4>
                <hr />

                {!isLoading && notificationData.length
                  ? notificationData.map((list, index) => {
                      return (
                        <div key={index}>
                          <div className="location-border">
                            <div className="input-group input-add-location notification-list p-2 d-flex align-items-center">
                              <div
                                onClick={() => {
                                  navigate(`/valuation/viewvaluationList/${list.redirection_id}`);
                                  setNotificationBlock(!notificationBlock);
                                }}>
                                <h6 className="d-block" style={{ fontSize: '0.8rem' }}>
                                  {list?.from_users?.full_name}({list?.from_users?.email})
                                </h6>
                                <p className="bg-transparent border border-0 valuation_list_class_input">
                                  {list.title}
                                </p>
                              </div>

                              <div className="p-1 text-center">
                                <Button
                                  className="btn btn-dark text-white fw-bold"
                                  onClick={() => handleDeleteNotification(list.id)}>
                                  <FontAwesomeIcon icon={faTrashCan} />
                                </Button>
                                <div className="p-2 sub-title-text">{list.created_at}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
                <div className={`${notificationData.length ? '' : 'd-flex align-items-center'}`}>
                  {notificationData.length === 0 ? (
                    <p className="no-notification">{t('page.no_notification_data')}</p>
                  ) : (
                    <div className="text-center mt-2 mb-2">
                      <button
                        className="table-secondary-button btn btn-primary"
                        onClick={() => {
                          navigate('/notification');
                          setNotificationBlock(!notificationBlock);
                        }}>
                        {t('page.view_notification_button')}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="desktop-login-name">
              <span>{`${checkLoggenInUser.user.first_name} ${checkLoggenInUser.user.last_name}`}</span>
            </div>
            <div className="profile-img">
              <img
                src={checkLoggenInUser.user.user_image_path}
                width="40px"
                height="40px"
                alt="profile_img"
              />
            </div>
            <NavDropdown title={UserMenu} className="add-proper-cls">
              <div className="mobile-login-name">
                <span>{'Admin'}</span>
                <NavDropdown.Divider className="separator" />
              </div>
              <NavDropdown.Item className="setting-items" onClick={handleEditProfile}>
                <Link to="/edit-profile">{t('page.header_editprofile')}</Link>
              </NavDropdown.Item>
              <NavDropdown.Item className="setting-items" onClick={handleLogout}>
                {t('page.header_logout')}
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
Header.propTypes = {
  t: PropTypes.func,
  toggleClass: PropTypes.any,
};
export { Header };
