import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Chat Module process routes, and pages
 */

const ChatMainPage = React.lazy(() => import('./ChatMainPage/ChatMainPage'));
const ChatUser = React.lazy(() => import('./ChatUser'));

const ChatRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/" element={<ChatUser t={t} />} />
      <Route exact path="/:chat_room_id" element={<ChatMainPage t={t} />} />
      <Route exact path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ChatRoutes.propTypes = {
  t: PropTypes.func,
};
export default ChatRoutes;
