import * as Yup from 'yup';
Yup.addMethod(Yup.mixed, 'icon_file', function (errorMessage) {
  return this.test(`test-icon_file`, errorMessage, function (value) {
    if (typeof value === 'string') {
      return true;
    }

    const isValid = ['image/svg+xml', 'image/vnd.microsoft.icon', 'image/x-icon'];
    if (value !== undefined) {
      return value && isValid.includes(value.type);
    }
  });
});
Yup.addMethod(Yup.mixed, 'file_type', function (errorMessage) {
  return this.test(`test-file_type`, errorMessage, function (value) {
    if (typeof value === 'string') {
      return true;
    }
    const isValid = ['image/jpg', 'image/jpeg', 'image/png'];
    if (value !== undefined) {
      return value && isValid.includes(value.type);
    }
  });
});
let validationSchema = Yup.object({
  general_front_home_logo: Yup.mixed('page.general_setting_front_home_logo_required')
    .required('page.general_setting_front_home_logo_required')
    .file_type('page.general_setting_front_home_logo_type_required'),
  general_front_favicon_logo: Yup.mixed('page.general_setting_front_favicon_logo_required')
    .required('page.general_setting_front_favicon_logo_required')
    .icon_file('page.general_setting_front_favicon_logo_type_required'),
  general_admin_home_logo: Yup.mixed('page.general_setting_admin_home_logo_required')
    .required('page.general_setting_admin_home_logo_required')
    .file_type('page.general_setting_admin_home_logo_type_required'),
  general_admin_favicon_logo: Yup.mixed('page.general_setting_admin_favicon_logo_required')
    .required('page.general_setting_admin_favicon_logo_required')
    .icon_file('page.general_setting_admin_favicon_logo_type_required'),
  general_facebook_link: Yup.string('page.general_setting_facebook_link_required').matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'page.general_setting_facebook_link_format_incorrect'
  ),
  general_twitter_link: Yup.string('page.general_setting_twitter_link_required').matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'page.general_setting_twitter_link_format_incorrect'
  ),
  general_linkedin_link: Yup.string('page.general_setting_linkedin_link_required').matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'page.general_setting_linkedin_link_format_incorrect'
  ),
  general_youtube_link: Yup.string('page.general_setting_youtube_link_required').matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'page.general_setting_youtube_link_format_incorrect'
  ),
  general_instagram_link: Yup.string('page.general_setting_instagram_link_required').matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'page.general_setting_facebook_instagram_format_incorrect'
  ),
  general_admin_sidebar_logo: Yup.mixed('page.general_setting_admin_sidebar_logo_required')
    .required('page.general_setting_admin_sidebar_logo_required')
    .file_type('page.general_setting_admin_sidebar_logo_type_required'),
  general_service_charge: Yup.number()
    .required('page.general_setting_service_charge_required')
    .moreThan(-1, 'page.service_charge_more_than_zero')
    .lessThan(101, 'page.service_charge_not_be_more_than_2_digit'),
  general_preliminary_offer: Yup.number()
    .required('page.general_preliminary_offer_charge_required')
    .moreThan(-1, 'page.general_preliminary_offer_more_than_zero')
    .lessThan(101, 'page.general_preliminary_offer_not_be_more_than_2_digit'),
  general_contract_closing_days:  Yup.number()
  .required('page.general_contract_closing_days_required')
  .moreThan(-1, 'page.general_contract_closing_days_more_than_zero'),
});

export default validationSchema;
