import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

const HeroSection = React.lazy(() => import('./HeroSection/HeroSectionPage'));
const OurStorySection = React.lazy(() => import('./OurStorySection/OurStoryPage'));
const OurVisionSection = React.lazy(() => import('./OurVisionSection/OurVisionPage'));
const OurMissionSection = React.lazy(() => import('./OurMissionSection/OurMissionPage'));
const LeaderShipSection = React.lazy(() => import('./LeadershipTeamSection/LeadershipTeamPage'));
const SeoSection = React.lazy(() => import('./SeoSection/SeoPage'));

const AboutPageRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/hero-section" element={<HeroSection t={t} />} />
      <Route exact path="/our-story" element={<OurStorySection t={t} />} />
      <Route exact path="/our-vision" element={<OurVisionSection t={t} />} />
      <Route exact path="/our-mission" element={<OurMissionSection t={t} />} />
      <Route exact path="/leadership" element={<LeaderShipSection t={t} />} />
      <Route exact path="/seo" element={<SeoSection t={t} />} />
    </Routes>
  );
};
AboutPageRoute.propTypes = {
  t: PropTypes.func
};
export default AboutPageRoute;
