import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListBlogCategoryPage = React.lazy(() => import('./ListBlogCategoryPage'));
const AddBlogCategoryPage = React.lazy(() => import('./AddBlogCategoryPage'));
const EditBlogCategoryPage = React.lazy(() => import('./EditBlogCategoryPage'));

const BlogCategoryRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListBlogCategoryPage t={t} />} />
      <Route exact path="/add" element={<AddBlogCategoryPage t={t} />} />
      <Route exact path="/edit/:id" element={<EditBlogCategoryPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />

    </Routes>
  );
};
BlogCategoryRoute.propTypes = {
  t: PropTypes.func
};
export default BlogCategoryRoute;
