/* Faq Category */
import client from 'libs/HttpClient';

class FaqCategoryService {
  static listFaqCategory(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    return client.get(
      `/admin/faq-category/list?search=${searchText}&page=${page_no}&status=${status}`,
      request
    );
  }
  static addFaqCategory(request) {
    return client.post(`/admin/faq-category/add`, request);
  }
  static viewFaqCategory(params) {
    return client.get(`/admin/faq-category/edit/${params}`);
  }
  static updateFaqCategory(request) {
    return client.post(`/admin/faq-category/update`, request);
  }
  static deleteFaqCategory(request) {
    return client.get(`/admin/faq-category/delete/${request.id}`, request);
  }
  static statusChangeFaqCategory(request) {
    return client.get(`/admin/faq-category/update-status/${request.id}`, request);
  }
}
export { FaqCategoryService };
