import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

const HeroSection = React.lazy(() => import('./HeroSection/HeroSectionPage'));
const HowThePlatformWorksSection = React.lazy(() => import('./HowThePlatformWorksSection/HowThePlatformWorksPage'));
const HowWeCanHelpYouSection = React.lazy(() => import('./HowWeCanHelpYouSection/HowWeCanHelpYouPage'));
const RecentBlogsSection = React.lazy(() => import('./RecentBlogsSection/RecentBlogsPage'));
const SeoSection = React.lazy(() => import('./SeoSection/SeoPage'));

const AboutPageRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/hero-section" element={<HeroSection t={t} />} />
      <Route exact path="/how-to-platform-works" element={<HowThePlatformWorksSection t={t} />} />
      <Route exact path="/how-we-can-help-you" element={<HowWeCanHelpYouSection t={t} />} />
      <Route exact path="/recent-blogs" element={<RecentBlogsSection t={t} />} />
      <Route exact path="/seo" element={<SeoSection t={t} />} />
    </Routes>
  );
};
AboutPageRoute.propTypes = {
  t: PropTypes.func
};
export default AboutPageRoute;
