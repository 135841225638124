import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { CmsService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useListCms = ([page_no, searchText, status], onSuccess, onError = onDefaultError) => {
  return useQuery(['cms-list', page_no, searchText, status], CmsService.listCms, {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useAddCms = (onSuccess, onError = onDefaultError) => {
  return useMutation(CmsService.addCms, {
    onSuccess,
    onError,
  });
};

const useCmsStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(CmsService.updateStatusCms, {
    onSuccess,
    onError,
  });
};

const useUpdateCms = (onSuccess, onError = onDefaultError) => {
  return useMutation(CmsService.updateCms, {
    onSuccess,
    onError,
  });
};

const useViewCms = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('cms-view', () => CmsService.viewCms(id), {
    onSuccess,
    onError,
  });
};

export { useListCms, useAddCms, useCmsStatusChange, useUpdateCms, useViewCms };
