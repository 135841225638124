import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PrivateLayout } from 'common/layouts';
import { loader } from 'store/features/loaderSlice';
import { loggedUser } from 'store/features/userSlice';
import { Loader } from 'common/components';

const PrivateRoute = () => {
  const checkLoading = useSelector(loader);
  const checkLoggenInUser = useSelector(loggedUser);

  const location = useLocation();
  const isLoggedIn = checkLoggenInUser.isLoggedIn;

  return isLoggedIn ? (
    <PrivateLayout>
      {checkLoading ? <Loader /> : ''}
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
