import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListQuestionaryPage = React.lazy(() => import('./ListQuestionaryPage'));
const AddQuestionaryPage = React.lazy(() => import('./AddQuestionaryPage'));
const EditQuestionaryPage = React.lazy(() => import('./EditQuestionaryPage'));

const QuestionaryRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListQuestionaryPage t={t} />} />
      <Route exact path="/add" element={<AddQuestionaryPage t={t} />} />
      <Route exact path="/edit/:id" element={<EditQuestionaryPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
QuestionaryRoute.propTypes = {
  t: PropTypes.func,
};
export default QuestionaryRoute;
