/* Settings */
import client from "libs/HttpClient";
class SettingService {
    //General Setting
    static getGeneralSettingData() {
        return client.get("/admin/setting/general");
    }
    //Faq Setting
    static getFaqSettingData() {
        return client.get("/admin/setting/faq");
    }
    //Contact Us Setting
    static getContactUsSettingData() {
        return client.get("/admin/setting/contact_us");
    }
    //About Setting
    static getAboutSettingData() {
        return client.get("/admin/setting/about");
    }
    //Home Setting
    static getHomeSettingData() {
        return client.get("/admin/setting/home");
    }
    //Save Setting Data
    static storeSettingData(request) {
        return client.post("/admin/setting/update/general", request, {
            headers: {
                "Content-type": "multipart/form-data",
            },
        });
    }
}
export { SettingService };
