/* CMS */
import client from 'libs/HttpClient';

class CmsService {
    static listCms(request) {
        const page_no = request.queryKey[1];
        const searchText = request.queryKey[2];
        const status = request.queryKey[3];
        return client.get(
            `/admin/cms/list?search=${searchText}&page=${page_no}&status=${status}`,
            request
        );
    }
    static addCms(request) {
        return client.post('/admin/cms/add', request);
    }
    static updateStatusCms(request) {
        return client.get(`/admin/cms/update-status/${request.id}`, request);
    }
    static updateCms(request) {
        return client.post(`/admin/cms/update`, request);
    }
    static viewCms(params) {
        return client.get(`/admin/cms/edit/${params}`);
    }
}
export { CmsService };
