import React, { useState } from 'react';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { Field, FieldArray, Formik } from 'formik';
import { validationSchema } from './AppointmentValidation';
import { toast } from 'react-toastify';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import 'assets/scss/page/_scheduleappointment.scss';
import { TNButton } from 'common/components';
import { useBookAppointment, useGetAvailibilitySlot } from 'hooks/useRQBookAppointment';

const ScheduleAppointments = ({ t }) => {
  const [isDateRange, setIsDateRange] = useState(false);
  const [dateRange, setDateRange] = useState();

  const initialValues = {
    is_date_range: isDateRange,
    start_date: new Date(),
    end_date: new Date(),

    days_slot_1: [
      {
        dayscheckbox: false,
        days_start: moment().format('HH:mm'),
        days_end: moment().add(1, 'hours').format('HH:mm'),
      },
    ],
    days_slot_2: [
      {
        dayscheckbox: false,
        days_start: moment().format('HH:mm'),
        days_end: moment().add(1, 'hours').format('HH:mm'),
      },
    ],
    days_slot_3: [
      {
        dayscheckbox: false,
        days_start: moment().format('HH:mm'),
        days_end: moment().add(1, 'hours').format('HH:mm'),
      },
    ],
    days_slot_4: [
      {
        dayscheckbox: false,
        days_start: moment().format('HH:mm'),
        days_end: moment().add(1, 'hours').format('HH:mm'),
      },
    ],
    days_slot_5: [
      {
        dayscheckbox: false,
        days_start: moment().format('HH:mm'),
        days_end: moment().add(1, 'hours').format('HH:mm'),
      },
    ],
    days_slot_6: [
      {
        dayscheckbox: false,
        days_start: moment().format('HH:mm'),
        days_end: moment().add(1, 'hours').format('HH:mm'),
      },
    ],
    days_slot_7: [
      {
        dayscheckbox: false,
        days_start: moment().format('HH:mm'),
        days_end: moment().add(1, 'hours').format('HH:mm'),
      },
    ],
  };
  const {
    refetch,
    isLoading: getDataLoading,
    data: slotdata,
  } = useGetAvailibilitySlot((res) => {
    setIsDateRange(res.data.availabilityData.is_date_range);
    setDateRange(res.data.availabilityData);
    if (res.data.availabilityData.is_date_range) {
      // setShow(true);
      setDateRange({
        startDate: res.data.availabilityData.start_date,
        endDate: res.data.availabilityData.end_date,
      });
    }
    initialValues.days_slot_1 = res.data.availability_slots_data
      .filter((o) => {
        return o.day === 6;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    initialValues.days_slot_2 = res.data.availability_slots_data
      .filter((o) => {
        return o.day === 0;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    initialValues.days_slot_3 = res.data.availability_slots_data
      .filter((o) => {
        return o.day === 1;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    initialValues.days_slot_4 = res.data.availability_slots_data
      .filter((o) => {
        return o.day === 2;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    initialValues.days_slot_5 = res.data.availability_slots_data
      .filter((o) => {
        return o.day === 3;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    initialValues.days_slot_6 = res.data.availability_slots_data
      .filter((o) => {
        return o.day === 4;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    initialValues.days_slot_7 = res.data.availability_slots_data
      .filter((o) => {
        return o.day === 5;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    // res.data.availability_slots_data.map((el, i) => {
    //   // console.log(el.day);
    //   if (el.day === true || el.day === 1) {
    //     tueDay[0].dayscheckbox = true;

    //     tueDay.push({
    //       dayscheckbox: true,
    //       days_start: el.start_time,
    //       days_end: el.end_time,
    //     });
    //     days[1] = true;

    //     console.log('cwejbfuifweufwe');
    //     // tueDay[0].dayscheckbox = true;
    //     // tueDay.push({
    //     //   dayscheckbox: true,
    //     //   days_start: el.start_time,
    //     //   days_end: el.end_time,
    //     // });
    //     // days[2] = true;
    //     // if (tueDay.length === 1) {
    //     //   tueDay[0].dayscheckbox = true;
    //     //   tueDay[0].days_start = el.start_time;
    //     //   tueDay[0].days_end = el.end_time;
    //     // } else {
    //     //   tueDay.push({
    //     //     dayscheckbox: true,
    //     //     days_start: el.start_time,
    //     //     days_end: el.end_time,
    //     //   });
    //     // }
    //     // days[1] = true;
    //   } else if (el.day === false || el.day === 0) {
    //     // monDay[0].dayscheckbox = true;
    //     // monDay.push({
    //     //   dayscheckbox: true,
    //     //   days_start: el.start_time,
    //     //   days_end: el.end_time,
    //     // });
    //     // monDay[0].dayscheckbox = true;
    //     if (monDay.length === 1) {
    //       monDay[0].dayscheckbox = true;
    //       monDay[0].days_start = el.start_time;
    //       monDay[0].days_end = el.end_time;
    //     } else {
    //       monDay.push({
    //         dayscheckbox: true,
    //         days_start: el.start_time,
    //         days_end: el.end_time,
    //       });
    //     }
    //     monDay[0].dayscheckbox = true;
    //   } else if (el.day === 1) {
    //     tueDay[0].dayscheckbox = true;

    //     tueDay.push({
    //       dayscheckbox: true,
    //       days_start: el.start_time,
    //       days_end: el.end_time,
    //     });
    //     days[1] = true;
    //   } else if (el.day === 2) {
    //     wedDay[0].dayscheckbox = true;

    //     wedDay.push({
    //       dayscheckbox: true,
    //       days_start: el.start_time,
    //       days_end: el.end_time,
    //     });
    //     days[2] = true;
    //   } else if (el.day === 3) {
    //     thuDay[0].dayscheckbox = true;

    //     thuDay.push({
    //       dayscheckbox: true,
    //       days_start: el.start_time,
    //       days_end: el.end_time,
    //     });
    //     days[3] = true;
    //   } else if (el.day === 4) {
    //     friDay[0].dayscheckbox = true;

    //     friDay.push({
    //       dayscheckbox: true,
    //       days_start: el.start_time,
    //       days_end: el.end_time,
    //     });
    //     days[4] = true;
    //   } else if (el.day === 5) {
    //     satDay[0].dayscheckbox = true;

    //     satDay.push({
    //       dayscheckbox: true,
    //       days_start: el.start_time,
    //       days_end: el.end_time,
    //     });
    //     days[5] = true;
    //   } else if (el.day === 6) {
    //     sunDay[0].dayscheckbox = true;

    //     sunDay.push({
    //       dayscheckbox: true,
    //       days_start: el.start_time,
    //       days_end: el.end_time,
    //     });
    //     days[6] = true;
    //   }
    //   return true;
    // });
    // setIsDaySelected(days);
    // setMon(
    //   monDay.length
    //     ? monDay
    //     : {
    //         dayscheckbox: false,
    //         days_start: moment().format('HH:mm'),
    //         days_end: moment().add(1, 'hours').format('HH:mm'),
    //       }
    // );
    // setTue(
    //   tueDay.length
    //     ? tueDay
    //     : {
    //         dayscheckbox: false,
    //         days_start: moment().format('HH:mm'),
    //         days_end: moment().add(1, 'hours').format('HH:mm'),
    //       }
    // );
    // setWed(
    //   wedDay.length
    //     ? wedDay
    //     : {
    //         dayscheckbox: false,
    //         days_start: moment().format('HH:mm'),
    //         days_end: moment().add(1, 'hours').format('HH:mm'),
    //       }
    // );
    // setThu(
    //   thuDay.length
    //     ? thuDay
    //     : {
    //         dayscheckbox: false,
    //         days_start: moment().format('HH:mm'),
    //         days_end: moment().add(1, 'hours').format('HH:mm'),
    //       }
    // );
    // setFri(
    //   friDay.length
    //     ? friDay
    //     : {
    //         dayscheckbox: false,
    //         days_start: moment().format('HH:mm'),
    //         days_end: moment().add(1, 'hours').format('HH:mm'),
    //       }
    // );
    // setSat(
    //   satDay.length
    //     ? satDay
    //     : {
    //         dayscheckbox: false,
    //         days_start: moment().format('HH:mm'),
    //         days_end: moment().add(1, 'hours').format('HH:mm'),
    //       }
    // );
    // setSun(
    //   sunDay.length
    //     ? sunDay
    //     : {
    //         dayscheckbox: false,
    //         days_start: moment().format('HH:mm'),
    //         days_end: moment().add(1, 'hours').format('HH:mm'),
    //       }
    // );
  });
  if (!getDataLoading && slotdata) {
    const days_slot_1 = slotdata.data.availability_slots_data
      .filter((o) => {
        return o.day === 6;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    if (days_slot_1.length !== 0) initialValues.days_slot_1 = days_slot_1;
    const days_slot_2 = slotdata.data.availability_slots_data
      .filter((o) => {
        return o.day === 0;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    if (days_slot_2.length !== 0) initialValues.days_slot_2 = days_slot_2;
    const days_slot_3 = slotdata.data.availability_slots_data
      .filter((o) => {
        return o.day === 1;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    if (days_slot_3.length !== 0) initialValues.days_slot_3 = days_slot_3;
    const days_slot_4 = slotdata.data.availability_slots_data
      .filter((o) => {
        return o.day === 2;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    if (days_slot_4.length !== 0) initialValues.days_slot_4 = days_slot_4;
    const days_slot_5 = slotdata.data.availability_slots_data
      .filter((o) => {
        return o.day === 3;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    if (days_slot_5.length !== 0) initialValues.days_slot_5 = days_slot_5;
    const days_slot_6 = slotdata.data.availability_slots_data
      .filter((o) => {
        return o.day === 4;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    if (days_slot_6.length !== 0) initialValues.days_slot_6 = days_slot_6;
    const days_slot_7 = slotdata.data.availability_slots_data
      .filter((o) => {
        return o.day === 5;
      })
      .map((val) => {
        return {
          dayscheckbox: true,
          days_start: val.start_time,
          days_end: val.end_time,
        };
      });
    if (days_slot_7.length !== 0) initialValues.days_slot_7 = days_slot_7;
  }
  const { mutate: addDayTime, isLoading } = useBookAppointment((response) => {
    toast.success(response.message);
    refetch();
  });
  return (
    <>
      <Card className="inner-box" style={{ padding: '10px' }}>
        <Card.Body>
          {!getDataLoading && (
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                addDayTime(values);
              }}>
              {({ values, dirty, errors, touched, setFieldValue, handleSubmit, handleChange }) => {
                return (
                  <>
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <h3 className="page-heading">{t('page.timeslot_label')}</h3>
                        <Form.Label className="field-label form-field field-label-top">
                          <h4> {t('page.timeslot_label_subtitle')}</h4>
                        </Form.Label>

                        <Col lg={8}>
                          <Form.Check type={'radio'} className="d-flex align-items-center">
                            <Form.Check.Input
                              type={'radio'}
                              name="is_date_range"
                              id={`is_date_range_1`}
                              className="form-check-input radio-btn me-2"
                              value={true}
                              checked={values.is_date_range}
                              onChange={(e) => {
                                setIsDateRange(e.target.checked);
                              }}
                              onClick={(e) => {
                                setIsDateRange(e.target.checked);
                              }}
                            />
                            <Form.Check.Label className="bg-checkout  w-100">
                              {t('page.timeslot_radio_label1')}
                              <DateRangePicker
                                initialSettings={{
                                  startDate: moment(dateRange?.startDate).format('L'),
                                  endDate: moment(dateRange?.endDate).format('L'),
                                  minDate: moment(),
                                }}
                                locale={{
                                  format: 'mm/dd/yyyy',
                                }}
                                onApply={(event, picker) => {
                                  setFieldValue('start_date', new Date(picker.startDate));
                                  setFieldValue('end_date', new Date(picker.endDate));
                                }}>
                                <input
                                  type="text"
                                  className="date-form-control"
                                  disabled={values.is_date_range === false ? true : false}
                                />
                              </DateRangePicker>
                            </Form.Check.Label>
                          </Form.Check>
                          {/* <Form.Group>
                            <Field
                              className="form-check-input radio-btn me-2"
                              type="radio"
                              name="is_date_range"
                              value={1}
                              checked={values.is_date_range === 2 ? false : true}
                              onChange={handleChange}
                            />
                            <Form.Label>{t('page.timeslot_radio_label1')}</Form.Label>
                            <DateRangePicker
                              initialSettings={{
                                startDate: moment(dateRange?.startDate).format('L'),
                                endDate: moment(dateRange?.endDate).format('L'),
                              }}
                              locale={{
                                format: 'mm/dd/yyyy',
                              }}
                              onApply={(event, picker) => {
                                setFieldValue('start_date', new Date(picker.startDate));
                                setFieldValue('end_date', new Date(picker.endDate));
                              }}>
                              <input
                                type="text"
                                className="date-form-control"
                                disabled={values.is_date_range === 2 ? true : false}
                              />
                            </DateRangePicker>
                          </Form.Group> */}
                        </Col>

                        <Col lg={12}>
                          <Form.Check type={'radio'} className="d-flex align-items-center">
                            <Form.Check.Input
                              type={'radio'}
                              value={false}
                              name="is_date_range"
                              id={`is_date_range_2`}
                              className="form-check-input radio-btn me-2"
                              checked={values.is_date_range === false ? true : false}
                              onChange={(e) => {
                                console.log(e.target.checked);
                                setIsDateRange(e.target.checked === true ? false : true);
                              }}
                              onClick={(e) => {
                                setIsDateRange(e.target.checked === true ? false : true);
                              }}
                            />
                            <Form.Check.Label className="bg-checkout  w-100">
                              {t('page.timeslot_radio_label2')}
                            </Form.Check.Label>
                          </Form.Check>
                          {/* <Form.Group>
                            <Field
                              className="form-check-input radio-btn me-2"
                              type="radio"
                              name="is_date_range"
                              value={2}
                              checked={values.is_date_range === 2 ? true : false}
                              onChange={handleChange}
                              onClick={handleChange}
                            />
                            <Form.Label htmlFor="option2"></Form.Label>
                          </Form.Group> */}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Form.Label>
                          {' '}
                          <h4> {t('page.timeslot_weekly_hours')}</h4>
                        </Form.Label>
                        <Col lg={12}>
                          {/* // SunDay */}
                          <FieldArray name="days_slot_1">
                            {({ remove, push }) => {
                              const days_slot_1s = values.days_slot_1;
                              return (
                                <>
                                  {days_slot_1s.length > 0 &&
                                    days_slot_1s.map((days_slot_1, index) => {
                                      const days_startErrors =
                                        (errors.days_slot_1?.length &&
                                          errors.days_slot_1[index]?.days_start) ||
                                        '';
                                      const days_startTouched =
                                        (touched.days_slot_1?.length &&
                                          touched.days_slot_1[index]?.days_start) ||
                                        '';
                                      const days_endErrors =
                                        (errors.days_slot_1?.length &&
                                          errors.days_slot_1[index]?.days_end) ||
                                        '';
                                      const days_endTouched =
                                        (touched.days_slot_1?.length &&
                                          touched.days_slot_1[index]?.days_end) ||
                                        '';
                                      const dayscheckboxErrors =
                                        (errors.days_slot_1?.length &&
                                          errors.days_slot_1[index]?.dayscheckbox) ||
                                        '';
                                      const dayscheckboxTouched =
                                        (touched.days_slot_1?.length &&
                                          touched.days_slot_1[index]?.dayscheckbox) ||
                                        '';
                                      return (
                                        <div key={index.toString()} className="working-details">
                                          <Row className="working-text-center">
                                            <Col lg={2} xs={12} md={2}>
                                              {index === 0 ? (
                                                <>
                                                  <Form.Check
                                                    type={'checkbox'}
                                                    className="mt-0 main-top-add"
                                                    label={t('page.book_appointment_day_1')}
                                                    name={`days_slot_1.${index}.dayscheckbox`}
                                                    value={values.days_slot_1[index].dayscheckbox}
                                                    checked={values.days_slot_1[index].dayscheckbox}
                                                    onChange={(event) => {
                                                      for (
                                                        var i = 0;
                                                        i < values.days_slot_1.length;
                                                        ++i
                                                      ) {
                                                        setFieldValue(
                                                          `days_slot_1.${index}.dayscheckbox`,
                                                          event.currentTarget.checked
                                                        );
                                                      }
                                                      // setFieldValue(
                                                      //   `days_slot_1.${index}.dayscheckbox`,
                                                      //   event.currentTarget.checked
                                                      // );
                                                    }}
                                                  />
                                                  <div className="form-field-error-text">
                                                    {dayscheckboxTouched && dayscheckboxErrors ? (
                                                      <div>{t(dayscheckboxErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </>
                                              ) : null}
                                            </Col>
                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={5}>
                                                  <span className="time-label">
                                                    {t('page.book_appointment_start_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    name={`days_slot_1.${index}.days_start`}
                                                    disabled={
                                                      !values.days_slot_1[index].dayscheckbox
                                                    }
                                                    defaultValue={moment(
                                                      values.days_slot_1[index].days_start,
                                                      'HH:mm'
                                                    )}
                                                    className={
                                                      'form-control ' + days_startTouched ||
                                                      days_startErrors
                                                        ? 'form-select-error'
                                                        : days_startTouched && !days_startErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    use12Hours={true}
                                                    hourAriaLabel="Hour"
                                                    showSecond={false}
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values1) => {
                                                      if (values1 !== null) {
                                                        setFieldValue(
                                                          `days_slot_1.${index}.days_start`,
                                                          moment(values1).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_1.${index}.days_start`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    // value={values.days_slot_1[index].days_start}
                                                    secondAriaLabel="Second"
                                                  />
                                                </Col>
                                                <div
                                                  className={
                                                    'working-details-hours ' +
                                                    (index > 0 ? 'working-list-hours' : '')
                                                  }>
                                                  <div className="form-field-error-text">
                                                    {days_startTouched && days_startErrors ? (
                                                      <div>{t(days_startErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </Row>
                                            </Col>

                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={3}>
                                                  <span className="time-label">
                                                    {t('page.book_appointment_end_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    name={`days_slot_1.${index}.days_end`}
                                                    disabled={
                                                      values.days_slot_1[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    defaultValue={moment(
                                                      values.days_slot_1[index].days_end,
                                                      'HH:mm'
                                                    )}
                                                    className={
                                                      'form-control ' + days_endTouched ||
                                                      days_endErrors
                                                        ? 'form-select-error'
                                                        : days_endTouched && !days_endErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    hourAriaLabel="Hour"
                                                    showSecond={false}
                                                    // maxDetail="second"
                                                    use12Hours={true}
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values1) => {
                                                      if (values1 !== null) {
                                                        setFieldValue(
                                                          `days_slot_1.${index}.days_end`,
                                                          moment(values1).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_1.${index}.days_end`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    secondAriaLabel="Second"
                                                  />
                                                </Col>
                                              </Row>
                                              <div className="working-details-hours">
                                                <div className="form-field-error-text">
                                                  {days_endTouched && days_endErrors ? (
                                                    <div>{t(days_endErrors)}</div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </Col>
                                            <Col lg={2} xs={12} md={2}>
                                              <div>
                                                {index > 0 ? (
                                                  <Button
                                                    variant="light"
                                                    disabled={
                                                      values.days_slot_1[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    type="button"
                                                    className={'secondary-remove-button '}
                                                    onClick={() => remove(index)}>
                                                    {t('page.book_appointment_remove_button_text')}
                                                  </Button>
                                                ) : (
                                                  <div>
                                                    <Button
                                                      variant="light"
                                                      disabled={
                                                        values.days_slot_1[0].dayscheckbox === false
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={() => {
                                                        const startDay = values.days_slot_1.filter(
                                                          (value) => {
                                                            return value.days_start === '';
                                                          }
                                                        );

                                                        const endDay = values.days_slot_1.filter(
                                                          (value) => {
                                                            return value.days_end === '';
                                                          }
                                                        );

                                                        if (
                                                          startDay.length > 0 &&
                                                          endDay.length > 0
                                                        ) {
                                                          toast(
                                                            t('page.select_previous_day_time_first')
                                                          );
                                                          return;
                                                        }
                                                        push({
                                                          dayscheckbox:
                                                            values.days_slot_1[0].dayscheckbox,
                                                          days_start: moment().format('HH:mm'),
                                                          days_end: moment()
                                                            .add(1, 'hours')
                                                            .format('HH:mm'),
                                                        });
                                                      }}
                                                      className={
                                                        'secondary-add-button newsecondary-add-button'
                                                      }>
                                                      {t('page.book_appointment_add_button_text')}
                                                    </Button>
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      );
                                    })}
                                </>
                              );
                            }}
                          </FieldArray>

                          {/* // Monday */}
                          <FieldArray name="days_slot_2">
                            {({ remove, push }) => {
                              const days_slot_2s = values.days_slot_2;
                              return (
                                <>
                                  {days_slot_2s.length > 0 &&
                                    days_slot_2s.map((days_slot_2, index) => {
                                      const days_startErrors =
                                        (errors.days_slot_2?.length &&
                                          errors.days_slot_2[index]?.days_start) ||
                                        '';
                                      const days_startTouched =
                                        (touched.days_slot_2?.length &&
                                          touched.days_slot_2[index]?.days_start) ||
                                        '';
                                      const days_endErrors =
                                        (errors.days_slot_2?.length &&
                                          errors.days_slot_2[index]?.days_end) ||
                                        '';
                                      const days_endTouched =
                                        (touched.days_slot_2?.length &&
                                          touched.days_slot_2[index]?.days_end) ||
                                        '';
                                      const dayscheckboxErrors =
                                        (errors.days_slot_2?.length &&
                                          errors.days_slot_2[index]?.dayscheckbox) ||
                                        '';
                                      const dayscheckboxTouched =
                                        (touched.days_slot_2?.length &&
                                          touched.days_slot_2[index]?.dayscheckbox) ||
                                        '';
                                      return (
                                        <div key={index.toString()} className="working-details">
                                          <Row className="working-text-center">
                                            <Col lg={2} xs={12} md={2}>
                                              {index === 0 ? (
                                                <>
                                                  <Form.Check
                                                    type={'checkbox'}
                                                    className="mt-0 main-top-add"
                                                    label={t('page.book_appointment_day_2')}
                                                    name={`days_slot_2.${index}.dayscheckbox`}
                                                    value={values.days_slot_2[index].dayscheckbox}
                                                    checked={values.days_slot_2[index].dayscheckbox}
                                                    onChange={(event) => {
                                                      for (
                                                        var i = 0;
                                                        i < values.days_slot_2.length;
                                                        ++i
                                                      ) {
                                                        setFieldValue(
                                                          `days_slot_2.${i}.dayscheckbox`,
                                                          event.currentTarget.checked
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  <div className="form-field-error-text">
                                                    {dayscheckboxTouched && dayscheckboxErrors ? (
                                                      <div>{t(dayscheckboxErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </>
                                              ) : null}
                                            </Col>
                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={5}>
                                                  <span className="time-label">
                                                    {t('page.book_appointment_start_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    defaultValue={moment(
                                                      values.days_slot_2[index].days_start,
                                                      'HH:mm'
                                                    )}
                                                    name={`days_slot_2.${index}.days_start`}
                                                    disabled={
                                                      !values.days_slot_2[index].dayscheckbox
                                                    }
                                                    // disabled={
                                                    //   values.days_slot_2[0].dayscheckbox === false
                                                    //     ? true
                                                    //     : false
                                                    // }
                                                    className={
                                                      'form-control ' + days_startTouched ||
                                                      days_startErrors
                                                        ? 'form-select-error'
                                                        : days_startTouched && !days_startErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    use12Hours={true}
                                                    hourAriaLabel="Hour"
                                                    showSecond={false}
                                                    // maxDetail="second"
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values1) => {
                                                      if (values1 !== null) {
                                                        setFieldValue(
                                                          `days_slot_2.${index}.days_start`,
                                                          moment(values1).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_2.${index}.days_start`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    secondAriaLabel="Second"
                                                  />
                                                </Col>
                                                <div
                                                  className={
                                                    'working-details-hours ' +
                                                    (index > 0 ? 'working-list-hours' : '')
                                                  }>
                                                  <div className="form-field-error-text">
                                                    {days_startTouched && days_startErrors ? (
                                                      <div>{t(days_startErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </Row>
                                            </Col>

                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={3}>
                                                  <span className="time-label">
                                                    {t('page.book_appointment_end_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    defaultValue={moment(
                                                      values.days_slot_2[index].days_end,
                                                      'HH:mm'
                                                    )}
                                                    name={`days_slot_2.${index}.days_end`}
                                                    disabled={
                                                      values.days_slot_2[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    className={
                                                      'form-control ' + days_endTouched ||
                                                      days_endErrors
                                                        ? 'form-select-error'
                                                        : days_endTouched && !days_endErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    use12Hours={true}
                                                    hourAriaLabel="Hour"
                                                    showSecond={false}
                                                    // maxDetail="second"
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values) => {
                                                      if (values !== null) {
                                                        setFieldValue(
                                                          `days_slot_2.${index}.days_end`,
                                                          moment(values).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_2.${index}.days_end`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    secondAriaLabel="Second"
                                                  />
                                                </Col>
                                              </Row>
                                              <div className="working-details-hours">
                                                <div className="form-field-error-text">
                                                  {days_endTouched && days_endErrors ? (
                                                    <div>{t(days_endErrors)}</div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </Col>
                                            <Col lg={2} xs={12} md={2}>
                                              <div>
                                                {index > 0 ? (
                                                  <div>
                                                    <Button
                                                      variant="light"
                                                      disabled={
                                                        values.days_slot_2[0].dayscheckbox === false
                                                          ? true
                                                          : false
                                                      }
                                                      type="button"
                                                      className={'secondary-remove-button '}
                                                      onClick={() => remove(index)}>
                                                      {t(
                                                        'page.book_appointment_remove_button_text'
                                                      )}
                                                    </Button>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <Button
                                                      variant="light"
                                                      disabled={
                                                        values.days_slot_2[0].dayscheckbox === false
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={() => {
                                                        const startDay = values.days_slot_2.filter(
                                                          (value) => {
                                                            return value.days_start === '';
                                                          }
                                                        );
                                                        const endDay = values.days_slot_2.filter(
                                                          (value) => {
                                                            return value.days_end === '';
                                                          }
                                                        );
                                                        if (
                                                          startDay.length > 0 &&
                                                          endDay.length > 0
                                                        ) {
                                                          toast(
                                                            t('page.select_previous_day_time_first')
                                                          );
                                                          return;
                                                        }

                                                        push({
                                                          dayscheckbox:
                                                            values.days_slot_2[0].dayscheckbox,
                                                          days_start: moment().format('HH:mm'),
                                                          days_end: moment()
                                                            .add(1, 'hours')
                                                            .format('HH:mm'),
                                                        });
                                                      }}
                                                      className={
                                                        'secondary-add-button newsecondary-add-button'
                                                      }>
                                                      {t('page.book_appointment_add_button_text')}
                                                    </Button>
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      );
                                    })}
                                </>
                              );
                            }}
                          </FieldArray>

                          {/* // Tuesday */}
                          <FieldArray name="days_slot_3">
                            {({ remove, push }) => {
                              const days_slot_3s = values.days_slot_3;
                              return (
                                <>
                                  {days_slot_3s.length > 0 &&
                                    days_slot_3s.map((days_slot_3, index) => {
                                      const days_startErrors =
                                        (errors.days_slot_3?.length &&
                                          errors.days_slot_3[index]?.days_start) ||
                                        '';
                                      const days_startTouched =
                                        (touched.days_slot_3?.length &&
                                          touched.days_slot_3[index]?.days_start) ||
                                        '';
                                      const days_endErrors =
                                        (errors.days_slot_3?.length &&
                                          errors.days_slot_3[index]?.days_end) ||
                                        '';
                                      const days_endTouched =
                                        (touched.days_slot_3?.length &&
                                          touched.days_slot_3[index]?.days_end) ||
                                        '';
                                      const dayscheckboxErrors =
                                        (errors.days_slot_3?.length &&
                                          errors.days_slot_3[index]?.dayscheckbox) ||
                                        '';
                                      const dayscheckboxTouched =
                                        (touched.days_slot_3?.length &&
                                          touched.days_slot_3[index]?.dayscheckbox) ||
                                        '';
                                      return (
                                        <div key={index.toString()} className="working-details">
                                          <Row className="working-text-center">
                                            <Col lg={2} xs={12} md={2}>
                                              {index === 0 ? (
                                                <>
                                                  <Form.Check
                                                    type={'checkbox'}
                                                    className="mt-0 main-top-add"
                                                    label={t('page.book_appointment_day_3')}
                                                    name={`days_slot_3.${index}.dayscheckbox`}
                                                    value={values.days_slot_3[index].dayscheckbox}
                                                    checked={values.days_slot_3[index].dayscheckbox}
                                                    onChange={(event) => {
                                                      for (
                                                        var i = 0;
                                                        i < values.days_slot_3.length;
                                                        ++i
                                                      ) {
                                                        setFieldValue(
                                                          `days_slot_3.${i}.dayscheckbox`,
                                                          event.currentTarget.checked
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  <div className="form-field-error-text">
                                                    {dayscheckboxTouched[index] &&
                                                    dayscheckboxErrors[index] ? (
                                                      <div>{t(dayscheckboxErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </>
                                              ) : null}
                                            </Col>
                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={5}>
                                                  <span className="time-lable">
                                                    {t('page.book_appointment_start_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    defaultValue={moment(
                                                      values.days_slot_3[index].days_start,
                                                      'HH:mm'
                                                    )}
                                                    name={`days_slot_3.${index}.days_start`}
                                                    disabled={
                                                      values.days_slot_3[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    className={
                                                      'form-control ' + days_startTouched ||
                                                      days_startErrors
                                                        ? 'form-select-error'
                                                        : days_startTouched && !days_startErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    use12Hours={true}
                                                    hourAriaLabel="Hour"
                                                    showSecond={false}
                                                    // maxDetail="second"
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values1) => {
                                                      if (values1 !== null) {
                                                        setFieldValue(
                                                          `days_slot_3.${index}.days_start`,
                                                          moment(values1).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_3.${index}.days_start`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    secondAriaLabel="Second"
                                                  />
                                                </Col>
                                                <div
                                                  className={
                                                    'working-details-hours ' +
                                                    (index > 0 ? 'working-list-hours' : '')
                                                  }>
                                                  <div className="form-field-error-text">
                                                    {days_startTouched[index] &&
                                                    days_startErrors[index] ? (
                                                      <div>{t(days_startErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </Row>
                                            </Col>

                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={3}>
                                                  <span className="time-label">
                                                    {t('page.book_appointment_end_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    defaultValue={moment(
                                                      values.days_slot_3[index].days_end,
                                                      'HH:mm'
                                                    )}
                                                    name={`days_slot_3.${index}.days_end`}
                                                    disabled={
                                                      values.days_slot_3[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    className={
                                                      'form-control ' + days_endTouched ||
                                                      days_endErrors
                                                        ? 'form-select-error'
                                                        : days_endTouched && !days_endErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    hourAriaLabel="Hour"
                                                    showSecond={false}
                                                    use12Hours={true}
                                                    // maxDetail="second"
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values) => {
                                                      if (values !== null) {
                                                        setFieldValue(
                                                          `days_slot_3.${index}.days_end`,
                                                          moment(values).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_3.${index}.days_end`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    secondAriaLabel="Second"
                                                  />
                                                </Col>
                                              </Row>
                                              <div className="working-details-hours">
                                                <div className="form-field-error-text">
                                                  {days_endTouched[index] &&
                                                  days_endErrors[index] ? (
                                                    <div>{t(days_endErrors)}</div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </Col>
                                            <Col xs={12} md={2}>
                                              <div>
                                                {index > 0 ? (
                                                  <Button
                                                    variant="light"
                                                    disabled={
                                                      values.days_slot_3[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    type="button"
                                                    className={'secondary-remove-button '}
                                                    onClick={() => remove(index)}>
                                                    {t('page.book_appointment_remove_button_text')}
                                                  </Button>
                                                ) : (
                                                  <div>
                                                    <Button
                                                      variant="light"
                                                      disabled={
                                                        values.days_slot_3[0].dayscheckbox === false
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={() => {
                                                        const startDay = values.days_slot_3.filter(
                                                          (value) => {
                                                            return value.days_start === '';
                                                          }
                                                        );
                                                        const endDay = values.days_slot_3.filter(
                                                          (value) => {
                                                            return value.days_end === '';
                                                          }
                                                        );
                                                        if (
                                                          startDay.length > 0 &&
                                                          endDay.length > 0
                                                        ) {
                                                          toast(
                                                            t('page.select_previous_day_time_first')
                                                          );
                                                          return;
                                                        }
                                                        push({
                                                          dayscheckbox:
                                                            values.days_slot_3[0].dayscheckbox,
                                                          days_start: moment().format('HH:mm'),
                                                          days_end: moment()
                                                            .add(1, 'hours')
                                                            .format('HH:mm'),
                                                        });
                                                      }}
                                                      className={
                                                        'secondary-add-button newsecondary-add-button'
                                                      }>
                                                      {t('page.book_appointment_add_button_text')}
                                                    </Button>
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      );
                                    })}
                                </>
                              );
                            }}
                          </FieldArray>



 {/* // WED */}
 <FieldArray name="days_slot_4">
                            {({ remove, push }) => {
                              const days_slot_4s = values.days_slot_4;
                              return (
                                <>
                                  {days_slot_4s.length > 0 &&
                                    days_slot_4s.map((days_slot_4, index) => {
                                      const days_startErrors =
                                        (errors.days_slot_4?.length &&
                                          errors.days_slot_4[index]?.days_start) ||
                                        '';
                                      const days_startTouched =
                                        (touched.days_slot_4?.length &&
                                          touched.days_slot_4[index]?.days_start) ||
                                        '';
                                      const days_endErrors =
                                        (errors.days_slot_4?.length &&
                                          errors.days_slot_4[index]?.days_end) ||
                                        '';
                                      const days_endTouched =
                                        (touched.days_slot_4?.length &&
                                          touched.days_slot_4[index]?.days_end) ||
                                        '';
                                      const dayscheckboxErrors =
                                        (errors.days_slot_4?.length &&
                                          errors.days_slot_4[index]?.dayscheckbox) ||
                                        '';
                                      const dayscheckboxTouched =
                                        (touched.days_slot_4?.length &&
                                          touched.days_slot_4[index]?.dayscheckbox) ||
                                        '';
                                      return (
                                        <div key={index.toString()} className="working-details">
                                          <Row className="working-text-center">
                                            <Col lg={2} xs={12} md={2}>
                                              {index === 0 ? (
                                                <>
                                                  <Form.Check
                                                    type={'checkbox'}
                                                    className="mt-0 main-top-add"
                                                    label={t('page.book_appointment_day_4')}
                                                    name={`days_slot_4.${index}.dayscheckbox`}
                                                    value={values.days_slot_4[index].dayscheckbox}
                                                    checked={values.days_slot_4[index].dayscheckbox}
                                                    onChange={(event) => {
                                                      for (
                                                        var i = 0;
                                                        i < values.days_slot_4.length;
                                                        ++i
                                                      ) {
                                                        setFieldValue(
                                                          `days_slot_4.${i}.dayscheckbox`,
                                                          event.currentTarget.checked
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  <div className="form-field-error-text">
                                                    {dayscheckboxTouched[index] &&
                                                    dayscheckboxErrors[index] ? (
                                                      <div>{t(dayscheckboxErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </>
                                              ) : null}
                                            </Col>
                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={5}>
                                                  <span className="time-lable">
                                                    {t('page.book_appointment_start_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    defaultValue={moment(
                                                      values.days_slot_4[index].days_start,
                                                      'HH:mm'
                                                    )}
                                                    name={`days_slot_4.${index}.days_start`}
                                                    disabled={
                                                      values.days_slot_4[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    className={
                                                      'form-control ' + days_startTouched ||
                                                      days_startErrors
                                                        ? 'form-select-error'
                                                        : days_startTouched && !days_startErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    use12Hours={true}
                                                    hourAriaLabel="Hour"
                                                    showSecond={false}
                                                    // maxDetail="second"
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values1) => {
                                                      if (values1 !== null) {
                                                        setFieldValue(
                                                          `days_slot_4.${index}.days_start`,
                                                          moment(values1).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_4.${index}.days_start`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    secondAriaLabel="Second"
                                                  />
                                                </Col>
                                                <div
                                                  className={
                                                    'working-details-hours ' +
                                                    (index > 0 ? 'working-list-hours' : '')
                                                  }>
                                                  <div className="form-field-error-text">
                                                    {days_startTouched[index] &&
                                                    days_startErrors[index] ? (
                                                      <div>{t(days_startErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </Row>
                                            </Col>

                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={3}>
                                                  <span className="time-label">
                                                    {t('page.book_appointment_end_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    defaultValue={moment(
                                                      values.days_slot_4[index].days_end,
                                                      'HH:mm'
                                                    )}
                                                    name={`days_slot_4.${index}.days_end`}
                                                    disabled={
                                                      values.days_slot_4[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    className={
                                                      'form-control ' + days_endTouched ||
                                                      days_endErrors
                                                        ? 'form-select-error'
                                                        : days_endTouched && !days_endErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    hourAriaLabel="Hour"
                                                    showSecond={false}
                                                    use12Hours={true}
                                                    // maxDetail="second"
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values) => {
                                                      if (values !== null) {
                                                        setFieldValue(
                                                          `days_slot_4.${index}.days_end`,
                                                          moment(values).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_4.${index}.days_end`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    secondAriaLabel="Second"
                                                  />
                                                </Col>
                                              </Row>
                                              <div className="working-details-hours">
                                                <div className="form-field-error-text">
                                                  {days_endTouched[index] &&
                                                  days_endErrors[index] ? (
                                                    <div>{t(days_endErrors)}</div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </Col>
                                            <Col xs={12} md={2}>
                                              <div>
                                                {index > 0 ? (
                                                  <Button
                                                    variant="light"
                                                    disabled={
                                                      values.days_slot_4[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    type="button"
                                                    className={'secondary-remove-button '}
                                                    onClick={() => remove(index)}>
                                                    {t('page.book_appointment_remove_button_text')}
                                                  </Button>
                                                ) : (
                                                  <div>
                                                    <Button
                                                      variant="light"
                                                      disabled={
                                                        values.days_slot_4[0].dayscheckbox === false
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={() => {
                                                        const startDay = values.days_slot_4.filter(
                                                          (value) => {
                                                            return value.days_start === '';
                                                          }
                                                        );
                                                        const endDay = values.days_slot_4.filter(
                                                          (value) => {
                                                            return value.days_end === '';
                                                          }
                                                        );
                                                        if (
                                                          startDay.length > 0 &&
                                                          endDay.length > 0
                                                        ) {
                                                          toast(
                                                            t('page.select_previous_day_time_first')
                                                          );
                                                          return;
                                                        }
                                                        push({
                                                          dayscheckbox:
                                                            values.days_slot_4[0].dayscheckbox,
                                                          days_start: moment().format('HH:mm'),
                                                          days_end: moment()
                                                            .add(1, 'hours')
                                                            .format('HH:mm'),
                                                        });
                                                      }}
                                                      className={
                                                        'secondary-add-button newsecondary-add-button'
                                                      }>
                                                      {t('page.book_appointment_add_button_text')}
                                                    </Button>
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      );
                                    })}
                                </>
                              );
                            }}
                          </FieldArray>

                          {/* // Thursday */}
                          <FieldArray name="days_slot_5">
                            {({ remove, push }) => {
                              const days_slot_5s = values.days_slot_5;
                              return (
                                <>
                                  {days_slot_5s.length > 0 &&
                                    days_slot_5s.map((days_slot_5, index) => {
                                      const days_startErrors =
                                        (errors.days_slot_5?.length &&
                                          errors.days_slot_5[index]?.days_start) ||
                                        '';
                                      const days_startTouched =
                                        (touched.days_slot_5?.length &&
                                          touched.days_slot_5[index]?.days_start) ||
                                        '';
                                      const days_endErrors =
                                        (errors.days_slot_5?.length &&
                                          errors.days_slot_5[index]?.days_end) ||
                                        '';
                                      const days_endTouched =
                                        (touched.days_slot_5?.length &&
                                          touched.days_slot_5[index]?.days_end) ||
                                        '';
                                      const dayscheckboxErrors =
                                        (errors.days_slot_5?.length &&
                                          errors.days_slot_5[index]?.dayscheckbox) ||
                                        '';
                                      const dayscheckboxTouched =
                                        (touched.days_slot_5?.length &&
                                          touched.days_slot_5[index]?.dayscheckbox) ||
                                        '';
                                      return (
                                        <div key={index.toString()} className="working-details">
                                          <Row className="working-text-center">
                                            <Col lg={2} xs={12} md={2}>
                                              {index === 0 ? (
                                                <>
                                                  <Form.Check
                                                    type={'checkbox'}
                                                    className="mt-0 main-top-add"
                                                    label={t('page.book_appointment_day_5')}
                                                    name={`days_slot_4.${index}.dayscheckbox`}
                                                    value={values.days_slot_5[index].dayscheckbox}
                                                    checked={values.days_slot_5[index].dayscheckbox}
                                                    onChange={(event) => {
                                                      for (
                                                        var i = 0;
                                                        i < values.days_slot_5.length;
                                                        ++i
                                                      ) {
                                                        setFieldValue(
                                                          `days_slot_5.${i}.dayscheckbox`,
                                                          event.currentTarget.checked
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  <div className="form-field-error-text">
                                                    {dayscheckboxTouched && dayscheckboxErrors ? (
                                                      <div>{t(dayscheckboxErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </>
                                              ) : null}
                                            </Col>
                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={5}>
                                                  <span className="time-lable">
                                                    {t('page.book_appointment_start_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    defaultValue={moment(
                                                      values.days_slot_5[index].days_start,
                                                      'HH:mm'
                                                    )}
                                                    name={`days_slot_5.${index}.days_start`}
                                                    disabled={
                                                      values.days_slot_5[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    className={
                                                      'form-control ' + days_startTouched ||
                                                      days_startErrors
                                                        ? 'form-select-error'
                                                        : days_startTouched && !days_startErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    hourAriaLabel="Hour"
                                                    showSecond={false}
                                                    use12Hours={true}
                                                    // maxDetail="second"
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values1) => {
                                                      if (values1 !== null) {
                                                        setFieldValue(
                                                          `days_slot_5.${index}.days_start`,
                                                          moment(values1).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_5.${index}.days_start`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    secondAriaLabel="Second"
                                                    // value={values.days_slot_1}
                                                  />
                                                </Col>
                                                <div
                                                  className={
                                                    'working-details-hours ' +
                                                    (index > 0 ? 'working-list-hours' : '')
                                                  }>
                                                  <div className="form-field-error-text">
                                                    {days_startTouched && days_startErrors ? (
                                                      <div>{t(days_startErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </Row>
                                            </Col>

                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={3}>
                                                  <span className="time-lable">
                                                    {t('page.book_appointment_end_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    defaultValue={moment(
                                                      values.days_slot_5[index].days_end,
                                                      'HH:mm'
                                                    )}
                                                    name={`days_slot_5.${index}.days_end`}
                                                    disabled={
                                                      values.days_slot_5[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    className={
                                                      'form-control ' + days_endTouched ||
                                                      days_endErrors
                                                        ? 'form-select-error'
                                                        : days_endTouched && !days_endErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    hourAriaLabel="Hour"
                                                    use12Hours={true}
                                                    showSecond={false}
                                                    // maxDetail="second"
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values1) => {
                                                      if (values1 !== null) {
                                                        setFieldValue(
                                                          `days_slot_5.${index}.days_end`,
                                                          moment(values1).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_5.${index}.days_end`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    secondAriaLabel="Second"
                                                  />
                                                </Col>
                                              </Row>
                                              <div className="working-details-hours">
                                                <div className="form-field-error-text">
                                                  {days_endTouched && days_endErrors ? (
                                                    <div>{t(days_endErrors)}</div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </Col>
                                            <Col lg={2} xs={12} md={2}>
                                              <div>
                                                {index > 0 ? (
                                                  <Button
                                                    variant="light"
                                                    disabled={
                                                      values.days_slot_5[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    type="button"
                                                    className={'secondary-remove-button '}
                                                    onClick={() => remove(index)}>
                                                    {t('page.book_appointment_remove_button_text')}
                                                  </Button>
                                                ) : (
                                                  <div>
                                                    <Button
                                                      variant="light"
                                                      disabled={
                                                        values.days_slot_5[0].dayscheckbox === false
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={() => {
                                                        const startDay = values.days_slot_5.filter(
                                                          (value) => {
                                                            return value.days_start === '';
                                                          }
                                                        );
                                                        const endDay = values.days_slot_5.filter(
                                                          (value) => {
                                                            return value.days_end === '';
                                                          }
                                                        );
                                                        if (
                                                          startDay.length > 0 &&
                                                          endDay.length > 0
                                                        ) {
                                                          toast(
                                                            t('page.select_previous_day_time_first')
                                                          );
                                                          return;
                                                        }
                                                        push({
                                                          dayscheckbox:
                                                            values.days_slot_5[0].dayscheckbox,
                                                          days_start: moment().format('HH:mm'),
                                                          days_end: moment()
                                                            .add(1, 'hours')
                                                            .format('HH:mm'),
                                                        });
                                                      }}
                                                      className={
                                                        'secondary-add-button newsecondary-add-button '
                                                      }>
                                                      {t('page.book_appointment_add_button_text')}
                                                    </Button>
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      );
                                    })}
                                </>
                              );
                            }}
                          </FieldArray>

                          {/* // Friday */}
                          <FieldArray name="days_slot_6">
                            {({ remove, push }) => {
                              const days_slot_6s = values.days_slot_6;
                              return (
                                <>
                                  {days_slot_6s.length > 0 &&
                                    days_slot_6s.map((days_slot_6, index) => {
                                      const days_startErrors =
                                        (errors.days_slot_6?.length &&
                                          errors.days_slot_6[index]?.days_start) ||
                                        '';
                                      const days_startTouched =
                                        (touched.days_slot_6?.length &&
                                          touched.days_slot_6[index]?.days_start) ||
                                        '';
                                      const days_endErrors =
                                        (errors.days_slot_6?.length &&
                                          errors.days_slot_6[index]?.days_end) ||
                                        '';
                                      const days_endTouched =
                                        (touched.days_slot_6?.length &&
                                          touched.days_slot_6[index]?.days_end) ||
                                        '';
                                      const dayscheckboxErrors =
                                        (errors.days_slot_6?.length &&
                                          errors.days_slot_6[index]?.dayscheckbox) ||
                                        '';
                                      const dayscheckboxTouched =
                                        (touched.days_slot_6?.length &&
                                          touched.days_slot_6[index]?.dayscheckbox) ||
                                        '';
                                      return (
                                        <div key={index.toString()} className="working-details">
                                          <Row className="working-text-center">
                                            <Col lg={2} xs={12} md={2}>
                                              {index === 0 ? (
                                                <>
                                                  <Form.Check
                                                    type={'checkbox'}
                                                    className="mt-0 main-top-add"
                                                    label={t('page.book_appointment_day_6')}
                                                    name={`days_slot_6.${index}.dayscheckbox`}
                                                    value={values.days_slot_6[index].dayscheckbox}
                                                    checked={values.days_slot_6[index].dayscheckbox}
                                                    onChange={(event) => {
                                                      for (
                                                        var i = 0;
                                                        i < values.days_slot_6.length;
                                                        ++i
                                                      ) {
                                                        setFieldValue(
                                                          `days_slot_6.${i}.dayscheckbox`,
                                                          event.currentTarget.checked
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  <div className="form-field-error-text">
                                                    {dayscheckboxTouched && dayscheckboxErrors ? (
                                                      <div>{t(dayscheckboxErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </>
                                              ) : null}
                                            </Col>
                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={5}>
                                                  <span className="time-lable">
                                                    {t('page.book_appointment_start_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    defaultValue={moment(
                                                      values.days_slot_6[index].days_start,
                                                      'HH:mm'
                                                    )}
                                                    name={`days_slot_6.${index}.days_start`}
                                                    disabled={
                                                      values.days_slot_6[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    className={
                                                      'form-control ' + days_startTouched ||
                                                      days_startErrors
                                                        ? 'form-select-error'
                                                        : days_startTouched && !days_startErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    hourAriaLabel="Hour"
                                                    showSecond={false}
                                                    use12Hours={true}
                                                    // maxDetail="second"
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values1) => {
                                                      if (values1 !== null) {
                                                        setFieldValue(
                                                          `days_slot_6.${index}.days_start`,
                                                          moment(values1).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_6.${index}.days_start`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    secondAriaLabel="Second"
                                                  />
                                                </Col>
                                                <div
                                                  className={
                                                    'working-details-hours ' +
                                                    (index > 0 ? 'working-list-hours' : '')
                                                  }>
                                                  <div className="form-field-error-text">
                                                    {days_startTouched && days_startErrors ? (
                                                      <div>{t(days_startErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </Row>
                                            </Col>

                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={3}>
                                                  <span className="time-lable">
                                                    {t('page.book_appointment_end_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    defaultValue={moment(
                                                      values.days_slot_6[index].days_end,
                                                      'HH:mm'
                                                    )}
                                                    name={`days_slot_6.${index}.days_end`}
                                                    disabled={
                                                      values.days_slot_6[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    className={
                                                      'form-control ' + days_endTouched ||
                                                      days_endErrors
                                                        ? 'form-select-error'
                                                        : days_endTouched && !days_endErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    hourAriaLabel="Hour"
                                                    showSecond={false}
                                                    use12Hours={true}
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values1) => {
                                                      if (values1 !== null) {
                                                        setFieldValue(
                                                          `days_slot_6.${index}.days_end`,
                                                          moment(values1).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_6.${index}.days_end`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    secondAriaLabel="Second"
                                                  />
                                                </Col>
                                              </Row>
                                              <div className="working-details-hours">
                                                <div className="form-field-error-text">
                                                  {days_endTouched && days_endErrors ? (
                                                    <div>{t(days_endErrors)}</div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </Col>
                                            <Col lg={2} xs={12} md={2}>
                                              <div>
                                                {index > 0 ? (
                                                  <Button
                                                    variant="light"
                                                    disabled={
                                                      values.days_slot_6[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    type="button"
                                                    className={'secondary-remove-button '}
                                                    onClick={() => remove(index)}>
                                                    {t('page.book_appointment_remove_button_text')}
                                                  </Button>
                                                ) : (
                                                  <div>
                                                    <Button
                                                      variant="light"
                                                      disabled={
                                                        values.days_slot_6[0].dayscheckbox === false
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={() => {
                                                        const startDay = values.days_slot_6.filter(
                                                          (value) => {
                                                            return value.days_start === '';
                                                          }
                                                        );
                                                        const endDay = values.days_slot_6.filter(
                                                          (value) => {
                                                            return value.days_end === '';
                                                          }
                                                        );
                                                        if (
                                                          startDay.length > 0 &&
                                                          endDay.length > 0
                                                        ) {
                                                          toast(
                                                            t('page.select_previous_day_time_first')
                                                          );
                                                          return;
                                                        }
                                                        push({
                                                          dayscheckbox:
                                                            values.days_slot_6[0].dayscheckbox,
                                                          days_start: moment().format('HH:mm'),
                                                          days_end: moment()
                                                            .add(1, 'hours')
                                                            .format('HH:mm'),
                                                        });
                                                      }}
                                                      className={
                                                        'secondary-add-button newsecondary-add-button '
                                                      }>
                                                      {t('page.book_appointment_add_button_text')}
                                                    </Button>
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      );
                                    })}
                                </>
                              );
                            }}
                          </FieldArray>

                          {/* // Saturday */}
                          <FieldArray name="days_slot_7">
                            {({ remove, push }) => {
                              const days_slot_7s = values.days_slot_7;
                              return (
                                <>
                                  {days_slot_7s.length > 0 &&
                                    days_slot_7s.map((days_slot_7, index) => {
                                      const days_startErrors =
                                        (errors.days_slot_7?.length &&
                                          errors.days_slot_7[index]?.days_start) ||
                                        '';
                                      const days_startTouched =
                                        (touched.days_slot_7?.length &&
                                          touched.days_slot_7[index]?.days_start) ||
                                        '';
                                      const days_endErrors =
                                        (errors.days_slot_7?.length &&
                                          errors.days_slot_7[index]?.days_end) ||
                                        '';
                                      const days_endTouched =
                                        (touched.days_slot_7?.length &&
                                          touched.days_slot_7[index]?.days_end) ||
                                        '';
                                      const dayscheckboxErrors =
                                        (errors.days_slot_7?.length &&
                                          errors.days_slot_7[index]?.dayscheckbox) ||
                                        '';
                                      const dayscheckboxTouched =
                                        (touched.days_slot_7?.length &&
                                          touched.days_slot_7[index]?.dayscheckbox) ||
                                        '';
                                      return (
                                        <div key={index.toString()} className="working-details">
                                          <Row className="working-text-center">
                                            <Col lg={2} xs={12} md={2}>
                                              {index === 0 ? (
                                                <>
                                                  <Form.Check
                                                    type={'checkbox'}
                                                    className="mt-0 main-top-add"
                                                    label={t('page.book_appointment_day_7')}
                                                    name={`days_slot_7.${index}.dayscheckbox`}
                                                    value={values.days_slot_7[index].dayscheckbox}
                                                    checked={values.days_slot_7[index].dayscheckbox}
                                                    onChange={(event) => {
                                                      for (
                                                        var i = 0;
                                                        i < values.days_slot_7.length;
                                                        ++i
                                                      ) {
                                                        setFieldValue(
                                                          `days_slot_7.${i}.dayscheckbox`,
                                                          event.currentTarget.checked
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  <div className="form-field-error-text">
                                                    {dayscheckboxTouched && dayscheckboxErrors ? (
                                                      <div>{t(dayscheckboxErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </>
                                              ) : null}
                                            </Col>
                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={5}>
                                                  <span className="time-lable">
                                                    {t('page.book_appointment_start_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    name={`days_slot_7.${index}.days_start`}
                                                    disabled={
                                                      values.days_slot_7[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    defaultValue={moment(
                                                      values.days_slot_7[index].days_start,
                                                      'HH:mm'
                                                    )}
                                                    className={
                                                      'form-control ' + days_startTouched ||
                                                      days_startErrors
                                                        ? 'form-select-error'
                                                        : days_startTouched && !days_startErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    use12Hours={true}
                                                    hourAriaLabel="Hour"
                                                    showSecond={false}
                                                    // maxDetail="second"
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values1) => {
                                                      if (values1 !== null) {
                                                        setFieldValue(
                                                          `days_slot_7.${index}.days_start`,
                                                          moment(values1).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_7.${index}.days_start`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    secondAriaLabel="Second"
                                                  />
                                                </Col>
                                                <div
                                                  className={
                                                    'working-details-hours ' +
                                                    (index > 0 ? 'working-list-hours' : '')
                                                  }>
                                                  <div className="form-field-error-text">
                                                    {days_startTouched && days_startErrors ? (
                                                      <div>{t(days_startErrors)}</div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </Row>
                                            </Col>

                                            <Col lg={4} xs={6} md={4}>
                                              <Row className="working-text-center pt-0">
                                                <Col lg={3}>
                                                  <span className="time-lable">
                                                    {t('page.book_appointment_end_time_label')}
                                                  </span>
                                                </Col>
                                                <Col lg={7}>
                                                  <TimePicker
                                                    name={`days_slot_7.${index}.days_end`}
                                                    disabled={
                                                      values.days_slot_7[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    defaultValue={moment(
                                                      values.days_slot_7[index].days_end,
                                                      'HH:mm'
                                                    )}
                                                    className={
                                                      'form-control ' + days_endTouched ||
                                                      days_endErrors
                                                        ? 'form-select-error'
                                                        : days_endTouched && !days_endErrors
                                                        ? 'form-select-success'
                                                        : ''
                                                    }
                                                    amPmAriaLabel="Select AM/PM"
                                                    clearAriaLabel="Clear value"
                                                    clockAriaLabel="Toggle clock"
                                                    hourAriaLabel="Hour"
                                                    use12Hours={true}
                                                    showSecond={false}
                                                    // maxDetail="second"
                                                    minuteAriaLabel="Minute"
                                                    nativeInputAriaLabel="Time"
                                                    onChange={(values1) => {
                                                      if (values1 !== null) {
                                                        setFieldValue(
                                                          `days_slot_7.${index}.days_end`,
                                                          moment(values1).format('HH:mm')
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `days_slot_7.${index}.days_end`,
                                                          ''
                                                        );
                                                      }
                                                    }}
                                                    secondAriaLabel="Second"
                                                  />
                                                </Col>
                                              </Row>
                                              <div className="working-details-hours">
                                                <div className="form-field-error-text">
                                                  {days_endTouched && days_endErrors ? (
                                                    <div>{t(days_endErrors)}</div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </Col>
                                            <Col lg={2} xs={12} md={2}>
                                              <div>
                                                {index > 0 ? (
                                                  <Button
                                                    variant="light"
                                                    disabled={
                                                      values.days_slot_7[0].dayscheckbox === false
                                                        ? true
                                                        : false
                                                    }
                                                    type="button"
                                                    className={'secondary-remove-button '}
                                                    onClick={() => remove(index)}>
                                                    {t('page.book_appointment_remove_button_text')}
                                                  </Button>
                                                ) : (
                                                  <div>
                                                    <Button
                                                      variant="light"
                                                      disabled={
                                                        values.days_slot_7[0].dayscheckbox === false
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={() => {
                                                        const startDay = values.days_slot_7.filter(
                                                          (value) => {
                                                            return value.days_start === '';
                                                          }
                                                        );
                                                        const endDay = values.days_slot_7.filter(
                                                          (value) => {
                                                            return value.days_end === '';
                                                          }
                                                        );
                                                        if (
                                                          startDay.length > 0 &&
                                                          endDay.length > 0
                                                        ) {
                                                          toast(
                                                            t('page.select_previous_day_time_first')
                                                          );
                                                          return;
                                                        }
                                                        push({
                                                          dayscheckbox:
                                                            values.days_slot_7[0].dayscheckbox,
                                                          days_start: moment()
                                                            .add(1, 'hours')
                                                            .format('HH:mm'),
                                                          days_end: moment()
                                                            .add(1, 'hours')
                                                            .format('HH:mm'),
                                                        });
                                                      }}
                                                      className={
                                                        'secondary-add-button newsecondary-add-button '
                                                      }>
                                                      {t('page.book_appointment_add_button_text')}
                                                    </Button>
                                                  </div>
                                                )}
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      );
                                    })}
                                </>
                              );
                            }}
                          </FieldArray>
                        </Col>
                        <Col lg={3}>
                          <TNButton
                            type="submit"
                            loading={isLoading}
                            className="ms-0"
                            isdirtyform={dirty && dirty !== undefined ? 1 : 0}>
                            {t('page.save_button_text')}
                          </TNButton>
                        </Col>
                      </Row>
                    </form>
                  </>
                );
              }}
            </Formik>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ScheduleAppointments;
