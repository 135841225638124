import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { TranslationService } from 'api';
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useListTranslation = ([page_no, searchText], onSuccess, onError = onDefaultError) => {
  return useQuery(['translation-list', page_no, searchText], TranslationService.listTranslation, {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useAddTranslation = (onSuccess, onError = onDefaultError) => {
  return useMutation(TranslationService.addTranslation, {
    onSuccess,
    onError,
  });
};

const useViewTranslation = (translation_id, onSuccess, onError = onDefaultError) => {
  return useQuery('translation-view', () => TranslationService.viewTranslation(translation_id), {
    onSuccess,
    onError,
  });
};

const useUpdateTranslation = (onSuccess, onError = onDefaultError) => {
  return useMutation(TranslationService.updateTranslation, {
    onSuccess,
    onError,
  });
};

const useSyncTranslation = (isEnabled, onSuccess, onError = onDefaultError) => {
  return useQuery('translation-sync', () => TranslationService.syncTranslation, {
    onSuccess,
    onError,
    enabled: isEnabled[0],
    retry: 0,
  });
};

export {
  useListTranslation,
  useAddTranslation,
  useViewTranslation,
  useUpdateTranslation,
  useSyncTranslation,
};
