import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListUserPage = React.lazy(() => import('./ListUserPage'));
const EditUserPage = React.lazy(() => import('./EditUserPage'));

const UserRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListUserPage t={t} />} />
      <Route exact path="/edit/:id" element={<EditUserPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
UserRoutes.propTypes = {
  t: PropTypes.func
};
export default UserRoutes;
