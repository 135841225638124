import client from 'libs/HttpClient';

class DashboardService {
  static listCounter(request) {
    return client.get(`/admin/dashboard/counter`, request);
  }
  static listUserCounter(request) {
    let url = '/admin/dashboard/user_counter';
    if (request.queryKey[1] !== '') {
      url = `${url}?year=${request.queryKey[1]}`;
    }
    return client.get(`${url}`);
  }
  // static listValuationCounter(request) {
  //   let url = '/admin/dashboard/valuation_counter';
  //   if (request.queryKey[1] !== '') {
  //     url = `${url}?year=${request.queryKey[1]}`;
  //   }
  //   return client.get(`${url}`);
  // }
  static listValuationCounter(request) {
    const year = request.queryKey[1];

    return client.get(`/admin/dashboard/valuation_counter?year=${year}`, request);
  }
}
export { DashboardService };
