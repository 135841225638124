import client from 'libs/HttpClient';

class NotificationService {
  static notificationList(request) {
    return client.get(`/admin/notification/list`, request);
  }
  static deleteNotificationList(request) {
    return client.post(`/admin/notification/clear`, request);
  }
}
export { NotificationService };
