// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import client from 'libs/HttpClient';

var firebaseConfig = {
  apiKey: 'AIzaSyAhfk_GaIDIMq0QJ3xlkaCCsPgpamEeP20',
  authDomain: 'gable2closingtable-website.firebaseapp.com',
  projectId: 'gable2closingtable-website',
  storageBucket: 'gable2closingtable-website.appspot.com',
  messagingSenderId: '581084339969',
  appId: '1:581084339969:web:5395027476efa3d4c40500',
  measurementId: 'G-Z4Z5KQWHY0',
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: `BCKAiAA81hZ1HCeh5RPoB7uhlulGH0lSOXp_ws87Xy5MhIQLV-YM2dws83su8PpbMnxsuaBb2QcsHtuix06UqEM`,
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        localStorage.fcmToken = currentToken;
        client.post('admin/token/store', { token: currentToken });

        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('admin', payload);
      resolve(payload);
    });
  });
